import React from 'react'
import { FieldLabel } from '../../atoms/FieldLabel'
import { FieldRow } from '../../atoms/FieldRow'
import { FieldValue } from '../../atoms/FieldValue'
import { DetailsTitle } from '../../atoms/DetailsTitle'
import { FieldItem, FieldsTable } from '../../molecules/FieldsTable'
import { DocumentType } from './types'
import { useDocumentDetailsType } from './useDocumentDetailsType'
import { useGetUserQuery } from '../../../api/generated'
import { formatDate } from '../../../utils/formatDate'

const nameMap: Record<DocumentType, string> = {
  ead: 'EAD',
  i94: 'I-94',
  passport: 'Passport',
  visa: 'Visa',
}

export type DocumentDetailsProps = {
  userId: string
}

export const DocumentDetails = ({ userId }: DocumentDetailsProps) => {
  const type = useDocumentDetailsType()
  const { data } = useGetUserQuery({
    variables: { id: userId },
    fetchPolicy: 'cache-only',
  })

  if (!type) {
    return <>Not found</>
  }

  if (!data?.user) {
    return <>Could not load data</>
  }

  const { ead, i94, visa, passport } = data.user

  const tableData = ((): {
    fields: FieldItem[]
    comments?: string | null
  } | null => {
    switch (type) {
      case 'i94':
        if (!i94 || !i94.expiration) {
          return null
        }

        return {
          fields: [
            {
              label: 'Expiration',
              type: 'date',
              value: formatDate(i94.expiration),
            },
            {
              label: 'Date of Entry',
              type: 'date',
              value: i94.dateOfEntry ? formatDate(i94.dateOfEntry) : '',
            },
            {
              label: 'Class of Admission',
              type: 'string',
              value: i94.classOfAdmission,
            },
          ],
        }
      case 'ead':
        if (!ead || !ead.expiration) {
          return null
        }

        return {
          comments: ead.comments,
          fields: [
            {
              label: 'Expiration',
              type: 'date',
              value: formatDate(ead.expiration),
            },
          ],
        }
      case 'passport':
        if (!passport || !passport.expiration) {
          return null
        }

        return {
          comments: '',
          fields: [
            { label: 'Number', type: 'string', value: passport.number },
            {
              label: 'Expiration',
              type: 'date',
              value: formatDate(passport.expiration),
            },
            {
              label: 'Issue Date',
              type: 'date',
              value: passport.issue,
            },
          ],
        }
      case 'visa':
        if (!visa || !visa.expiration) {
          return null
        }

        return {
          comments: visa.comments,
          fields: [
            { label: 'Type', type: 'string', value: visa.type },
            {
              label: 'Expiration',
              type: 'date',
              value: formatDate(visa.expiration),
            },
          ],
        }
    }
  })()

  return (
    <>
      <DetailsTitle>{nameMap[type]} Information</DetailsTitle>

      {tableData ? (
        <>
          <FieldsTable data={tableData.fields} />

          {tableData.comments && (
            <FieldRow>
              <FieldLabel>Comments</FieldLabel>
              <FieldValue>{tableData.comments}</FieldValue>
            </FieldRow>
          )}
        </>
      ) : null}
    </>
  )
}
