import React from 'react'
import { getIcColor } from './colors'
import { DocSvgProps } from './types'

export const Visa = ({ isGray }: DocSvgProps) => {
  return (
    <svg
      width='55'
      height='55'
      viewBox='0 0 55 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 25.9248C15 26.3536 15.3475 26.7012 15.7764 26.7012H36.6864C37.1153 26.7012 37.4628 26.3536 37.4628 25.9248C37.4628 25.496 37.1153 25.1484 36.6864 25.1484H15.7764C15.3475 25.1484 15 25.496 15 25.9248Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M37.0519 42.9873V36.7764C37.0519 36.3476 36.7044 36 36.2756 36H15.7764C15.3475 36 15 36.3476 15 36.7764V42.9873C15 43.4161 15.3475 43.7637 15.7764 43.7637H36.2755C36.7043 43.7637 37.0519 43.4161 37.0519 42.9873ZM35.4992 42.2109H16.5527V37.5527H35.4991V42.2109H35.4992Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M15.7764 30.0137H36.6864C37.1153 30.0137 37.4628 29.6661 37.4628 29.2373C37.4628 28.8085 37.1153 28.4609 36.6864 28.4609H15.7764C15.3475 28.4609 15 28.8085 15 29.2373C15 29.6661 15.3475 30.0137 15.7764 30.0137Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M20.3827 21.0596C20.3827 21.4883 20.7302 21.8359 21.1591 21.8359C21.5879 21.8359 21.9354 21.4883 21.9354 21.0596C21.9354 18.6908 23.8626 16.7637 26.2313 16.7637C28.6001 16.7637 30.5272 18.6908 30.5272 21.0596C30.5272 21.4883 30.8747 21.8359 31.3036 21.8359C31.7325 21.8359 32.08 21.4883 32.08 21.0596C32.08 18.7506 30.7347 16.7502 28.787 15.7999C29.599 15.088 30.1132 14.0441 30.1132 12.8818C30.1132 10.7413 28.3718 9 26.2313 9C24.0908 9 22.3495 10.7413 22.3495 12.8818C22.3495 14.0441 22.8637 15.088 23.6756 15.7999C21.728 16.7502 20.3827 18.7506 20.3827 21.0596ZM23.9022 12.8818C23.9022 11.5975 24.9471 10.5527 26.2313 10.5527C27.5155 10.5527 28.5604 11.5975 28.5604 12.8818C28.5604 14.1662 27.5155 15.2109 26.2313 15.2109C24.9471 15.2109 23.9022 14.1662 23.9022 12.8818Z'
        fill={getIcColor(isGray)}
      />
      <rect
        x='8'
        y='3'
        width='37'
        height='50'
        rx='4'
        stroke={getIcColor(isGray)}
        strokeWidth='2'
      />
    </svg>
  )
}
