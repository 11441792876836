import React from 'react'

export const Call = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.949 12.5086C23.1209 13.8077 22.7494 15.2794 21.7131 16.6758C21.4675 17.0069 21.1843 17.3331 20.8423 17.6925C20.6781 17.8651 20.5501 17.9948 20.2824 18.2628C19.6743 18.8715 19.1631 19.383 18.7488 19.7972C18.5479 19.9981 19.9196 22.7391 22.5815 25.4037C25.2421 28.0668 27.9829 29.4394 28.1847 29.2375L29.7172 27.7041C30.5611 26.8594 31.0081 26.4518 31.6222 26.0489C32.8989 25.2113 34.2915 24.9837 35.466 26.0339C39.3008 28.7769 41.4708 30.4602 42.534 31.5648C44.6076 33.7194 44.3358 37.0359 42.5457 38.928C41.925 39.584 41.1379 40.3715 40.2083 41.2677C34.5853 46.8939 22.7179 43.47 13.6229 34.366C4.52582 25.26 1.10375 13.3915 6.71505 7.77722C7.72245 6.754 8.05459 6.42202 9.0357 5.45529C10.8624 3.65543 14.3319 3.37361 16.441 5.45707C17.5504 6.55299 19.3191 8.82866 21.949 12.5086ZM32.5444 30.5322L31.0116 32.0659C28.4062 34.6727 23.9691 32.4506 19.7541 28.2315C15.5362 24.0094 13.3165 19.574 15.9228 16.9679C16.3366 16.5542 16.8473 16.0432 17.4549 15.435C17.7 15.1896 17.8122 15.076 17.9468 14.9345C18.1302 14.7417 18.2809 14.5725 18.4015 14.4227C16.0708 11.176 14.4864 9.14727 13.6323 8.30353C13.1912 7.86778 12.2034 7.94801 11.8408 8.30531C10.8737 9.25823 10.5558 9.57595 9.55251 10.595C5.94389 14.2056 8.70641 23.7866 16.4504 31.5382C24.1919 39.2874 33.7715 42.0512 37.4076 38.4136C38.3227 37.5309 39.0685 36.7848 39.6425 36.1781C40.0572 35.7397 40.1313 34.8351 39.6542 34.3394C38.8596 33.5137 36.914 31.9968 33.5547 29.5844C33.3099 29.7815 33.0088 30.0673 32.5444 30.5322ZM40 21.9999V17.9999H32.8284L43.4142 7.41407L40.5858 4.58564L30 15.1714V7.99986H26V21.9999H40Z'
        fill='#6494FF'
      />
    </svg>
  )
}
