import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export type SplitInputInputProps = {
  error?: boolean
  disabled?: boolean
}

export const SplitInputInput = styled.input.withConfig({
  shouldForwardProp: prop => true,
})`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  padding: 18px 22px;
  background: #f5f8fd;
  border-radius: 5px;
  border: none;
  outline: none;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: #000000;
  border: 1px solid #f5f8fd;

  border-color: ${(p: SplitInputInputProps) =>
    p.error ? '#F45B39' : '#f5f8fd'};

  opacity: ${(p: SplitInputInputProps) => (p.disabled ? 0.4 : 1)};

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 40px;
    height: 40px;
    padding: 13px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`
