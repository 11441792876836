import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'
import { Color } from '../../../types/color'
import { hProgressBgMap } from '../../molecules/HProgress/bgMap'

export type DotCompletedRootProps = {
  color: Color
  big?: boolean
}

export const DotCompletedRoot = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(p: DotCompletedRootProps) => (p.big ? 31 : 22)}px;
  height: ${(p: DotCompletedRootProps) => (p.big ? 31 : 22)}px;
  border-radius: 50%;
  background-color: ${(p: DotCompletedRootProps) => hProgressBgMap[p.color]};

  @media (max-width: ${breakpoints.sm}px) {
    width: ${(p: DotCompletedRootProps) => (p.big ? 23 : 18)}px;
    height: ${(p: DotCompletedRootProps) => (p.big ? 23 : 18)}px;
  }
`
