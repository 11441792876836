import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const CaseDetailsBtnRoot = styled.button`
  padding: 8px 20px;
  border: 1px solid #a1b9ed;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  @media (max-width: ${breakpoints.sm}px) {
    padding: 4px 14px;
  }
`

export const CaseDetailsBtnImg = styled.img`
  width: 13px;
  min-width: 9px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 9px;
    min-width: 9px;
  }
`
