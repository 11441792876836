import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import { gql } from '@apollo/client'
import { TextField } from '../../atoms/TextField'
import BrandLogo from '../../atoms/BrandLogo/BrandLogo'
import {
  LoginPageBrandWrap,
  LoginPageContent,
  LoginPageError,
  LoginPageFormContainer,
  LoginPageHeader,
  LoginPageInputWrap,
  LoginPagePlaceholder,
  LoginPageRoot,
  LoginPageSigninBtnWrap,
  LoginPageSpinnerWrap,
  LoginPageTitle,
} from './LoginPage.styled'
import { Button } from '../../atoms/Button'
import { useLoginStore } from './login.store'
import { FbSpinner } from '../../atoms/FbSpinner'
import { useLoginMutation } from '../../../api/generated'
import { breakpoints } from '../../../breakpoints'

export const LOGIN_MUTATION = gql`
  mutation Login($login: String!, $password: String!) {
    login(login: $login, password: $password) {
      __typename

      ... on LoginSuccessResponse {
        id
        accessToken
        employeeId
        shouldRegisterOTP
        shouldChangePassword
        mobilenum
      }

      ... on LoginWrongCredentials {
        success
      }
    }
  }
`

export const LoginPage = () => {
  const [execute, { error, loading }] = useLoginMutation()
  const { login } = useLoginStore()
  const [loginValue, setLoginValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [wrongError, setWrongError] = useState(false)

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setWrongError(false)

    if (document.activeElement) {
      const el = document.activeElement as any
      el.blur()
    }

    const { data } = await execute({
      variables: { login: loginValue, password: passwordValue },
    })

    if (data?.login.__typename === 'LoginWrongCredentials') {
      setWrongError(true)
    }

    if (data?.login.__typename === 'LoginSuccessResponse') {
      login(data.login)
    }
  }

  const submitDisabled = loading || !passwordValue || !loginValue

  return (
    <>
      <MediaQuery maxWidth={breakpoints.sm}>
        <style>{`
      html, body, #root-element {
        min-height: 100%;
        background-color: #fff;
    }
      `}</style>
      </MediaQuery>
      <LoginPageRoot>
        <LoginPageContent>
          <LoginPageHeader>
            <LoginPageBrandWrap>
              <BrandLogo />
            </LoginPageBrandWrap>
          </LoginPageHeader>
          <LoginPageFormContainer
            onSubmit={onSubmit}
            action='#'
            autoComplete='on'
            noValidate
          >
            <LoginPageTitle>Sign In to Proceed</LoginPageTitle>

            <LoginPageError
              // eslint-disable-next-line react/forbid-component-props
              style={{ visibility: !wrongError ? 'hidden' : 'unset' }}
            >
              Incorrect Login or Password
            </LoginPageError>

            <LoginPageInputWrap>
              <TextField
                label='Login'
                type='email'
                name='username'
                autoCorrect='off'
                autoFocus
                autoComplete='username'
                placeholder='Type your Login'
                value={loginValue}
                onChange={e => {
                  setLoginValue(e.target.value)
                  setWrongError(false)
                }}
                error={wrongError}
                enterKeyHint='go'
                inputMode='email'
              />
            </LoginPageInputWrap>
            <TextField
              label='Password'
              type='password'
              name='password'
              autoComplete='password'
              placeholder='Type your Password'
              value={passwordValue}
              onChange={e => {
                setPasswordValue(e.target.value)
                setWrongError(false)
              }}
              error={wrongError}
              enterKeyHint='go'
              inputMode='text'
            />
            <LoginPageSigninBtnWrap>
              <Button variant='primary' type='submit' disabled={submitDisabled}>
                Sign In
              </Button>
              {loading && (
                <LoginPageSpinnerWrap>
                  {' '}
                  <FbSpinner />
                </LoginPageSpinnerWrap>
              )}
            </LoginPageSigninBtnWrap>

            {error && 'Something went wrong, try again'}
          </LoginPageFormContainer>
        </LoginPageContent>
        <LoginPagePlaceholder />
      </LoginPageRoot>
    </>
  )
}
