import styled from 'styled-components'
import { Color } from '../../../types/color'
import { hProgressBgMap } from '../../molecules/HProgress/bgMap'
import { breakpoints } from '../../../breakpoints'

type DotRootProps = {
  color: Color
}

export const DotRoot = styled.div`
  border-radius: 50%;
  background-color: ${(p: DotRootProps) => hProgressBgMap[p.color]};
  width: 11px;
  height: 11px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 9px;
    height: 9px;
  }
`
