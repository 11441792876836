import React from 'react'

export const ExitIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.61538 23L4.38462 23C2.51534 23 1 21.4847 1 19.6154L1 4.38461C1 2.51534 2.51535 0.999999 4.38462 0.999999L8.61539 0.999999M6.07692 12L23 12M23 12L17.9231 6.92308M23 12L17.9231 17.0769'
        stroke='#BEBFC3'
        strokeWidth='2'
      />
    </svg>
  )
}
