import React from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  ActiveCaseName,
  CaseBottom,
  CaseBottomFooter,
  CaseHeader,
  CaseStatusWrap,
  CaseSubHeader,
  CaseTitle,
} from './Case.styled'
import { HProgress } from '../HProgress'
import { useHover } from '../../../hooks/useHover'
import { CaseDetailsBtn } from '../../atoms/CaseDetailsBtn'
import { DotMiddle } from '../../atoms/DotMiddle'
import { Color } from '../../../types/color'
import { CardField } from '../../atoms/CardField'
import { PointTextTmpl } from '../../templates/PointTextTmpl'
import { CardTmpl } from '../../templates/CardTmpl/CardTmpl'
import { DotCompleted } from '../../atoms/DotCompleted'
import { breakpoints } from '../../../breakpoints'
import { Case as CaseData, CaseStatus } from '../../../api/generated'
import { formatDate } from '../../../utils/formatDate'
import { DotCancelled } from '../../atoms/DotCancelled/DotCancelled'
import { sortMilestones } from '../../../utils/sortMilestones'

export type CaseProps = {
  caseData: Omit<CaseData, 'files'>
  color: Color
  onClickDetails?: () => void
}

export const Case = ({ caseData, color, onClickDetails }: CaseProps) => {
  const {
    name,
    initiationDate,
    completionDate,
    milestones: _milestones,
    status,
  } = caseData

  const milestones = sortMilestones(_milestones)

  const _points = milestones.map(m => ({
    title: m.name,
    desc: m.completedDate ? formatDate(m.completedDate) : '',
  }))

  const { hover, ...hoverHandlers } = useHover()
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md })
  const points = [
    { title: 'Initiated', desc: formatDate(initiationDate) },
    ..._points,
    {
      title: 'Completion',
      desc: completionDate ? formatDate(completionDate) : '',
    },
  ]
  let activePointIdx = 0
  if (completionDate) {
    activePointIdx = points.length - 1
  } else {
    for (let i = milestones.length - 1; i >= 0; i--) {
      const item = milestones[i]
      if (item.completedDate) {
        activePointIdx = i + 1
        break
      }
    }
  }

  const activePoint = points[activePointIdx]

  let statusDot: React.ReactNode = null
  let statusText = ''
  switch (status) {
    case CaseStatus.Completed:
      statusDot = <DotCompleted color={color} />
      statusText = 'Completed'
      break
    case CaseStatus.Deactivated:
      statusDot = <DotCancelled color={color} />
      statusText = 'Deactivated'
      break
    default:
      statusDot = <DotMiddle color={color} />
      statusText = activePoint?.title
  }

  return (
    <div {...hoverHandlers}>
      <CardTmpl
        topContent={
          <>
            <CaseHeader>
              <CaseTitle>{name}</CaseTitle>
            </CaseHeader>

            <CaseSubHeader>
              <CardField>Initiated: {formatDate(initiationDate)}</CardField>
              {completionDate && (
                <CardField>Completion: {formatDate(completionDate)}</CardField>
              )}
            </CaseSubHeader>
          </>
        }
        bottomContent={
          <CaseBottom>
            <HProgress
              points={points}
              activePointIdx={activePointIdx}
              hover={hover || isMobile}
              color={color}
              renderActivePoint={
                status === CaseStatus.Completed
                  ? props => <DotCompleted {...props} />
                  : // eslint-disable-next-line no-undefined
                    undefined
              }
            />

            <CaseBottomFooter>
              <CaseStatusWrap>
                <PointTextTmpl
                  point={statusDot}
                  text={<ActiveCaseName>{statusText}</ActiveCaseName>}
                />
              </CaseStatusWrap>
              <CaseDetailsBtn onClick={onClickDetails} />
            </CaseBottomFooter>
          </CaseBottom>
        }
      />
    </div>
  )
}
