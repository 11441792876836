import { useEffect } from 'react'

let removedCount = 0

export const RemoveDocScroll = () => {
  useEffect(() => {
    removedCount++
    document.documentElement.style.overflow = 'hidden'
    return () => {
      removedCount--
      if (removedCount === 0) {
        document.documentElement.style.overflow = 'initial'
      }
    }
  }, [])

  return null
}
