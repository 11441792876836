import React, { useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

import {
  OtpPaper,
  OtpDescription,
  OtpWrap,
  OtpContentWrap,
  OtpHeader,
  OtpContentInner,
  OtpLabel,
  OtpInputsRow,
  OtpFooter,
  OtpContentInnerWrap,
  OtpTitle,
  OtpButton,
} from '../../OtpPage.styled'
import { useRegisterOtpMutation } from '../../../../../api/generated'
import {
  mobilePhoneStore$,
  useAuth,
} from '../../../../pages/LoginPage/login.store'
import { PhoneInputRoot } from '../../../../atoms/PhoneInput/PhoneInput.styled'
import { RegNumLabelWrap } from './RegNumberScreen.styled'

export const REGISTER_OTP_MUTATION = gql`
  mutation RegisterOtp(
    $countryCode: String!
    $mobilenum: String!
    $userId: String!
  ) {
    registerOtp(
      countryCode: $countryCode
      mobilenum: $mobilenum
      userId: $userId
    )
  }
`

export type RegNumberScreenProps = {
  onContinue: () => void
}

export const RegNumberScreen = ({ onContinue }: RegNumberScreenProps) => {
  const [registerOtp, { loading }] = useRegisterOtpMutation()
  const auth = useAuth()

  const [value, setValue] = useState('')

  const phone = useMemo(() => parsePhoneNumber(value || ''), [value])

  const onRegister = async () => {
    if (auth.isAuth && phone) {
      const { data } = await registerOtp({
        variables: {
          countryCode: phone.countryCallingCode,
          mobilenum: phone.nationalNumber,
          userId: auth.employeeId,
        },
      })
      if (data?.registerOtp) {
        const lastNums = phone.number.slice(-4)
        const placeholder = '*'.repeat(phone.number.length - 4)
        mobilePhoneStore$.setState(`${placeholder}${lastNums}`)
        onContinue()
      }
    }
  }

  return (
    <OtpWrap>
      <OtpPaper>
        <OtpHeader></OtpHeader>

        <OtpContentWrap>
          <OtpContentInnerWrap>
            <OtpContentInner>
              <OtpTitle>Register your Mobile number</OtpTitle>

              <OtpDescription>
                To verify your identity, please register your mobile phone
                number to receive a One-Time Password (OTP)
              </OtpDescription>

              <RegNumLabelWrap>
                <OtpLabel>Your Phone Number</OtpLabel>
              </RegNumLabelWrap>

              <OtpInputsRow>
                <PhoneInput
                  placeholder='Enter Phone Number'
                  defaultCountry='US'
                  value={value}
                  onChange={setValue}
                  inputComponent={PhoneInputRoot}
                />
              </OtpInputsRow>
            </OtpContentInner>
          </OtpContentInnerWrap>
        </OtpContentWrap>
        <OtpFooter>
          <OtpButton
            variant='primary'
            onClick={onRegister}
            disabled={loading || !phone}
          >
            Continue
          </OtpButton>
        </OtpFooter>
      </OtpPaper>
    </OtpWrap>
  )
}
