import styled from 'styled-components'
import { hProgressBgMap } from '../../molecules/HProgress/bgMap'
import { Color } from '../../../types/color'

export type DotMiddleRootProps = {
  color: Color
}

export const DotMiddleRoot = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${(p: DotMiddleRootProps) => hProgressBgMap[p.color]};
`

export const DotMiddleInner = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ffffff;
`
