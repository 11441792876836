import React from 'react'
import { CardBottomWrap, CardRoot, CardTopWrap } from './CardTmpl.styled'

export type CardTmplProps = {
  topContent: React.ReactNode
  bottomContent: React.ReactNode
}

export const CardTmpl = ({ bottomContent, topContent }: CardTmplProps) => {
  return (
    <CardRoot>
      <CardTopWrap>{topContent}</CardTopWrap>
      <CardBottomWrap>{bottomContent}</CardBottomWrap>
    </CardRoot>
  )
}
