import { useMemo } from 'react'
import { useQueryURL } from '../../../hooks/useQueryURL'

export type GCardDetailsParams = {
  category: string | null
}

export function useGCardDetailsParams(): GCardDetailsParams {
  const query = useQueryURL()
  return useMemo(
    () => ({
      category: query.get(GCardDetailsCategoryParam),
    }),
    [query],
  )
}

export const GCardDetailsCategoryParam = 'category'
