import { gql } from '@apollo/client'
import { useAuthCheckQuery } from '../../../api/generated'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { createStore } from '../../../utils/store'

export const AUTH_CHECK_QUERY = gql`
  query AuthCheck {
    authCheck {
      ... on AuthCheckSuccessResponse {
        employeeId
      }
    }
  }
`

export type LoginStateAuth = {
  isAuth: true
  isTemporarily: boolean
  employeeId: string
  isInitialization: boolean
  shouldRegisterOTP: boolean
  shouldChangePassword: boolean
}

export type LoginStateNotAuth = {
  isAuth: false
  isInitialization: boolean
}

export type LoginState = LoginStateAuth | LoginStateNotAuth

export type LoginParams = {
  employeeId: string
  accessToken: string
  shouldRegisterOTP: boolean
  shouldChangePassword: boolean
  mobilenum: string
}

export type VerifyParams = {
  accessToken: string
  refreshToken: string
}

export type LoginStore = {
  state: LoginState
  login: (params: LoginParams) => void | Promise<void>
  verify: (params: VerifyParams) => void | Promise<void>
  logout: () => void
  setPasswordChanged: () => void
}

const Ctx = createContext<LoginStore>(null as any)

export function useAuth(): LoginState {
  const store = useContext(Ctx)
  return store.state
}

export function useLoginStore(): LoginStore {
  return useContext(Ctx)
}

export const LoginStoreProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { refetch: refetchAuthCheck } = useAuthCheckQuery({ skip: true })

  const [state, setState] = useState<LoginState>({
    isAuth: false,
    isInitialization: true,
  })

  const login = useCallback(
    ({
      employeeId,
      accessToken,
      shouldRegisterOTP,
      shouldChangePassword,
      mobilenum,
    }: LoginParams) => {
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('employeeId', employeeId)
      setState({
        isAuth: true,
        isInitialization: false,
        employeeId,
        shouldRegisterOTP,
        isTemporarily: true,
        shouldChangePassword,
      })
      if (mobilenum) {
        mobilePhoneStore$.setState(mobilenum)
      }
    },
    [],
  )

  const verify = useCallback(({ accessToken, refreshToken }: VerifyParams) => {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    setState(
      prev =>
        ({
          ...prev,
          isAuth: true,
          isTemporarily: false,
          shouldRegisterOTP: false,
        } as any),
    )
  }, [])

  const resetAuth = useCallback(() => {
    setState({ isAuth: false, isInitialization: false })
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('employeeId')
  }, [])

  const setPasswordChanged = useCallback(() => {
    setState(prev => ({ ...prev, shouldChangePassword: false }))
  }, [])

  const store = useMemo(
    () => ({ state, login, logout: resetAuth, verify, setPasswordChanged }),
    [login, resetAuth, state, verify, setPasswordChanged],
  )

  useEffect(() => {
    refetchAuthCheck().then(({ data }) => {
      if (data?.authCheck.__typename === 'AuthCheckSuccessResponse') {
        setState({
          isAuth: true,
          isInitialization: false,
          employeeId: data.authCheck.employeeId,
          shouldRegisterOTP: false,
          isTemporarily: false,
          shouldChangePassword: false,
        })
      } else {
        resetAuth()
      }
    })
  }, [refetchAuthCheck, resetAuth])

  return <Ctx.Provider value={store}>{children}</Ctx.Provider>
}

export const mobilePhoneStore$ = createStore<string | null>(null)
