import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

const BorderRadius = '15px'

export const CardRoot = styled.div`
  /* padding-bottom: 10px; */
  box-shadow: 10px 10px 20px rgba(47, 55, 120, 0.1);
  border-radius: ${BorderRadius};
`

export const CardTopWrap = styled.div`
  padding: 24px;
  padding-bottom: 15px;
  border-top-left-radius: ${BorderRadius};
  border-top-right-radius: ${BorderRadius};
  background-color: #fff;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 15px;
  }
`

export const CardBottomWrap = styled.div`
  padding: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #f5f8fd;
  border-bottom-left-radius: ${BorderRadius};
  border-bottom-right-radius: ${BorderRadius};

  @media (max-width: ${breakpoints.sm}px) {
    padding: 15px;
  }
`
