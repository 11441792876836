import React from 'react'
import { CaseDetailsBtnImg, CaseDetailsBtnRoot } from './CaseDetailsBtn.styled'
import Icon from './icon.svg'

export type CaseDetailsBtnProps = {
  onClick?: () => void
}

export const CaseDetailsBtn = ({ onClick }: CaseDetailsBtnProps) => {
  return (
    <CaseDetailsBtnRoot type='button' onClick={onClick}>
      <CaseDetailsBtnImg src={Icon} />
    </CaseDetailsBtnRoot>
  )
}
