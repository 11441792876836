import styled from 'styled-components'

export type ButtonProps = {
  variant: ButtonVariant
}

export type ButtonVariant = 'primary' | 'secondary'

const bgMap: Record<ButtonVariant, string> = {
  primary: '#6494ff',
  secondary: '#fff',
}

const borderColorMap: Record<ButtonVariant, string> = {
  primary: '#6494ff',
  secondary: '#DADADA',
}

const colorMap: Record<ButtonVariant, string> = {
  primary: '#fff',
  secondary: '#000',
}

export const Button = styled.button`
  min-width: 170px;
  padding: 13px 20px;
  background: ${(p: ButtonProps) => bgMap[p.variant]};
  outline: none;
  border-radius: 5px;
  border: 1px solid ${(p: ButtonProps) => borderColorMap[p.variant]};
  color: ${(p: ButtonProps) => colorMap[p.variant]};
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`
