import React from 'react'
import { DocumentIcon, DocumentIconType } from '../../icons/DocumentIcon'
import { DocChip } from '../../atoms/DocChip'
import { Clock } from '../../icons/Clock'
import {
  DocumentBoldName,
  DocumentChipWrap,
  DocumentExpires,
  DocumentIconWrap,
  DocumentName,
  DocumentNameWrap,
  DocumentRightSectionWrap,
  DocumentRoot,
} from './Document.styled'
import { useDetailsRouter } from '../../organisms/DetailsPanel/useDetailsRouter'
import { formatDate } from '../../../utils/formatDate'

export type DocumentProps = {
  typeName: string
  name: string
  expires: Date
  type: DocumentIconType
  isExtension?: boolean
}

export const Document = ({
  expires,
  name,
  typeName,
  isExtension,
  type,
}: DocumentProps) => {
  const detailsRouter = useDetailsRouter()

  const currDate = new Date()
  const isExpired = expires < currDate
  const isParole = type === 'parole'

  return (
    <DocumentRoot
      isExpired={isExpired}
      isParole={isParole}
      onClick={() => {
        if (type !== 'parole') {
          detailsRouter.goToDocumentDetails({ documentType: type })
        }
      }}
    >
      {isExtension && (
        <DocumentChipWrap>
          <DocChip label='Extension' endIcon={<Clock />} />
        </DocumentChipWrap>
      )}
      <DocumentIconWrap isExpired={isExpired} isParole={isParole}>
        <DocumentIcon type={type} isGray={isExpired} />
      </DocumentIconWrap>
      <DocumentRightSectionWrap>
        <DocumentNameWrap>
          <DocumentName isExpired={isExpired} isParole={isParole}>
            <DocumentBoldName>{typeName}</DocumentBoldName> {name}
          </DocumentName>
        </DocumentNameWrap>
        <DocumentExpires isExpired={isExpired} isParole={isParole}>
          {isExpired ? 'Expired' : 'Expires'} {formatDate(expires)}
        </DocumentExpires>
      </DocumentRightSectionWrap>
    </DocumentRoot>
  )
}
