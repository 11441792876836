import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const FieldRow = styled.div`
  padding: 14px 35px;
  border-bottom: 1px solid #f2f3f5;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
