import styled from 'styled-components'

export const DocChipRoot = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 6px 7px 6px 14px;
  background: #585b78;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  line-height: 1;
  font-size: 10px;
  font-weight: 500;
`

export const DotChipLabelWrap = styled.div`
  text-transform: uppercase;
  color: #ffffff;
`

export const DotChipEndIconWrap = styled.div`
  margin-left: 5px;
`
