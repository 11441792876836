import styled from 'styled-components'

export const DetailsPanelHead = styled.div`
  padding-top: 20px;
  padding-left: 30px;
`

export const DetailsPanelMobileHead = styled.div`
  height: 53px;
  display: flex;
  position: relative;
  padding: 10px;
  border: 1px solid #f2f3f5;
`

export const DetailsPanelMobileHeadCloseWrap = styled.div`
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
`

export const DetailsPanelMobileHeadLogoWrap = styled.div`
  width: 90px;
  margin: 0 auto;
`

export const DetailsPanelRoot = styled.div`
  height: 100%;
  overflow: auto;
`

export const DetailsPanelInnerRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`
