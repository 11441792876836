import React, { useEffect, useReducer } from 'react'
import { SplitInput } from '../../../../atoms/SplitInput'
import { SmsSmall } from '../../../../icons/SmsSmall'
import {
  OtpBtnWrap,
  OtpButton,
  OtpContentInner,
  OtpContentInnerWrap,
  OtpContentWrap,
  OtpDescription,
  OtpFooter,
  OtpHeader,
  OtpLabel,
  OtpLabelError,
  OtpPaper,
  OtpTitle,
  OtpWrap,
} from '../../OtpPage.styled'
import {
  SendAgain,
  SendAgainDisabled,
  SendAgainIconWrap,
  SmsScreenInputWrap,
} from './SmsScreen.styled'
import { useVerifyLogic } from '../../hooks'
import { useStore } from '../../../../../utils/store'
import { mobilePhoneStore$ } from '../../../../pages/LoginPage/login.store'
import { SmsSmallDisabled } from '../../../../icons/SmsSmallDisabled'

export type SmsScreenProps = {
  userId: string
  lastSent: Date | null
  onCancel: () => void
  onCompleted: () => void
  onResend: () => void
}

export const SmsScreen = ({
  onCancel,
  onCompleted,
  userId,
  lastSent,
  onResend,
}: SmsScreenProps) => {
  const phoneNum = useStore(mobilePhoneStore$)
  const [, forceUpdate] = useReducer(s => s + 1, 0)

  const { loading, setValue, value, wrongErr, submitHandler } = useVerifyLogic({
    userId,
    onCompleted,
  })

  const currTime = new Date().getTime()
  const deadline = lastSent ? lastSent.getTime() + 60 * 1000 : currTime
  const leftTime = deadline - currTime
  const leftSeconds = Math.round(leftTime / 1000)

  useEffect(() => {
    setInterval(() => {
      forceUpdate()
    }, 1000)
  }, [])

  return (
    <OtpWrap>
      <OtpPaper>
        <OtpHeader></OtpHeader>
        <OtpContentWrap>
          <OtpContentInnerWrap>
            <OtpContentInner>
              <OtpTitle>Enter the Code from SMS</OtpTitle>
              <OtpDescription>
                Please note that depending on your carrier, it may take a few
                moments for the verification code to arrive. Standard SMS
                messaging rates from your carrier may apply.
              </OtpDescription>

              {wrongErr ? (
                <OtpLabelError>Please check the code</OtpLabelError>
              ) : (
                <OtpLabel>Code was sent to {phoneNum}</OtpLabel>
              )}
              <SmsScreenInputWrap>
                <SplitInput
                  value={value}
                  disabled={loading}
                  onChange={next => setValue(next)}
                  sections={7}
                  error={wrongErr}
                />
              </SmsScreenInputWrap>

              {leftSeconds > 0 ? (
                <SendAgainDisabled>
                  <SendAgainIconWrap>
                    <SmsSmallDisabled />
                  </SendAgainIconWrap>
                  Resend the code in {leftSeconds}s
                </SendAgainDisabled>
              ) : (
                <SendAgain onClick={onResend}>
                  <SendAgainIconWrap>
                    <SmsSmall />
                  </SendAgainIconWrap>
                  Resend the code
                </SendAgain>
              )}
            </OtpContentInner>
          </OtpContentInnerWrap>
        </OtpContentWrap>
        <OtpFooter>
          <OtpBtnWrap>
            <OtpButton
              variant='secondary'
              disabled={loading}
              onClick={onCancel}
            >
              Choose Another Method
            </OtpButton>
          </OtpBtnWrap>
          <OtpButton
            variant='primary'
            disabled={loading}
            onClick={submitHandler}
          >
            Submit
          </OtpButton>
        </OtpFooter>
      </OtpPaper>
    </OtpWrap>
  )
}
