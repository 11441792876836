import React from 'react'
import MediaQuery from 'react-responsive'
import { useHistory } from 'react-router'
import { CardListTmpl } from '../../templates/CardListTmpl'
import { Case } from '../../molecules/Case'
import { ContentContainer } from '../../atoms/ContentContainer'
import { ContentTitle } from '../../atoms/ContentTitle'
import {
  CasesPageBackBtn,
  CasesPageRoot,
  CasesPageTitleWrap,
} from './InactiveCasesPage.styled'
import { ArrowIcon } from '../../icons/ArrowIcon'
import { CasesPeriodSection } from '../../templates/CasesPeriodSection'
import { breakpoints } from '../../../breakpoints'
import { Case as CaseData } from '../../../api/generated'
import {
  addMonth,
  getThisMonthDate,
  getThisYearDate,
} from '../../../utils/formatDate'
import { sortCasesList } from '../../../utils/sortCasesList'
import { SectionTmplPlaceholderText } from '../../templates/SectionTmpl'

export type InactiveCasesScreenProps = {
  cases: CaseData[]
  loading: boolean
  onClickCaseDetails: (pr: { caseId: string }) => void
}

export const InactiveCasesScreen = ({
  cases,
  onClickCaseDetails,
  loading,
}: InactiveCasesScreenProps) => {
  const history = useHistory()

  const currDate = new Date()
  const thisMonthDate = getThisMonthDate(currDate)
  const lastMonthDate = addMonth(thisMonthDate, -1)
  const thisYearDate = getThisYearDate(currDate)

  const thisMonthCases: typeof cases = []
  const lastMonthCases: typeof cases = []
  const thisYearCases: typeof cases = []
  const olderCases: typeof cases = []

  cases.forEach(c => {
    if (c.initiationDate >= thisMonthDate) {
      thisMonthCases.push(c)
    } else if (c.initiationDate >= lastMonthDate) {
      lastMonthCases.push(c)
    } else if (
      c.initiationDate >= thisYearDate &&
      c.initiationDate.getFullYear() === thisYearDate.getFullYear()
    ) {
      thisYearCases.push(c)
    } else {
      olderCases.push(c)
    }
  })

  const renderCases = (cases: CaseData[], periodStr: string) => {
    if (!cases.length) {
      return null
    }

    return (
      <CasesPeriodSection periodContent={periodStr}>
        <CardListTmpl
          mobileSliderOff
          itemsJSX={sortCasesList(cases).map(c => (
            <Case
              {...c}
              caseData={c}
              key={c.id}
              color='grey'
              onClickDetails={() => {
                onClickCaseDetails({ caseId: c.id })
              }}
            />
          ))}
        />
      </CasesPeriodSection>
    )
  }

  return (
    <CasesPageRoot>
      <ContentContainer>
        <MediaQuery minWidth={breakpoints.md + 1}>
          <CasesPageTitleWrap>
            <CasesPageBackBtn onClick={() => history.goBack()}>
              <ArrowIcon />
            </CasesPageBackBtn>
            <ContentTitle>Inactive Cases</ContentTitle>
          </CasesPageTitleWrap>
        </MediaQuery>

        {loading || cases.length > 0 ? (
          <>
            {renderCases(thisMonthCases, 'This Month')}
            {renderCases(lastMonthCases, 'Last month')}
            {renderCases(thisYearCases, 'Earlier this Year')}
            {renderCases(olderCases, 'Older')}
          </>
        ) : (
          <SectionTmplPlaceholderText>
            There are no cases to show
          </SectionTmplPlaceholderText>
        )}
      </ContentContainer>
    </CasesPageRoot>
  )
}
