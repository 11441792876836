import React from 'react'
import { RemoveDocScroll } from '../../atoms/RemoveDocScroll'
import { BackDrop } from '../../atoms/BackDrop'
import { DrawerRoot } from './Drawer.styled'

export type DrawerProps = {
  children: React.ReactNode
  open: boolean
  onClose: () => void
}

export const Drawer = ({ children, onClose, open }: DrawerProps) => {
  return (
    <>
      {open && (
        <>
          <BackDrop onClick={onClose} />
          <RemoveDocScroll />
        </>
      )}
      <DrawerRoot transitionOff={false} open={open}>
        {children}
      </DrawerRoot>
    </>
  )
}
