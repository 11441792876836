import React from 'react'
import { DocumentIconRoot } from './DocumentIcon.styled'
import * as docIconsMap from './img'

export type DocumentIconProps = {
  type: DocumentIconType
  isGray: boolean
}

export type DocumentIconType = keyof typeof docIconsMap

export const DocumentIcon = ({ type, isGray }: DocumentIconProps) => {
  const Icon = docIconsMap[type]
  return (
    <DocumentIconRoot>
      <Icon isGray={isGray} />
    </DocumentIconRoot>
  )
}
