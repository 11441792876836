import { useQueryURL } from '../../../hooks/useQueryURL'

export type DetailsScreenQueryType =
  | typeof DetailsCaseScreenType
  | typeof DetailsGCardScreenType
  | typeof DetailsDocsScreenType
  | typeof DetailsGCardCaseScreenType

export function useDetailsScreenType(): DetailsScreenQueryType | null {
  const query = useQueryURL()
  return query.get(DetailsScreenParam) as DetailsScreenQueryType
}

export const DetailsScreenParam = 'details_screen'

export const DetailsGCardScreenType = 'green_card'
export const DetailsCaseScreenType = 'case'
export const DetailsDocsScreenType = 'docs'
export const DetailsGCardCaseScreenType = 'green_card_case'
