import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const DocumentDepRoot = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  border-radius: 15px;
  border: 1px solid rgba(88, 91, 120, 0.11);

  @media (max-width: ${breakpoints.sm}px) {
    padding: 8px 6px;
  }
`

export const DocumentDepIconWrap = styled.div`
  width: 38px;
`

export const DocumentDepTextWrap = styled.div`
  margin-top: 5px;
  padding-left: 5px;
`

export const DocumentDepTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #2f3778;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 10px;
    line-height: 12px;
  }
`

export const DocumentDepSubtitle = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  color: #585b78;

  opacity: 0.75;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 8px;
    line-height: 10px;
  }
`
