import React, { useMemo } from 'react'
import { CaseDetailsBtn } from '../../atoms/CaseDetailsBtn'
import { CardTmpl } from '../../templates/CardTmpl/CardTmpl'
import {
  DependentAvatar,
  DependentDocList,
  DependentDocWrap,
  DependentName,
  DependentRole,
  DependentTextWrap,
  DependentTop,
  DependentUser,
} from './Dependent.styled'
import { DocumentDep } from '../../molecules/DocumentDep'
import { UserShortInfo } from '../../../api/generated'
import { Image } from '../../atoms/Image'
import defaultAvatar from '../../atoms/Avatar/default-avatar.svg'

export type DependentProps = {
  onClickDetails: () => void
  data: UserShortInfo
}

export const Dependent = ({ onClickDetails, data }: DependentProps) => {
  const {
    lastName,
    middleName,
    firstName,
    role,
    profile: { image_url },
    i94,
    ead,
    importantDocs,
    currentStatus,
    advancedParole,
    passport,
    visa,
    type,
  } = data

  const fullName = [firstName, lastName, middleName].filter(Boolean).join(' ')

  const docsJsx = useMemo(() => {
    return importantDocs
      .map(field => {
        switch (field) {
          case 'i94':
            return i94 ? (
              <DocumentDep
                type='i94'
                typeName='I-94'
                expires={i94.expiration!}
              />
            ) : null
          case 'ead':
            return ead ? (
              <DocumentDep
                type='ead'
                typeName='EAD'
                expires={ead.expiration!}
              />
            ) : null
          case 'passport':
            return passport ? (
              <DocumentDep
                type='passport'
                typeName='Passport'
                expires={passport.expiration!}
              />
            ) : null

          case 'visa':
            return visa ? (
              <DocumentDep
                type='visa'
                typeName='Visa'
                expires={visa.expiration!}
              />
            ) : null

          case 'currentStatus':
            return currentStatus ? (
              <DocumentDep type='currentStatus' typeName={currentStatus} />
            ) : null
          case 'ap':
            return advancedParole ? (
              <DocumentDep
                type='parole'
                typeName='Advance Parole'
                expires={advancedParole!.expirationDate!}
              />
            ) : null
        }
      })
      .filter(Boolean)
  }, [advancedParole, currentStatus, ead, i94, importantDocs, passport, visa])

  return (
    <CardTmpl
      topContent={
        <DependentTop>
          <DependentUser>
            <DependentAvatar>
              <Image
                src={image_url || defaultAvatar}
                placeHolderSrc={defaultAvatar}
              />
            </DependentAvatar>
            <DependentTextWrap>
              <DependentName>{fullName}</DependentName>
              <DependentRole>{role || type}</DependentRole>
            </DependentTextWrap>
          </DependentUser>
          <div>
            <CaseDetailsBtn onClick={onClickDetails} />
          </div>
        </DependentTop>
      }
      bottomContent={
        <DependentDocList>
          {docsJsx.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <DependentDocWrap key={idx}>{item}</DependentDocWrap>
          ))}
        </DependentDocList>
      }
    />
  )
}
