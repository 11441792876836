import React, { useEffect } from 'react'
import { SplitInputInput } from './SplitInput.styled'

export type SplitInputProps = {
  sections: number
  value: string
  onChange: (nextStr: string) => void
  error?: boolean
  disabled?: boolean
}

export const SplitInput = ({
  onChange,
  value,
  sections,
  error,
  disabled,
}: SplitInputProps) => {
  const arr = Array(sections).fill(0)
  const values = value.split('')
  const values1 = arr.map((_, idx) => values[idx] || '')
  let refs: {
    [idx: number]: HTMLInputElement | undefined
  } = {}

  const onChangeSection = (idx: number, _inputVal: string) => {
    const inputVal = _inputVal.replace(/[^\d]/g, '')

    const next = [...values1]
    // eslint-disable-next-line prefer-destructuring

    next[idx] = inputVal

    const nextValue = next.join('')
    onChange(nextValue)

    let nextIdx: number
    if (nextValue.length > value.length) {
      nextIdx = idx + 1
    } else {
      nextIdx = idx - 1
    }

    const nextInput = refs[nextIdx]
    if (nextInput) {
      nextInput.focus()
      setTimeout(() => nextInput.setSelectionRange(1, 1), 0)
    }
  }

  useEffect(() => {
    if (value.length === 0) {
      const inp = refs[0]!
      inp.focus()
      setTimeout(() => inp.setSelectionRange(1, 1), 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div>
      {arr.map((_, idx) => (
        <SplitInputInput
          error={error}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          ref={r => {
            if (r) {
              refs = { ...refs, [idx]: r }
            }
          }}
          tabIndex={1}
          autoFocus={idx === 0}
          inputMode='numeric'
          disabled={disabled}
          value={values1[idx]}
          onChange={e => onChangeSection(idx, e.target.value)}
          onClick={() => {
            const lastIdx = values.length - 1
            if (lastIdx > 0 && refs[lastIdx + 1]) {
              refs[lastIdx + 1]?.focus()
            }
          }}
          onKeyUp={e => {
            if (e.keyCode === 8 && !values1[idx]) {
              const prevRef = refs[idx - 1]
              if (prevRef) {
                prevRef.focus()
                setTimeout(() => prevRef.setSelectionRange(1, 1), 0)
              }
            }
          }}
        />
      ))}
    </div>
  )
}
