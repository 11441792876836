import styled, { keyframes } from 'styled-components'

export const FbSpinnerRoot = styled.div`
  width: 25px;
  height: 28px;
`

const animation = keyframes`
    0% {
        transform: scale(1, 1);
        opacity: 1;
    }
    
    100% {
        transform: scale(0.7, 0.7);
    opacity: 0.1;
    }
`

type FbSpinnerItemProps = {
  num: number
}

export const FbSpinnerItem = styled.div`
  float: left;
  width: 7px;
  height: 28px;
  background-color: #8b9dc3;
  border: 1px solid #3b5998;
  box-sizing: border-box;
  animation-name: ${animation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: ${(p: FbSpinnerItemProps) => p.num * 100}ms;
`
