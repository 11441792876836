import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export type DrawerRootProps = {
  open: boolean
  transitionOff: boolean
}

const DrawerWidth = 700

export const DrawerRoot = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: ${DrawerWidth}px;
  z-index: 101;
  background-color: #ffffff;

  transform: translateX(
    ${(p: DrawerRootProps) => (p.open ? '0' : `${DrawerWidth}px`)}
  );

  transition: ${(p: DrawerRootProps) =>
    p.transitionOff ? 'none' : 'transform 0.15s ease'};

  @media (max-width: ${breakpoints.sm}px) {
    width: 100vw;
    transform: translateX(${(p: DrawerRootProps) => (p.open ? '0' : `100%`)});
  }
`
