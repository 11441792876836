import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'
import { Modal } from '../Modal'

export const UserRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UserAvatarWrap = styled.div`
  position: relative;
  margin-bottom: 20px;
`

export const UserAvatarSpinnerWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`

export const UserName = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #282b32;
`

export const UserSecondaryInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: #585b78;
`

export const UserLocationPinWrap = styled.span`
  margin-right: 5px;
`

export const UserChangeAvatarModal = styled(Modal)`
  width: 600px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 95%;
  }
`
