import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const DetailsTitle = styled.div`
  max-width: 100%;
  word-break: break-all;
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 25px;

  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  color: #2f3778;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
    line-height: 20px;
  }
`
