/* eslint-disable react/forbid-component-props */
import React, { useState } from 'react'
import { changeAvatar } from '../../../api/api.instance'
import { useGetUserQuery } from '../../../api/generated'
import { FbSpinner } from '../../atoms/FbSpinner'
import { Avatar } from '../../atoms/Avatar'
import { PinIcon } from '../../icons/PinIcon'
import { AvatarEdit, UploadImageInfo } from '../AvatarEdit'
import {
  UserAvatarSpinnerWrap,
  UserAvatarWrap,
  UserLocationPinWrap,
  UserName,
  UserRoot,
  UserSecondaryInfo,
} from './User.styled'

export type UserProps = {
  user_id: string
  avatarUrl: string | null | undefined
  name: string
  jobText: string
  locationText: string
  isDependent?: boolean
}

export const User = ({
  avatarUrl,
  jobText,
  locationText,
  name,
  user_id,
  isDependent,
}: UserProps) => {
  const { loading, refetch } = useGetUserQuery({
    fetchPolicy: 'network-only',
    variables: { id: user_id },
    skip: true,
  })
  const [inEdit, setInEdit] = useState(false)
  const [changePending, setChangePending] = useState(false)

  const changeAvatarSubmit = async (imageInfo: UploadImageInfo) => {
    setChangePending(true)
    try {
      await changeAvatar(user_id, imageInfo)
      refetch()
    } catch (error) {
      console.error(error)
    }
    setChangePending(false)
  }

  return (
    <UserRoot>
      <UserAvatarWrap>
        {(changePending || loading) && (
          <UserAvatarSpinnerWrap>
            <FbSpinner />
          </UserAvatarSpinnerWrap>
        )}
        <Avatar
          url={avatarUrl}
          onClick={
            !isDependent
              ? () => {
                  setInEdit(true)
                }
              : // eslint-disable-next-line no-undefined
                undefined
          }
        />
      </UserAvatarWrap>
      <UserName>{name}</UserName>
      <UserSecondaryInfo>{jobText}</UserSecondaryInfo>
      <UserSecondaryInfo>
        <UserLocationPinWrap>
          <PinIcon />
        </UserLocationPinWrap>{' '}
        {locationText}
      </UserSecondaryInfo>

      <AvatarEdit
        onSubmitUpload={changeAvatarSubmit}
        onClose={() => setInEdit(false)}
        open={inEdit}
      />
    </UserRoot>
  )
}
