import React from 'react'
import ReactDOM from 'react-dom'

const rootStyles: React.CSSProperties = {
  position: 'relative',
  zIndex: 999,
}

export class ModalPortal extends React.Component<{
  children: React.ReactNode
}> {
  private readonly el = document.createElement('div')
  private readonly modalRoot = document.getElementById('modal-root-element')!

  componentDidMount() {
    // Append the element into the DOM on mount. We'll render
    // into the modal container element (see the HTML tab).
    Object.assign(this.modalRoot.style, rootStyles)
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    // Remove the element from the DOM when we unmount
    Object.keys(rootStyles).forEach(
      key => delete (this.modalRoot.style as any)[key],
    )
    this.modalRoot.removeChild(this.el)
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
