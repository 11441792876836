import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { LoginStoreProvider } from '../components/pages/LoginPage/login.store'
import { AppContent } from './AppContent'
import { GlobalStyles } from './GlobalStyles'
import { ResetCss } from './ResetCss'
import { client } from '../api/client'

export const App = () => {
  return (
    <>
      <GlobalStyles />
      <ResetCss />
      <ApolloProvider client={client}>
        <Router>
          <LoginStoreProvider>
            <AppContent />
          </LoginStoreProvider>
        </Router>
      </ApolloProvider>
    </>
  )
}
