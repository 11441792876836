import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const CasesPeriodSectionTitle = styled.div`
  margin-bottom: 20px;
  margin-left: 40px;

  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #9395aa;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 15px;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin-left: 0;
    text-align: center;
  }
`
export const CasesPeriodSectionRoot = styled.div`
  margin-bottom: 50px;
`
