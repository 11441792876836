import styled from 'styled-components'
import { hProgressBgMap } from '../../molecules/HProgress/bgMap'
import { Color } from '../../../types/color'
import { breakpoints } from '../../../breakpoints'

export type DotBigRootProps = {
  color: Color
}

export const DotBigRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid ${(p: DotBigRootProps) => hProgressBgMap[p.color]};

  @media (max-width: ${breakpoints.sm}px) {
    width: 23px;
    height: 23px;
  }
`
