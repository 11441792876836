import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const CaseHeader = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  font-weight: bold;
  font-size: 18px;
  line-height: 18px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 15px;
  }
`

export const CaseBottom = styled.div`
  padding-top: 5px;
`

export const CaseSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CaseTitle = styled.span`
  color: #2f3778;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 100%;
`

export const ActiveCaseName = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    padding-top: 0px;
    font-size: 10px;
    line-height: 10px;
  }
`

export const CaseStatusWrap = styled.div`
  min-width: 0;
  margin-right: 3px;
  flex-shrink: 1;
`

export const CaseBottomFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    margin-top: 15px;
  }
`
