import React from 'react'
import {
  PointTextTmplPointWrap,
  PointTextTmplRoot,
  PointTextTmplTextWrap,
} from './PointTextTmpl.styled'

export type PointTextTmplProps = {
  point: React.ReactNode
  text: React.ReactNode
}

export const PointTextTmpl = ({ point, text }: PointTextTmplProps) => {
  return (
    <PointTextTmplRoot>
      <PointTextTmplPointWrap>{point}</PointTextTmplPointWrap>{' '}
      <PointTextTmplTextWrap>{text}</PointTextTmplTextWrap>
    </PointTextTmplRoot>
  )
}
