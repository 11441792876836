import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const CasesPageTitleWrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 35px;

  padding-top: 22px;
`

export const CasesPageBackBtn = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5px;
  margin-right: 9px;
  cursor: pointer;
`

export const CasesPageRoot = styled.div`
  @media (max-width: ${breakpoints.md}px) {
    padding-top: 20px;
  }
`
