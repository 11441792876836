import React from 'react'
import {
  DetailsSecondaryTmplContent,
  DetailsSecondaryTmplRoot,
  DetailsSecondaryTmplFooter,
} from './DetailsSecondaryTmpl.styled'

export type DetailsSecondaryTmplProps = {
  children: React.ReactNode
  footer?: React.ReactNode
}

export const DetailsSecondaryTmpl = ({
  children,
  footer,
}: DetailsSecondaryTmplProps) => {
  return (
    <DetailsSecondaryTmplRoot>
      <DetailsSecondaryTmplContent>{children}</DetailsSecondaryTmplContent>
      {footer && (
        <DetailsSecondaryTmplFooter>{footer}</DetailsSecondaryTmplFooter>
      )}
    </DetailsSecondaryTmplRoot>
  )
}
