import React, { useState } from 'react'
import { gql } from '@apollo/client'
import { RegNumberScreen } from './screens/RegNumberScreen'
import { SmsScreen } from './screens/SmsScreen'
import { VariantsScreen } from './screens/VariantsScreen'
import { useCallMeMutation, useSendSmsMutation } from '../../../api/generated'
import { AuthyScreen } from './screens/AuthyScreen'
import { Route, Switch, useHistory } from 'react-router'
import { CallScreen } from './screens/CallScreen'

export const SEND_SMS_MUTATION = gql`
  mutation SendSms($userId: String!) {
    sendSms(userId: $userId)
  }
`

export const CALL_ME_MUTATION = gql`
  mutation CallMe($userId: String!) {
    callMe(userId: $userId)
  }
`

export type OtpPageProps = {
  userId: string
}

export const OtpPage = ({ userId }: OtpPageProps) => {
  const history = useHistory()
  const [sendSms] = useSendSmsMutation()
  const [callMe] = useCallMeMutation()
  const [lastSent, setLastSent] = useState<Date | null>(null)
  const [lastCall, setLastCall] = useState<Date | null>(null)

  return (
    <Switch>
      <Route path='/otp/reg-phone'>
        <RegNumberScreen
          onContinue={() => {
            setLastSent(new Date())
            history.replace('/otp/sms')
          }}
        />
      </Route>
      <Route path='/otp/choose'>
        <VariantsScreen
          onClickSms={() => {
            sendSms({ variables: { userId } })
            setLastSent(new Date())
            history.replace('/otp/sms')
          }}
          onClickCall={() => {
            callMe({ variables: { userId } })
            setLastCall(new Date())
            history.replace('/otp/call')
          }}
          onClickAuthy={() => history.replace('/otp/authy')}
        />
      </Route>
      <Route path='/otp/sms'>
        <SmsScreen
          userId={userId}
          onCancel={() => history.replace('/otp/choose')}
          lastSent={lastSent}
          onCompleted={() => {
            history.push('/')
          }}
          onResend={() => {
            sendSms({ variables: { userId } })
            setLastSent(new Date())
          }}
        />
      </Route>
      <Route path='/otp/call'>
        <CallScreen
          userId={userId}
          onCancel={() => history.replace('/otp/choose')}
          lastCall={lastCall}
          onCompleted={() => {
            history.push('/')
          }}
          onResend={() => {
            callMe({ variables: { userId } })
            setLastCall(new Date())
          }}
        />
      </Route>
      <Route path='/otp/authy'>
        <AuthyScreen
          userId={userId}
          onCancel={() => history.replace('/otp/choose')}
          onCompleted={() => {
            history.push('/')
          }}
        />
      </Route>
    </Switch>
  )
}
