import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { DOC_DETAILS_TYPE } from '../../pages/DocumentDetails/useDocumentDetailsType'
import { DocumentType } from '../../pages/DocumentDetails/types'
import {
  DetailsCaseScreenType,
  DetailsDocsScreenType,
  DetailsGCardCaseScreenType,
  DetailsGCardScreenType,
  DetailsScreenParam,
} from './useDetailsScreenType'
import { CaseDetailsCaseIdParam } from '../../pages/CaseDetails/useCaseDetailsParams'
import { useQueryURL } from '../../../hooks/useQueryURL'
import { GCardDetailsCategoryParam } from '../../pages/GCardDetails/useGCardDetailsParams'

export type DetailsRouter = {
  goToCaseDetails: (params: GoToCaseDetailsParams) => void
  goToGreenCardCaseDetails: (params: GoToGCardCaseDetailsParams) => void
  goToGreenCardDetails: (params: GoToGCardDetailsParams) => void
  goToDocumentDetails: (params: GoToDocumentDetailsParams) => void
  closeDetails: () => void
}

export type GoToCaseDetailsParams = { caseId: string }
export type GoToGCardCaseDetailsParams = { caseId: string }
export type GoToDocumentDetailsParams = { documentType: DocumentType }
export type GoToGCardDetailsParams = { category: string }

export function useDetailsRouter(): DetailsRouter {
  const history = useHistory()
  const query = useQueryURL()

  const goToCaseDetails = useCallback(
    ({ caseId }: GoToCaseDetailsParams) => {
      const params = new URLSearchParams(query)
      params.set(DetailsScreenParam, DetailsCaseScreenType)
      params.set(CaseDetailsCaseIdParam, caseId)

      history.replace({
        search: params.toString(),
      })
    },
    [history, query],
  )

  const goToGreenCardDetails = useCallback(
    ({ category }: GoToGCardDetailsParams) => {
      const params = new URLSearchParams(query)
      params.set(DetailsScreenParam, DetailsGCardScreenType)
      params.set(GCardDetailsCategoryParam, category)

      history.replace({
        search: params.toString(),
      })
    },
    [history, query],
  )

  const goToGreenCardCaseDetails = useCallback(
    ({ caseId }: GoToGCardCaseDetailsParams) => {
      const params = new URLSearchParams(query)
      params.set(DetailsScreenParam, DetailsGCardCaseScreenType)
      params.set(CaseDetailsCaseIdParam, caseId)

      history.replace({
        search: params.toString(),
      })
    },
    [history, query],
  )

  const goToDocumentDetails = useCallback(
    ({ documentType }: GoToDocumentDetailsParams) => {
      const params = new URLSearchParams(query)
      params.set(DetailsScreenParam, DetailsDocsScreenType)
      params.set(DOC_DETAILS_TYPE, documentType)

      history.replace({
        search: params.toString(),
      })
    },
    [history, query],
  )

  const closeDetails = useCallback(() => {
    const params = new URLSearchParams(query)

    // Delete all params associated with details panel
    params.delete(DetailsScreenParam)
    params.delete(DOC_DETAILS_TYPE)
    params.delete(CaseDetailsCaseIdParam)
    params.delete(GCardDetailsCategoryParam)

    history.replace({
      search: params.toString(),
    })
  }, [history, query])

  return React.useMemo(
    () => ({
      goToCaseDetails,
      goToDocumentDetails,
      goToGreenCardDetails,
      goToGreenCardCaseDetails,
      closeDetails,
    }),
    [
      closeDetails,
      goToCaseDetails,
      goToDocumentDetails,
      goToGreenCardCaseDetails,
      goToGreenCardDetails,
    ],
  )
}
