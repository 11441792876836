import styled from 'styled-components'
import { breakpoints } from '../../../../../breakpoints'

export const VariantsList = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 20px;
  margin-right: 18.5px;
  margin-left: -7.5px;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    padding-bottom: 30px;
  }
`

export const VariantWrap = styled.div`
  padding-left: 7.5px;
  padding-right: 7.5px;
  flex-basis: 33%;

  @media (max-width: ${breakpoints.sm}px) {
    flex-basis: auto;
    padding: 0;
  }
`
