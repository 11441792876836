import React from 'react'
import { Color } from '../../../types/color'
import { Dot } from '../Dot'
import { DotBigRoot } from './DotBig.styled'

export type DotBigProps = {
  color: Color
}

export const DotBig = ({ color }: DotBigProps) => {
  return (
    <DotBigRoot color={color}>
      <Dot color={color} />
    </DotBigRoot>
  )
}
