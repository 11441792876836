import axios from 'axios'

const noErrorStatus = [401, 403, 404]

export const api = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 15000,
  validateStatus: status => {
    return (status >= 200 && status < 300) || noErrorStatus.includes(status)
  },
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

api.interceptors.response.use(async res => {
  if (
    res.status !== 401 ||
    res.config.url?.endsWith('/employee/login') ||
    res.config.url?.endsWith('/employee/login/token') ||
    res.config.url?.endsWith('/update-password')
  ) {
    return res
  }

  function resetAuth() {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessToken')
    window.location.href = '/login' // TODO use store in this case
  }

  const originalReq = res.config
  if ((originalReq as any)._retry) {
    resetAuth()
    return res
  }

  const refreshToken = localStorage.getItem('refreshToken')
  if (!refreshToken) {
    resetAuth()
    return res
  }

  const tokenRes = await api.post('/employee/login/token', {
    token: refreshToken,
  })
  localStorage.setItem('accessToken', tokenRes.data.accessToken)

  if (tokenRes.status !== 200) {
    resetAuth()
    return res
  }

  return api({
    ...originalReq,
    _retry: true,
    headers: {
      ...res.config.headers,
      Authorization: `Bearer ${tokenRes.data.accessToken}`,
    },
  } as any)
})

export async function changeAvatar(
  userId: string,
  { image, height, width }: { image: string; width: number; height: number },
) {
  const imgRes = await fetch(image)
  const blob = await imgRes.blob()

  const formData = new FormData()
  formData.append('file', blob)
  formData.append('image_height', height.toString())
  formData.append('image_width', width.toString())

  // console.log(image)

  const res = await api.post(`/employee/user/${userId}/change-avatar`, formData)
  if (!res.data.file) {
    throw res
  }
}
