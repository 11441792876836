import React from 'react'
import {
  Case,
  CasesComponent,
  GreenCardsComponent,
} from '../../../api/generated'
import { CaseDetails } from './CaseDetails'

export type CaseDetailsWrapProps = {
  userId: string
  forGCard?: boolean
}

export const CaseDetailsWrap = ({ userId, forGCard }: CaseDetailsWrapProps) => {
  return (
    <>
      {forGCard ? (
        <GreenCardsComponent
          skip={false}
          variables={{ userId }}
          fetchPolicy='cache-and-network'
        >
          {({ data }) => {
            if (data) {
              const cases = data.greencards.reduce(
                (acc, item) => [...acc, ...item.cases],
                [] as Omit<Case, 'files'>[],
              )

              return <CaseDetails forGCard cases={cases as any} />
            }

            return null
          }}
        </GreenCardsComponent>
      ) : (
        <CasesComponent
          skip={false}
          fetchPolicy='cache-and-network'
          variables={{ userId }}
        >
          {({ data }) => {
            if (data) {
              return <CaseDetails cases={data.cases as any[]} />
            }

            return null
          }}
        </CasesComponent>
      )}
    </>
  )
}
