import React from 'react'

export const AuthyIcon = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48 23.9996C48 37.2555 37.2551 48 23.9994 48C10.7445 48 0 37.2555 0 23.9996C0 10.7447 10.7445 0 23.9994 0C37.2551 0 48 10.7447 48 23.9996Z'
        fill='#6494FF'
      />
      <path
        d='M21.942 18.9166L27.6966 24.6691C28.5272 25.5009 29.8759 25.5009 30.7086 24.6691C31.5394 23.8376 31.5413 22.49 30.7086 21.6582L24.9536 15.9035C20.8359 11.7848 14.1808 11.7185 9.97575 15.6942C9.93649 15.7268 9.89864 15.7611 9.86231 15.797C9.84394 15.8153 9.82894 15.8348 9.81075 15.8513C9.792 15.8693 9.77288 15.8855 9.75506 15.9035C9.71869 15.9397 9.6855 15.9777 9.65381 16.0154C5.676 20.2224 5.74444 26.8777 9.8625 30.9961L15.6172 36.7498C16.4497 37.581 17.7966 37.581 18.6289 36.7498C19.4617 35.9179 19.4617 34.5686 18.6309 33.7385L12.876 27.9839C10.3639 25.4728 10.347 21.3972 12.8239 18.8645C15.3562 16.3876 19.4295 16.4056 21.942 18.9168V18.9166ZM29.3692 11.2529C28.5377 12.0848 28.5377 13.4322 29.3715 14.264L35.1242 20.0189C37.6348 22.5299 37.6511 26.6049 35.1744 29.1382C32.6413 31.6132 28.5694 31.5967 26.0576 29.0853L20.3029 23.3311C19.4707 22.4992 18.1217 22.4992 17.2905 23.3311C16.4574 24.1623 16.4574 25.514 17.2905 26.3433L23.0434 32.0985C27.1624 36.2169 33.8168 36.2839 38.0226 32.3077C38.0616 32.2732 38.0983 32.2411 38.1369 32.2048C38.1546 32.187 38.1711 32.1688 38.1881 32.1504C38.2069 32.1326 38.2251 32.1161 38.2425 32.0962C38.2794 32.0619 38.3109 32.0242 38.3438 31.9848C42.3216 27.7796 42.255 21.1265 38.1371 17.0056L32.3824 11.2527C31.5506 10.4196 30.2003 10.4196 29.3692 11.2529Z'
        fill='white'
      />
    </svg>
  )
}
