import React from 'react'

export function useHover() {
  const [hover, setHover] = React.useState(false)

  const onMouseEnter = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setHover(true)
    },
    [],
  )

  const onMouseLeave = React.useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setHover(false)
    },
    [],
  )

  return React.useMemo(
    () => ({
      hover,
      onMouseEnter,
      onMouseLeave,
    }),
    [hover, onMouseEnter, onMouseLeave],
  )
}
