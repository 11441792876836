import { useCallback, useEffect, useMemo, useState } from 'react'
import { gql } from '@apollo/client'
import { useLoginStore } from '../LoginPage/login.store'
import { useVerifyOtpMutation } from '../../../api/generated'

export const VERIFY_OTP_MUTATION = gql`
  mutation VerifyOtp($userId: String!, $otp: String!) {
    verifyOtp(userId: $userId, otp: $otp) {
      ... on VerifyOtpSuccessResponse {
        accessToken
        refreshToken
        success
      }

      ... on VerifyOtpWrongCodeResponse {
        success
      }
    }
  }
`

export function useVerifyLogic({
  userId,
  onCompleted,
}: {
  userId: string
  onCompleted: () => void
}) {
  const [value, setValue] = useState('')
  const { verify } = useLoginStore()
  const [verifyOtp, { loading }] = useVerifyOtpMutation()
  const [wrongErr, setWrongErr] = useState(false)

  const submitHandler = useCallback(async () => {
    const res = await verifyOtp({ variables: { otp: value, userId } })
    const rData = res.data?.verifyOtp

    if (rData?.__typename === 'VerifyOtpSuccessResponse') {
      verify(rData)
      onCompleted()
    } else if (rData?.__typename === 'VerifyOtpWrongCodeResponse') {
      setWrongErr(true)
      setValue('')
    }
  }, [onCompleted, userId, value, verify, verifyOtp])

  useEffect(() => {
    if (value.length === 7) {
      submitHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (value.length > 0 && value.length < 7) {
      setWrongErr(false)
    }
  }, [value])

  return useMemo(
    () => ({
      wrongErr,
      loading,
      value,
      setValue,
      submitHandler,
    }),
    [loading, submitHandler, value, wrongErr],
  )
}
