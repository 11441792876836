import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { breakpoints } from '../../../breakpoints'

export const OtpWrap = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(94, 137, 238);
  background: linear-gradient(
    135deg,
    rgba(94, 137, 238, 1) 0%,
    rgba(46, 54, 119, 1) 100%
  );

  @media (max-width: ${breakpoints.sm}px) {
    padding: 0;
    background: #fff;
  }
`

export const OtpPaper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 100%;
  min-height: 564px;
  background: #ffffff;
  box-shadow: 10px 10px 100px rgba(47, 55, 120, 0.25);

  @media (max-width: ${breakpoints.sm}px) {
    box-shadow: none;
  }
`

export const OtpDescription = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 175%;
  color: #000000;
  opacity: 0.5;
`

export const OtpContentWrap = styled.div`
  padding-left: 50px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 30px;
  }
`

export const OtpContentInner = styled.div`
  padding-right: 60px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-right: 20px;
  }
`

export const OtpHeader = styled.div`
  padding: 25px 20px;
  margin-bottom: 20px;
`

export const OtpLabel = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  color: #282b32;
`

export const OtpLabelError = styled(OtpLabel)`
  color: #f45b39;
`

export const OtpTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    /* font-size: 24px; */
  }
`

export const OtpInputsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
`

export const OtpFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 25px;
  border-top: 1px solid #dadada;
`

export const OtpContentInnerWrap = styled.div`
  flex-grow: 1;
`

export const OtpBtnWrap = styled.div`
  margin-right: 15px;
`

export const OtpButton = styled(Button)`
  font-weight: bold;

  @media (max-width: ${breakpoints.sm}px) {
    min-width: 116px;
    padding-left: 10px;
    padding-right: 10px;
  }
`
