import jwt_decode from 'jwt-decode'
import { api } from './api.instance'
import { dateScalar } from './dateScalar'
import { Resolvers } from './generated'

export const resolvers: Resolvers<void> = {
  DateTime: dateScalar,
  Mutation: {
    async login(_, { login, password }) {
      const res = await api.post('/employee/login', {
        login,
        password,
      })

      if (res.status === 401) {
        return { __typename: 'LoginWrongCredentials', ...res.data }
      }

      return {
        __typename: 'LoginSuccessResponse',
        ...res.data,
      }
    },
    async registerOtp(_, { countryCode, mobilenum, userId }) {
      const res = await api.post(`/employee/user/${userId}/register-otp`, {
        country_code: countryCode,
        mobile_number: mobilenum,
      })

      if (res.data.success) {
        return true
      }

      return false
    },
    async callMe(_, { userId }) {
      const res = await api.get(`/employee/login/${userId}/call-me`)

      if (res.data.success) {
        return true
      }

      return false
    },
    async sendSms(_, { userId }) {
      const res = await api.get(`/employee/login/${userId}/send-sms`)

      if (res.data.success) {
        return true
      }

      return false
    },
    async verifyOtp(_, { otp, userId }) {
      const res = await api.post(`/employee/login/${userId}/verify-otp`, {
        otp,
      })

      if (res.data.success) {
        return {
          ...res.data,
          __typename: 'VerifyOtpSuccessResponse',
          success: true,
        }
      } else if (res.status === 403) {
        return {
          __typename: 'VerifyOtpWrongCodeResponse',
          success: false,
        }
      }

      return null
    },
    async changePassword(_, { newPassword, userId }) {
      const res = await api.post(`/employee/user/${userId}/change-password`, {
        newPassword,
      })

      if (res.data.success) {
        return true
      }

      return false
    },
    async updatePassword(_, { newPassword, password, userId }) {
      const res = await api.post(`/employee/user/${userId}/update-password`, {
        newPassword,
        password,
      })

      if (res.data.success) {
        return true
      }

      return false
    },
  },

  Query: {
    async authCheck() {
      const token = localStorage.getItem('accessToken')

      async function refreshTokenFunc(): Promise<string | null> {
        const refreshToken = localStorage.getItem('refreshToken')
        if (!refreshToken) {
          return null
        }

        const res = await api.post('/employee/login/token', {
          token: refreshToken,
        })
        const { accessToken } = res.data
        localStorage.setItem('accessToken', accessToken)
        return accessToken
      }

      function isValidToken(
        t: string,
      ): { valid: boolean; userId: string | null } {
        try {
          const { userId, exp } = jwt_decode<{
            name: string
            userId: string
            iat: number
            exp: number
          }>(t)

          const expiresDate = new Date(exp * 1000)
          const currDate = new Date()

          return { userId, valid: expiresDate > currDate }
        } catch (error) {
          return { userId: null, valid: false }
        }
      }

      if (!token) {
        const newToken = await refreshTokenFunc()
        if (!newToken) {
          return { __typename: 'AuthCheckUnauthorizedResponse', success: false }
        }

        const { valid, userId } = isValidToken(newToken)
        if (valid) {
          return {
            __typename: 'AuthCheckSuccessResponse',
            employeeId: userId!,
          }
        }
      } else {
        const { valid, userId } = isValidToken(token)
        if (valid) {
          return {
            __typename: 'AuthCheckSuccessResponse',
            employeeId: userId!,
          }
        }
      }

      return { __typename: 'AuthCheckUnauthorizedResponse', success: false }
    },

    async user(_, { id }) {
      const res = await api.get(`/employee/user/${id}`)

      const { data } = res

      if (!data.user_id) {
        return null
      }

      if (data.role === 'principal') {
        return {
          __typename: 'Employee',
          ...data,
        }
      }

      return {
        __typename: 'Dependent',
        ...data,
      }
    },

    async cases(_, { userId, activeFilter }) {
      let url = `/employee/user/${userId}/cases`
      if (activeFilter) {
        url += `?status=${activeFilter}`
      }

      const res = await api.get(url)
      return res.data.cases
    },

    async greencards(_, { userId }) {
      const res = await api.get(`/employee/user/${userId}/greencards`)
      return res.data.greencards
    },
  },
}
