import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { IconButton } from '../../atoms/IconButton'
import { GearIcon } from '../../icons/GearIcon'

export type SettingsButtonProps = {
  onClickChangePassword: () => void
}

export const SettingsButton = ({
  onClickChangePassword,
}: SettingsButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <GearIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            onClickChangePassword()
          }}
        >
          Change Password
        </MenuItem>
      </Menu>
    </>
  )
}
