import React from 'react'

export const InfoBig = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.4375 28H6.5625C2.94394 28 0 25.0561 0 21.4375V6.5625C0 2.94394 2.94394 0 6.5625 0H21.4375C25.0561 0 28 2.94394 28 6.5625V21.4375C28 25.0561 25.0561 28 21.4375 28ZM6.5625 2.1875C4.15013 2.1875 2.1875 4.15013 2.1875 6.5625V21.4375C2.1875 23.8499 4.15013 25.8125 6.5625 25.8125H21.4375C23.8499 25.8125 25.8125 23.8499 25.8125 21.4375V6.5625C25.8125 4.15013 23.8499 2.1875 21.4375 2.1875H6.5625Z'
        fill='#2F3778'
      />
      <path
        d='M14 21.4375C13.3959 21.4375 12.9062 20.9478 12.9062 20.3438V11.9766C12.9062 11.3725 13.3959 10.8828 14 10.8828C14.6041 10.8828 15.0938 11.3725 15.0938 11.9766V20.3438C15.0938 20.9478 14.6041 21.4375 14 21.4375ZM14 6.5625C13.2449 6.5625 12.6328 7.17462 12.6328 7.92969C12.6328 8.68476 13.2449 9.29688 14 9.29688C14.7551 9.29688 15.3672 8.68476 15.3672 7.92969C15.3672 7.17462 14.7551 6.5625 14 6.5625Z'
        fill='#2F3778'
      />
    </svg>
  )
}
