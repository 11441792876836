import styled from 'styled-components'

export const IconButtonRoot = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  transition: opacity 0.2s ease;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
`
