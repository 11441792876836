import { useEffect, useRef } from 'react'

export function useClickOutside<
  R extends HTMLElement,
  T extends (e: MouseEvent) => void = (e: MouseEvent) => void
>(handler: T) {
  const ref = useRef<R>(null)
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handler])
  return ref
}
