import React from 'react'
import {
  DocChipRoot,
  DotChipEndIconWrap,
  DotChipLabelWrap,
} from './DocChip.styled'

export type DocChipProps = {
  label: string
  endIcon?: React.ReactNode
}

export const DocChip = ({ label, endIcon }: DocChipProps) => {
  return (
    <DocChipRoot>
      <DotChipLabelWrap>{label}</DotChipLabelWrap>
      {endIcon && <DotChipEndIconWrap>{endIcon}</DotChipEndIconWrap>}
    </DocChipRoot>
  )
}
