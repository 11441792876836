import styled, { css } from 'styled-components'
import { Color } from '../../../types/color'
import { hProgressBgMap } from './bgMap'

const InactiveBG = '#dbe3f4'

export const HProgressRoot = styled.div`
  position: relative;
  height: 5px;
  background: ${InactiveBG};
  border-radius: 20px;
`

export type HProgressActiveProps = {
  percents: number
  color: Color
}

export const HProgressActive = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${(p: HProgressActiveProps) => `${p.percents}%`};
  height: 100%;
  background: ${(p: HProgressActiveProps) => hProgressBgMap[p.color]};
  border-radius: 20px;
`

export type HProgressPointWrapProps = {
  percents: number
  visible?: boolean
}

export const HProgressPointWrap = styled.div`
  position: absolute;
  top: 50%;
  ${(p: HProgressPointWrapProps) => getPointStyles(p.percents)};
  opacity: ${(p: HProgressPointWrapProps) => `${p.visible ? '1' : '0'}`};
  transition: opacity 0.2s ease;
`

export const HProgressPointsWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
`

export const HProgressPointTooltipMainDesc = styled.div`
  margin-bottom: 6px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #282b32;
  opacity: 0.75;
`

export const HProgressPointTooltipRoot = styled.div`
  width: 265px;
`

export const HProgressPointTooltipTitle = styled.div`
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #282b32;
`
export const HProgressPointTooltipDesc = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #585b78;
  opacity: 0.75;
`

function getPointStyles(percents: number) {
  return css`
    left: ${percents}%;
    transform: translateY(-50%) translateX(-${percents}%);
  `
}
