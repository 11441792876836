import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const SectionTmplRoot = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

export const SectionTmplTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 15px;
    margin-left: 15px;
  }
`

export const SectionTmplTitleSecondaryWrap = styled.div`
  font-size: 15px;
  line-height: 1;
  font-weight: bold;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 13px;
  }
`

export const SectionTmplDivider = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid rgba(88, 91, 120, 0.11);
`

export const SectionTmplPlaceholderText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  color: #585b78;
  margin-right: 7px;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 13px;
    margin-left: 15px;
  }
`
