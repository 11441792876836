import React from 'react'

export const ArrowIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.41436 13L12.7073 19.2929L11.293 20.7071L2.58594 12L11.293 3.29291L12.7073 4.70712L6.41436 11H21.0002V13H6.41436Z'
        fill='#4D7ADE'
      />
    </svg>
  )
}
