import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const ContentTitle = styled.h3`
  font-size: 28px;
  line-height: 28px;
  font-weight: bold;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 15px;
    line-height: 15px;
  }
`
