import React from 'react'

import { useDetailsRouter } from '../../organisms/DetailsPanel/useDetailsRouter'
import { CaseStatus, useGreenCardsQuery } from '../../../api/generated'
import { InactiveCasesScreen } from './InactiveCasesScreen'

export type GCardInactiveCasesPageProps = {
  userId: string
}

export const GCardInactiveCasesPage = ({
  userId,
}: GCardInactiveCasesPageProps) => {
  const detailsRouter = useDetailsRouter()
  const { data, loading } = useGreenCardsQuery({ variables: { userId } })

  if (!data?.greencards) {
    return null
  }

  const casesUniqueIds = new Set<string>()
  const cases = data.greencards.reduce((acc, g) => {
    g.cases
      .filter(c => c.status !== CaseStatus.InProgress)
      .forEach(c => {
        if (!casesUniqueIds.has(c.id)) {
          casesUniqueIds.add(c.id)
          acc.push(c)
        }
      })

    return acc
  }, [] as any[])

  return (
    <InactiveCasesScreen
      cases={cases}
      loading={loading}
      onClickCaseDetails={({ caseId }) =>
        detailsRouter.goToGreenCardCaseDetails({ caseId })
      }
    />
  )
}
