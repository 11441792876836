import React from 'react'
import { IconButtonRoot } from './IconButton.styled'

export type IconButtonProps = {
  children: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const IconButton = ({ children, ...other }: IconButtonProps) => {
  return (
    <IconButtonRoot type='button' {...other}>
      {children}
    </IconButtonRoot>
  )
}
