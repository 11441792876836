import styled from 'styled-components'

export const TextFieldRoot = styled.div`
  width: 100%;
`

export type TextFieldInputProps = {
  error: boolean
}

export const TextFieldInput = styled.input.withConfig({
  shouldForwardProp: prop => true,
})`
  width: 100%;
  padding: 11px 13px;

  background-color: #f5f8fd;
  border-radius: 5px;

  /* -webkit-box-shadow: inset 0 0 0 50px #f5f8fd;
  -webkit-text-fill-color: #000; */

  font-weight: normal;
  font-size: 15px;
  line-height: 1;
  color: #000000;
  border: 1px solid
    ${(p: TextFieldInputProps) => (p.error ? '#F45B39' : '#f5f8fd')};
  outline: none;

  &::placeholder {
    color: #acaeb1;
  }

  &:focus {
    border-color: #ccc;
  }
`

export const TextFieldLabel = styled.label`
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  color: #282b32;
`

export const TextFieldSuggestion = styled.span`
  display: block;
  margin-top: 12px;
  margin-left: 13px;
  font-weight: normal;
  font-size: 10px;
  line-height: 1;
`

export const TextFieldErrorText = styled(TextFieldSuggestion)`
  color: #f44336;
`
