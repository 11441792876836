import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const DependentTop = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DependentUser = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`

export const DependentAvatar = styled.div`
  width: 56px;
  height: 56px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
`

export const DependentTextWrap = styled.div`
  flex: 1;
  min-width: 0; /* or some value */
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const DependentName = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3778;
  width: 100%;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 15px;
    line-height: 15px;
  }
`

export const DependentRole = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #585b78;
  opacity: 0.75;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 10px;
    line-height: 12px;
  }
`

export const DependentDocWrap = styled.div`
  flex-basis: 50%;
  padding: 5px;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 3px;
  }
`

export const DependentDocList = styled.div`
  display: flex;
  margin: -5px;

  @media (max-width: ${breakpoints.sm}px) {
    margin: -3px;
  }
`
