import React from 'react'

export const Info = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.375 24H5.625C2.52338 24 0 21.4766 0 18.375V5.625C0 2.52338 2.52338 0 5.625 0H18.375C21.4766 0 24 2.52338 24 5.625V18.375C24 21.4766 21.4766 24 18.375 24ZM5.625 1.875C3.55725 1.875 1.875 3.55725 1.875 5.625V18.375C1.875 20.4427 3.55725 22.125 5.625 22.125H18.375C20.4427 22.125 22.125 20.4427 22.125 18.375V5.625C22.125 3.55725 20.4427 1.875 18.375 1.875H5.625Z'
        fill='#2F3778'
      />
      <path
        d='M12 18.375C11.4822 18.375 11.0625 17.9553 11.0625 17.4375V10.2656C11.0625 9.74784 11.4822 9.32812 12 9.32812C12.5178 9.32812 12.9375 9.74784 12.9375 10.2656V17.4375C12.9375 17.9553 12.5178 18.375 12 18.375ZM12 5.625C11.3528 5.625 10.8281 6.14967 10.8281 6.79688C10.8281 7.44408 11.3528 7.96875 12 7.96875C12.6472 7.96875 13.1719 7.44408 13.1719 6.79688C13.1719 6.14967 12.6472 5.625 12 5.625Z'
        fill='#2F3778'
      />
    </svg>
  )
}
