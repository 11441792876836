import styled from 'styled-components'

export const ProfileHeaderMobileRoot = styled.div`
  position: relative;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`

export const ProfileHeaderMobileImgWrap = styled.div`
  width: 40px;
`

export const ProfileHeaderMobileImg = styled.img`
  width: 100%;
`

export const ProfileHeaderMobileSideButtons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 0 5px;
`
