import React from 'react'
import { useQueryURL } from '../../../hooks/useQueryURL'

export type CaseDetailsParams = {
  caseId: string | null
}

export function useCaseDetailsParams(): CaseDetailsParams {
  const query = useQueryURL()
  return React.useMemo(
    () => ({
      caseId: query.get(CaseDetailsCaseIdParam),
    }),
    [query],
  )
}

export const CaseDetailsCaseIdParam = 'case_id'
