import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const CardListRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    margin: -7px;
  }
`

export const CardWrap = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  flex-grow: 0;
  padding: 10px;

  @media (max-width: ${breakpoints.sm}px) {
    max-width: 100%;
    padding: 7px;
  }
`

export const CardSlideWrap = styled.div`
  padding-bottom: 22px;
`
