import React from 'react'
import { Color } from '../../../types/color'
import { Check } from '../../icons/Check'
import { DotCompletedRoot } from './DotCompleted.styled'

export type DotCompletedProps = {
  color: Color
}

export const DotCompleted = ({ color }: DotCompletedProps) => {
  return (
    <DotCompletedRoot color={color}>
      <Check />
    </DotCompletedRoot>
  )
}
