import React from 'react'
import {
  DetailsPointTextTmplPointWrap,
  DetailsPointTextTmplRoot,
  DetailsPointTextTmplTextWrap,
} from './DetailsPointTextTmpl.styled'

export type DetailsPointTextTmplProps = {
  point: React.ReactNode
  text: React.ReactNode
}

export const DetailsPointTextTmpl = ({
  point,
  text,
}: DetailsPointTextTmplProps) => {
  return (
    <DetailsPointTextTmplRoot>
      <DetailsPointTextTmplPointWrap>{point}</DetailsPointTextTmplPointWrap>
      <DetailsPointTextTmplTextWrap>{text}</DetailsPointTextTmplTextWrap>
    </DetailsPointTextTmplRoot>
  )
}
