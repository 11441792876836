import styled from 'styled-components'
import { breakpoints } from '../../../../../../breakpoints'

export const VariantRoot = styled.div`
  height: 100%;
  padding: 50px 6px 26px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #dadada;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: row;
    height: auto;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dadada;
  }
`

export const VariantTextBlock = styled.div``

export const VariantTitle = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  color: #000000;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: left;
    margin-bottom: 3px;
  }
`

export const VariantDescription = styled.div`
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #282b32;
`

export const VariantIconWrap = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 0;
    margin-right: 17px;
  }
`
