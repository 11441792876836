import React from 'react'

export const CallSmall = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9744 6.25431C11.5604 6.90384 11.3746 7.63969 10.8565 8.3379C10.7337 8.50344 10.5921 8.66654 10.4211 8.84627C10.339 8.93254 10.275 8.99742 10.1411 9.13142L9.37435 9.89862C9.2739 9.99906 9.95972 11.3695 11.2907 12.7018C12.621 14.0334 13.9914 14.7197 14.0923 14.6187L14.8585 13.8521C15.2805 13.4297 15.504 13.2259 15.811 13.0244C16.4494 12.6056 17.1457 12.4918 17.7329 13.0169C19.6503 14.3885 20.7354 15.2301 21.2669 15.7824C22.3037 16.8597 22.1678 18.518 21.2728 19.464C20.9624 19.792 20.5689 20.1858 20.1041 20.6338C17.2926 23.4469 11.3589 21.735 6.81139 17.183C2.26285 12.63 0.551816 6.69577 3.35747 3.88861C3.86116 3.377 4.02723 3.21101 4.51779 2.72765C5.43112 1.82771 7.16589 1.6868 8.22044 2.72854C8.77515 3.27649 9.65949 4.41433 10.9744 6.25431ZM16.2721 15.2661L15.5057 16.0329C14.203 17.3364 11.9845 16.2253 9.87697 14.1157C7.76802 12.0047 6.65821 9.787 7.96136 8.48396L8.72738 7.71751C8.84996 7.59481 8.90603 7.53798 8.97333 7.46724C9.06503 7.37087 9.14038 7.28623 9.20071 7.21133C8.03534 5.58799 7.24314 4.57364 6.81608 4.15176C6.59552 3.93389 6.10166 3.97401 5.92035 4.15265C5.4368 4.62912 5.27786 4.78798 4.7762 5.29748C2.97188 7.10279 4.35315 11.8933 8.22513 15.7691C12.0959 19.6437 16.8857 21.0256 18.7037 19.2068C19.1613 18.7655 19.5342 18.3924 19.8212 18.089C20.0286 17.8699 20.0656 17.4176 19.8271 17.1697C19.4297 16.7568 18.457 15.9984 16.7773 14.7922C16.6549 14.8907 16.5044 15.0337 16.2721 15.2661ZM19.9999 10.9999V8.99993H16.4142L21.707 3.70703L20.2928 2.29282L14.9999 7.58571V3.99993H12.9999V10.9999H19.9999Z'
        fill='#6494FF'
      />
    </svg>
  )
}
