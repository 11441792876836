import React from 'react'

export const Sms = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 6H42C44.2091 6 46 7.79086 46 10V38C46 40.2091 44.2091 42 42 42H6C3.79086 42 2 40.2091 2 38V10C2 7.79086 3.79086 6 6 6ZM6 19.2362V38H42V19.2371L24 28.2371L6 19.2362ZM6 14.764L24 23.7649L42 14.7649V10H6V14.764Z'
        fill='#6494FF'
      />
    </svg>
  )
}
