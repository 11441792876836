import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export type FieldValueProps = {
  bold?: boolean
}

export const FieldValue = styled.div`
  font-weight: ${(p: FieldValueProps) => (p.bold ? 'bold' : '500')};
  font-size: 18px;
  color: #282b32;
  font-family: Gilroy;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 13px;
  }
`
