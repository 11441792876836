import styled from 'styled-components'

export const ProfileHeaderRoot = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f3f5;
`

export const ProfileHeaderLogoWrap = styled.div`
  flex-grow: 1;
`

export const ProfileHeaderBtnWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  position: relative;
`

export const ProfileHeaderLogoImgWrap = styled.div`
  width: 120px;
`
