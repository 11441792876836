import React from 'react'
import { CloseButtonRoot } from './CloseButton.styled'
import Icon from './icon.svg'

export type CloseButtonProps = {
  onClick?: () => void
}

export const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <CloseButtonRoot onClick={onClick}>
      <img src={Icon} />
    </CloseButtonRoot>
  )
}
