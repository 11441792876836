import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    html, body {
        background-color: #EDF1FA;
        font-family: Gilroy, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        -moz-osx-font-smoothing: grayscale; /*(For Firefox)*/
        -webkit-font-smoothing: antialiased; /*(For Chrome and Safari)*/
    }
`
