import React from 'react'

export const Cancel = () => {
  return (
    <svg
      width='9'
      height='10'
      viewBox='0 0 9 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.49999 6.97242L1.71669 9.75572L0 8.03903L2.7833 5.25573L0 2.47243L1.71669 0.755737L4.49999 3.53904L7.2833 0.755737L8.99999 2.47243L6.21668 5.25573L8.99999 8.03903L7.2833 9.75572L4.49999 6.97242Z'
        fill='#585B78'
      />
    </svg>
  )
}
