import styled from 'styled-components'
import { Color } from '../../../types/color'
import { hProgressBgMap } from '../HProgress/bgMap'
import { breakpoints } from '../../../breakpoints'

export const TimeLineRoot = styled.div`
  display: flex;
  flex-direction: column;
`

export type TimeLineRowProps = {
  isLast: boolean
}

export const TimeLineRow = styled.div`
  display: flex;
  min-height: ${(p: TimeLineRowProps) => (p.isLast ? 'auto' : '85px')};
  line-height: 18px;
`

export const TimeLineTime = styled.div`
  width: 100px;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 18px;
  color: #282b32;
  opacity: 0.5;

  @media (max-width: ${breakpoints.sm}px) {
    width: 58px;
    font-size: 10px;
  }
`

export type TimeLineSeparatorProps = {
  size: 'small' | 'middle'
  color: Color
  isLast: boolean
}

export const TimeLineSeparator = styled.div`
  width: 40px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  padding-top: ${(p: TimeLineSeparatorProps) =>
    p.size === 'small' ? '3px' : '0'};

  position: relative;
  &::after {
    background: ${(p: TimeLineSeparatorProps) => hProgressBgMap[p.color]};
    content: '';
    display: ${(p: TimeLineSeparatorProps) => (p.isLast ? 'none' : 'block')};
    height: 100%;
    left: 0px;
    top: 3px;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0px;
    width: 3px;
    z-index: 0;
  }
`

export const TimeLineDescription = styled.div`
  display: flex;
  flex-grow: 1;

  font-weight: bold;
  font-size: 18px;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 13px;
  }
`

export type TimeLineDotWrapProps = {
  important?: boolean
  cancel?: boolean
}

export const TimeLineDotWrap = styled.div`
  z-index: 1;
  transform: translateY(
    ${(p: TimeLineDotWrapProps) =>
      p.important ? '-7px' : p.cancel ? '-3px' : '0'}
  );

  @media (max-width: ${breakpoints.sm}px) {
    transform: translateY(
      ${(p: TimeLineDotWrapProps) =>
        p.important ? '-3px' : p.cancel ? '-1px' : '0'}
    );
  }
`
