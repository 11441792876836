import { Case, CaseMilestone } from '../api/generated'
import { sortMilestones } from './sortMilestones'

type CaseSortFragment = Pick<
  Case,
  'milestones' | 'completionDate' | 'initiationDate'
>

export function sortCasesList<T extends CaseSortFragment>(list: T[]): T[] {
  const copy = list.slice()

  return copy.sort((a, b) => {
    const aEarly = getOldMilestone(getFullMilestones(a))
    const bEarly = getOldMilestone(getFullMilestones(b))

    return (
      (bEarly.completedDate?.getTime() || 0) -
      (aEarly.completedDate?.getTime() || 0)
    )
  })
}

function getOldMilestone(milestones: CaseMilestone[]): CaseMilestone {
  let result = milestones[0]
  milestones.forEach(m => {
    if (
      m.completedDate &&
      result.completedDate &&
      m.completedDate > result.completedDate
    ) {
      result = m
    }
  })

  return result
}

export function getFullMilestones({
  milestones,
  initiationDate,
  completionDate,
}: CaseSortFragment): CaseMilestone[] {
  return [
    {
      __typename: 'CaseMilestone',
      name: 'Initiated',
      completedDate: initiationDate,
    },
    ...sortMilestones(milestones),
    {
      __typename: 'CaseMilestone',
      name: 'Completed',
      completedDate: completionDate,
    },
  ]
}
