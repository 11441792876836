import React from 'react'
import { useHistory } from 'react-router'
import MediaQuery from 'react-responsive'
import { PersonIcon } from '../../icons/PersonIcon'
import {
  ProfileHeaderMobileImg,
  ProfileHeaderMobileImgWrap,
  ProfileHeaderMobileRoot,
  ProfileHeaderMobileSideButtons,
} from './ProfileHeaderMobile.styled'
import logo from './brand-logo-small.png'
import { UserSmall } from '../UserSmall'
import { IconButton } from '../../atoms/IconButton'
import { UserDetails } from '../../organisms/UserDetails'
import { breakpoints } from '../../../breakpoints'
import { useGetUserQuery } from '../../../api/generated'
import { CasesPageBackBtn } from '../../pages/InactiveCasesPage/InactiveCasesPage.styled'
import { ArrowIcon } from '../../icons/ArrowIcon'

export type ProfileHeaderMobileProps = {
  userId: string
  isDependent?: boolean
  onClickChangePassword: () => void
}

export const ProfileHeaderMobile = ({
  userId,
  isDependent,
  onClickChangePassword,
}: ProfileHeaderMobileProps) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false)
  const history = useHistory()

  const { data } = useGetUserQuery({
    variables: { id: userId },
    fetchPolicy: 'cache-only',
  })

  if (!data?.user) {
    return <>Could not load data</>
  }

  const { profile, firstName, lastName } = data.user

  return (
    <MediaQuery maxWidth={breakpoints.md}>
      <ProfileHeaderMobileRoot>
        <ProfileHeaderMobileSideButtons>
          {isDependent ? (
            <CasesPageBackBtn onClick={() => history.push('/')}>
              <ArrowIcon />
            </CasesPageBackBtn>
          ) : (
            <ProfileHeaderMobileImgWrap>
              <ProfileHeaderMobileImg src={logo} />
            </ProfileHeaderMobileImgWrap>
          )}

          <IconButton onClick={() => setDetailsOpen(true)}>
            <PersonIcon />
          </IconButton>
        </ProfileHeaderMobileSideButtons>

        <UserSmall
          avatarUrl={profile.image_url}
          name={[firstName, lastName].join(' ')}
        />
      </ProfileHeaderMobileRoot>

      <UserDetails
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
        user={data.user as any} // TODO
        onClickChangePassword={onClickChangePassword}
        isDependent={isDependent}
      />
    </MediaQuery>
  )
}
