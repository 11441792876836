import styled from 'styled-components'

export const PointTextTmplRoot = styled.span`
  display: flex;
  align-items: center;
  min-width: 0;
`

export const PointTextTmplPointWrap = styled.span`
  display: inline;
  margin-right: 5px;
  line-height: 100%;
`

export const PointTextTmplTextWrap = styled.span`
  line-height: 1;
  padding-top: 0.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  & > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
