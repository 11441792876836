import React from 'react'
import { ArrowRightIcon } from '../../icons/ArrowRightIcon'
import { ArrowButtonRoot } from './ArrowButton.styled'

export type ArrowButtonProps = {
  onClick?: () => void
}

export const ArrowButton = ({ onClick }: ArrowButtonProps) => {
  return (
    <ArrowButtonRoot onClick={onClick}>
      <ArrowRightIcon />
    </ArrowButtonRoot>
  )
}
