import styled from 'styled-components'

export const ArrowButtonRoot = styled.button`
  display: inline-flex;
  padding: 6px 10px;
  border: 1px solid #e5e5ef;
  box-sizing: border-box;
  border-radius: 15px;
  line-height: 1;
  background: transparent;
  cursor: pointer;
`
