import React from 'react'
import { useHistory } from 'react-router'
import { useGetUserQuery } from '../../../api/generated'
import { ArrowIcon } from '../../icons/ArrowIcon'
import {
  HeaderArrowButton,
  HeaderAvatarImg,
  HeaderAvatarWrap,
  HeaderRoot,
  HeaderTitle,
} from './HeaderMobileCases.styled'
import defaultAvatar from '../../atoms/Avatar/default-avatar.svg'

export type HeaderMobileCasesProps = {
  userId: string
}

export const HeaderMobileCases = ({ userId }: HeaderMobileCasesProps) => {
  const history = useHistory()

  const { data, loading } = useGetUserQuery({
    variables: { id: userId },
    fetchPolicy: 'cache-first',
  })

  if (loading) {
    return null
  }

  if (!data?.user) {
    return <>Could not load data</>
  }

  return (
    <HeaderRoot>
      <HeaderArrowButton onClick={() => history.goBack()}>
        <ArrowIcon />
      </HeaderArrowButton>
      <HeaderTitle>Inactive cases</HeaderTitle>
      <HeaderAvatarWrap>
        <HeaderAvatarImg
          src={data.user.profile.image_url || defaultAvatar}
          placeHolderSrc={defaultAvatar}
        />
      </HeaderAvatarWrap>
    </HeaderRoot>
  )
}
