import React from 'react'
import {
  CasesPeriodSectionRoot,
  CasesPeriodSectionTitle,
} from './CasesPeriodSection.styled'

export type CasesPeriodSectionProps = {
  periodContent: React.ReactNode
  children: React.ReactNode
}

export const CasesPeriodSection = ({
  periodContent,
  children,
}: CasesPeriodSectionProps) => {
  return (
    <CasesPeriodSectionRoot>
      <CasesPeriodSectionTitle>{periodContent}</CasesPeriodSectionTitle>
      <div>{children}</div>
    </CasesPeriodSectionRoot>
  )
}
