import styled from 'styled-components'

export const SmsScreenInputWrap = styled.div`
  margin-top: 7px;
`

export const SendAgainIconWrap = styled.div`
  margin-right: 5px;
`

export const SendAgain = styled.div`
  margin-top: 30px;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  color: #4d7ade;
  cursor: pointer;
`

export const SendAgainDisabled = styled(SendAgain)`
  color: #9395aa;
`
