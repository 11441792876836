import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'
import { UserPanelWidth, UserPanelWidthMd } from './constants'

export const UserPanelRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${UserPanelWidth}px;
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(47, 55, 120, 0.05);
  overflow-y: auto;

  @media (max-width: ${breakpoints.lg}px) {
    width: ${UserPanelWidthMd}px;
  }
`

export const UserPanelBodyWrap = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  border: 1px solid #f2f3f5;
  background: #f5f8fd;
`

export const UserPanelDocsWrap = styled.div`
  padding: 20px;
`

export const UserPanelDocWrap = styled.div`
  margin-bottom: 15px;
`
