import React from 'react'
import { TimeLine } from '../../molecules/TimeLine'
import { DetailsTitle } from '../../atoms/DetailsTitle'
import { DotMiddle } from '../../atoms/DotMiddle'
import { FieldLabel } from '../../atoms/FieldLabel'
import { FieldRow } from '../../atoms/FieldRow'
import { FieldValue } from '../../atoms/FieldValue'
import { FieldsTable } from '../../molecules/FieldsTable'
import { DetailsSecondaryTmpl } from '../../templates/DetailsSecondaryTmpl'
import { DetailsPointTextTmpl } from '../../templates/DetailsPointTextTmpl'
import { CaseStatus, useGreenCardsQuery } from '../../../api/generated'
import { useGCardDetailsParams } from './useGCardDetailsParams'
import { formatDate } from '../../../utils/formatDate'
import { useDetailsRouter } from '../../organisms/DetailsPanel/useDetailsRouter'
import { ArrowButton } from '../../atoms/ArrowButton'
import { TimelineTooltip } from '../../molecules/TimeLine/TimelineTooltip'

export type GCardDetailsProps = {
  userId: string
  onScrollTop: () => void
}

export const GCardDetails = ({ userId, onScrollTop }: GCardDetailsProps) => {
  const { data, loading } = useGreenCardsQuery({ variables: { userId } })
  const { category } = useGCardDetailsParams()
  const { goToGreenCardCaseDetails } = useDetailsRouter()

  if (loading && !data) {
    return <>loading</>
  } else if (!data) {
    return <>not found</>
  }

  const gCard = data.greencards.find(g => g.category === category)
  if (!gCard) {
    return <>not found</>
  }

  const { country, milestones, priorityDate, note, status, cases } = gCard

  return (
    <>
      <DetailsTitle>Green Card Status</DetailsTitle>

      <FieldsTable
        data={[
          { label: 'Class', type: 'string', value: gCard.category },
          {
            label: 'Priority Date',
            type: 'date',
            value: priorityDate ? formatDate(priorityDate) : '',
          },
          { label: 'Country of Chargeability', type: 'string', value: country },
        ]}
      />

      {note && (
        <FieldRow>
          <FieldLabel>Green Card Notes</FieldLabel>
          <FieldValue>{note}</FieldValue>
        </FieldRow>
      )}

      <FieldRow>
        <FieldLabel>Status</FieldLabel>
        <FieldValue bold>
          <DetailsPointTextTmpl
            point={<DotMiddle color='green' />}
            text={status}
          />
        </FieldValue>
      </FieldRow>

      <DetailsSecondaryTmpl>
        <TimeLine
          color='green'
          items={milestones.map((m, idx) => {
            const caseItem = cases.find(c => c.id === m.caseId)
            const time = m.completedDate ? formatDate(m.completedDate) : ''

            return {
              description: (
                <div style={{ position: 'relative', minWidth: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '-10px',
                      left: 0,
                      width: '100%',
                      minHeight: '38px',
                    }}
                  >
                    <TimelineTooltip
                      name={m.name}
                      subName={m.description}
                      time={time}
                    >
                      <span style={{ marginRight: '10px' }}>{m.name}</span>
                    </TimelineTooltip>{' '}
                    {m.caseId && (
                      <ArrowButton
                        onClick={() => {
                          goToGreenCardCaseDetails({ caseId: m.caseId })
                          onScrollTop()
                        }}
                      />
                    )}
                  </div>
                </div>
              ),
              name: m.name,
              subName: m.description,
              important: m.isImportant,
              time,
              isCurrent:
                !milestones[idx + 1]?.completedDate && !!m.completedDate,

              deactivated: caseItem?.status === CaseStatus.Deactivated,
            }
          })}
        />
      </DetailsSecondaryTmpl>
    </>
  )
}
