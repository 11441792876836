/* eslint-disable react/forbid-component-props */
import React from 'react'
import { SplitInput } from '../../../../atoms/SplitInput'
import {
  OtpBtnWrap,
  OtpButton,
  OtpContentInner,
  OtpContentInnerWrap,
  OtpContentWrap,
  OtpDescription,
  OtpFooter,
  OtpHeader,
  OtpLabelError,
  OtpPaper,
  OtpTitle,
  OtpWrap,
} from '../../OtpPage.styled'
import { SmsScreenInputWrap } from '../SmsScreen/SmsScreen.styled'
import { useVerifyLogic } from '../../hooks'
import {
  AuthyDescription,
  AuthyDescriptionWrap,
  AuthyIconWrap,
  AuthyLink,
} from './AuthyScreen.styled'
import { AuthyIcon } from './AuthyIcon'

export type AuthyScreenProps = {
  onCancel: () => void
  onCompleted: () => void
  userId: string
}

export const AuthyScreen = ({
  onCancel,
  onCompleted,
  userId,
}: AuthyScreenProps) => {
  const { loading, setValue, value, wrongErr, submitHandler } = useVerifyLogic({
    userId,
    onCompleted,
  })

  return (
    <OtpWrap>
      <OtpPaper>
        <OtpHeader></OtpHeader>
        <OtpContentWrap>
          <OtpContentInnerWrap>
            <OtpContentInner>
              <OtpTitle>Enter the Code Generated by Authy</OtpTitle>
              <OtpDescription>
                Open Authy app and generate a code for Immidee. Enter the code
                in the field below before it expires.
              </OtpDescription>
              <OtpLabelError style={{ opacity: wrongErr ? 1 : 0 }}>
                Please check the code
              </OtpLabelError>
              <SmsScreenInputWrap>
                <SplitInput
                  value={value}
                  onChange={next => setValue(next)}
                  sections={7}
                  error={wrongErr}
                />
              </SmsScreenInputWrap>

              <AuthyDescriptionWrap>
                <AuthyIconWrap>
                  <AuthyIcon />
                </AuthyIconWrap>
                <AuthyDescription>
                  If you have not installed the Authy app to your mobile device,
                  you can download it from the{' '}
                  <AuthyLink href='https://authy.com/download' target='_blank'>
                    official website
                  </AuthyLink>
                </AuthyDescription>
              </AuthyDescriptionWrap>
            </OtpContentInner>
          </OtpContentInnerWrap>
        </OtpContentWrap>
        <OtpFooter>
          <OtpBtnWrap>
            <OtpButton
              variant='secondary'
              disabled={loading}
              onClick={onCancel}
            >
              Choose Another Method
            </OtpButton>
          </OtpBtnWrap>
          <OtpButton
            variant='primary'
            disabled={loading}
            onClick={submitHandler}
          >
            Submit
          </OtpButton>
        </OtpFooter>
      </OtpPaper>
    </OtpWrap>
  )
}
