import { Redirect, useParams } from 'react-router'
import React from 'react'
import { ProfileHeaderMobile } from '../../molecules/ProfileHeaderMobile'
import { UserPanel } from '../../organisms/UserPanel'
import { ContentWrap } from '../../../App/App.styled'
import { MainScreen } from '../../organisms/MainScreen'
import { useGetUserQuery } from '../../../api/generated'
import { LoadingPlaceholder } from '../../atoms/LoadingPlaceholder'
import { useAuth } from '../LoginPage/login.store'

export type RefUserPageProps = {
  onClickChangePassword: () => void
}

export const RefUserPage = ({ onClickChangePassword }: RefUserPageProps) => {
  const { userId } = useParams<{ userId: string }>()
  const auth = useAuth()
  const { data, loading, error } = useGetUserQuery({
    variables: { id: userId },
  })

  if (loading) {
    return <LoadingPlaceholder />
  }

  if (!data && error) {
    return <>Could not load data</>
  }

  if (!data?.user) {
    return <>404</>
  }

  if (auth.isAuth && auth.employeeId === userId) {
    return <Redirect to='/' />
  }

  return (
    <>
      <ProfileHeaderMobile
        userId={userId}
        isDependent
        onClickChangePassword={onClickChangePassword}
      />
      <UserPanel
        userId={userId}
        isDependent
        onClickChangePassword={onClickChangePassword}
      />
      <ContentWrap>
        <MainScreen userId={userId} isDependentPage />
      </ContentWrap>
    </>
  )
}
