import React from 'react'

export const CancelBig = () => {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.50001 8.88649L2.63075 12.7558L0.244263 10.3693L4.11352 6.50001L0.244263 2.63075L2.63075 0.244263L6.50001 4.11352L10.3693 0.244263L12.7558 2.63075L8.88649 6.50001L12.7558 10.3693L10.3693 12.7558L6.50001 8.88649Z'
        fill='#585B78'
      />
    </svg>
  )
}
