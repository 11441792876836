import styled from 'styled-components'

export const CurrentStatusWrap = styled.div`
  margin-top: -40px;
  transform: translateY(-20px);
`

export const CurrentStatusIconWrap = styled.div`
  flex-shrink: 0;
`

export const CurrentStatusRoot = styled.div`
  padding: 15px;
  padding-left: 14px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  min-height: 75px;
  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(47, 55, 120, 0.1);
  border-radius: 15px;
`

export const CurrentStatusNameWrap = styled.div`
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #585b78;
`

export const CurrentStatusTextWrap = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  color: #2f3778;
`

export const CurrentStatusTextBlock = styled.div`
  margin-left: 12px;
`
