import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const FileListRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    margin: -5px;
  }
`

export const FileListItemWrap = styled.div`
  padding: 10px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    padding: 5px;
  }
`
