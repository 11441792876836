import React from 'react'
import { useHistory } from 'react-router'
import { useGetUserQuery } from '../../../api/generated'
import { Image } from '../../atoms/Image'
import {
  DependentAvatar,
  DependentName,
  DependentRole,
  DependentTextWrap,
  DependentUser,
} from '../../organisms/Dependent/Dependent.styled'
import { ProfileHeaderDepRoot } from './ProfileHeaderDep.styled'
import defaultAvatar from '../../atoms/Avatar/default-avatar.svg'
import { CasesPageBackBtn } from '../../pages/InactiveCasesPage/InactiveCasesPage.styled'
import { ArrowIcon } from '../../icons/ArrowIcon'

export type ProfileHeaderDepProps = {
  userId: string
}

export const ProfileHeaderDep = ({ userId }: ProfileHeaderDepProps) => {
  const history = useHistory()

  const { data } = useGetUserQuery({
    fetchPolicy: 'cache-only',
    variables: { id: userId },
  })

  if (!data?.user) {
    return null
  }

  const {
    firstName,
    lastName,
    middleName,
    profile: { image_url },
    role,
  } = data.user

  const fullName = [firstName, lastName, middleName].filter(Boolean).join(' ')

  return (
    <ProfileHeaderDepRoot>
      <CasesPageBackBtn onClick={() => history.push('/')}>
        <ArrowIcon />
      </CasesPageBackBtn>

      <DependentUser>
        <DependentAvatar>
          <Image
            src={image_url || defaultAvatar}
            placeHolderSrc={defaultAvatar}
          />
        </DependentAvatar>
        <DependentTextWrap>
          <DependentName>{fullName}</DependentName>
          <DependentRole>
            {role[0].toUpperCase()}
            {role.slice(1)}
          </DependentRole>
        </DependentTextWrap>
      </DependentUser>
    </ProfileHeaderDepRoot>
  )
}
