import React from 'react'
import { DotCancelled } from '../../atoms/DotCancelled/DotCancelled'
import { Color } from '../../../types/color'
import { Dot } from '../../atoms/Dot'
import { DotBig } from '../../atoms/DotBig'
import { DotMiddle } from '../../atoms/DotMiddle'
import {
  TimeLineDescription,
  TimeLineDotWrap,
  TimeLineRoot,
  TimeLineRow,
  TimeLineSeparator,
  TimeLineTime,
} from './TimeLine.styled'
import { TimelineTooltip } from './TimelineTooltip'

export type TimelineItem = {
  description: React.ReactNode
  name: string
  subName?: string | null
  time?: string
  isCurrent?: boolean
  important?: boolean
  deactivated?: boolean
}

export type TimeLineProps = {
  items: TimelineItem[]
  color: Color
}

export const TimeLine = ({ items, color: colorProp }: TimeLineProps) => {
  const reversed = React.useMemo(() => [...items].reverse(), [items])

  return (
    <TimeLineRoot>
      {reversed.map(
        (
          {
            description,
            time,
            isCurrent,
            important,
            deactivated,
            name,
            subName,
          },
          idx,
        ) => {
          const isLast = idx + 1 === reversed.length
          const color = time && !deactivated ? colorProp : 'grey'

          let dot: React.ReactElement = important ? (
            <DotBig color={color} />
          ) : (
            <Dot color={color} />
          )
          if (deactivated) {
            dot = <DotCancelled color='grey' big={important} />
          } else if (isCurrent) {
            dot = important ? (
              <DotBig color={color} />
            ) : (
              <DotMiddle color={color} />
            )
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <TimeLineRow isLast={isLast} key={idx}>
              <TimeLineTime>{time}</TimeLineTime>
              <TimeLineSeparator
                color={color}
                isLast={isLast}
                size={
                  isCurrent || important || deactivated ? 'middle' : 'small'
                }
              >
                <TimeLineDotWrap important={important} cancel={deactivated}>
                  <TimelineTooltip name={name} subName={subName} time={time}>
                    <span>{dot}</span>
                    {/* {important ? (
                    <DotBig color={color} />
                  ) : isCurrent ? (
                    <DotMiddle color={color} />
                  ) : (
                    <Dot color={color} />
                  )} */}
                  </TimelineTooltip>
                </TimeLineDotWrap>
              </TimeLineSeparator>
              <TimeLineDescription
                // eslint-disable-next-line react/forbid-component-props
                style={{
                  // eslint-disable-next-line no-undefined
                  color: time ? undefined : '#b3bdd1',
                }}
              >
                {description}
              </TimeLineDescription>
            </TimeLineRow>
          )
        },
      )}
    </TimeLineRoot>
  )
}
