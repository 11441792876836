import styled from 'styled-components'

export const TooltipRoot = styled.div`
  padding: 9px 16px;
  background-color: #fff;
  border: 1px solid #e5ecfa;
  box-sizing: border-box;
  box-shadow: 0px -10px 20px rgba(47, 55, 120, 0.1);
  border-radius: 15px;
  z-index: 9999;
`
