import React from 'react'
import { FieldLabel } from '../../atoms/FieldLabel'
import { DetailFilesTitleWrap } from './DetailFilesTmpl.styled'

export type DetailFilesProps = {
  children: React.ReactNode
}

export const DetailFilesTmpl = ({ children }: DetailFilesProps) => {
  return (
    <>
      <DetailFilesTitleWrap>
        <FieldLabel>Associated files</FieldLabel>
      </DetailFilesTitleWrap>

      {children}
    </>
  )
}
