import React from 'react'
import { FieldLabel } from '../../atoms/FieldLabel'
import { FieldValue } from '../../atoms/FieldValue'
import { FieldsTableCell, FieldsTableRoot } from './FieldsTable.styled'

export type FieldItem = {
  label: string
  type: 'string' | 'date'
  value: string | undefined | null
}

export type FieldsTableProps = {
  data: FieldItem[]
}

export const FieldsTable = ({ data }: FieldsTableProps) => {
  return (
    <FieldsTableRoot>
      {data.map(({ label, value }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <FieldsTableCell key={idx}>
          <FieldLabel>{label}</FieldLabel>
          <FieldValue bold>{value}</FieldValue>
        </FieldsTableCell>
      ))}
    </FieldsTableRoot>
  )
}
