import React from 'react'
import { FbSpinner } from '../FbSpinner'

export const LoadingPlaceholder = () => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FbSpinner />
    </div>
  )
}
