import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const LoginPageRoot = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: ${breakpoints.sm}px) {
    height: 100%;
  }
`

export const LoginPageContent = styled.div`
  height: 100%;
  width: 620px;
  background: #fff;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`

export const LoginPagePlaceholder = styled.div`
  height: 100%;
  flex-grow: 1;
  content: '';
  background: rgb(94, 137, 238);
  background: linear-gradient(
    135deg,
    rgba(94, 137, 238, 1) 0%,
    rgba(46, 54, 119, 1) 100%
  );

  @media (max-width: ${breakpoints.sm}px) {
    display: none;
  }
`

export const LoginPageHeader = styled.div`
  padding-top: 12px;
  padding-left: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-top: 48px;
    padding-left: 0px;
    display: flex;
    justify-content: center;
  }
`

export const LoginPageBrandWrap = styled.div`
  width: 160px;
`

export const LoginPageTitle = styled.h1`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    text-align: center;
  }
`

export const LoginPageError = styled.div`
  margin-bottom: 24px;
  font-weight: normal;
  font-size: 15px;
  line-height: 175%;
  color: #f45b39;
`

export const LoginPageFormContainer = styled.form`
  padding-top: 65px;
  padding-left: 100px;
  padding-right: 180px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
  }
`

export const LoginPageInputWrap = styled.div`
  margin-bottom: 20px;
`

export const LoginPageSigninBtnWrap = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.sm}px) {
    justify-content: center;
  }
`

export const LoginPageSpinnerWrap = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`
