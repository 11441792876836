import React from 'react'
import {
  ProfileHeaderBtnWrap,
  ProfileHeaderLogoImgWrap,
  ProfileHeaderLogoWrap,
  ProfileHeaderRoot,
} from './ProfileHeader.styled'
import { IconButton } from '../../atoms/IconButton'
import { ExitIcon } from '../../icons/ExitIcon'
import BrandLogo from '../../atoms/BrandLogo/BrandLogo'
import { useLoginStore } from '../../pages/LoginPage/login.store'
import { SettingsButton } from '../../atoms/SettingsBtn'

export type ProfileHeaderProps = {
  onClickChangePassword: () => void
}

export const ProfileHeader = ({
  onClickChangePassword,
}: ProfileHeaderProps) => {
  const { logout } = useLoginStore()

  const onLogoutClick = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Do you really want to leave?')) {
      logout()
    }
  }

  return (
    <ProfileHeaderRoot>
      <ProfileHeaderLogoWrap>
        <ProfileHeaderLogoImgWrap>
          <BrandLogo />
        </ProfileHeaderLogoImgWrap>
      </ProfileHeaderLogoWrap>

      <ProfileHeaderBtnWrap>
        <SettingsButton onClickChangePassword={onClickChangePassword} />
      </ProfileHeaderBtnWrap>

      <IconButton onClick={onLogoutClick}>
        <ExitIcon />
      </IconButton>
    </ProfileHeaderRoot>
  )
}
