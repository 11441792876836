import React from 'react'

export const GearIcon = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 1C14.5294 1 15.7692 2.23982 15.7692 3.76923V3.76923C15.7692 4.02072 15.9332 4.24138 16.1694 4.32772C16.4147 4.4174 16.6552 4.5172 16.8903 4.62662C17.1186 4.73286 17.391 4.69277 17.569 4.51472V4.51472C18.6505 3.43327 20.4039 3.43327 21.4853 4.51472C22.5668 5.59617 22.5668 7.34959 21.4853 8.43104V8.43104C21.3072 8.60909 21.2671 8.88139 21.3734 9.10968C21.4828 9.3448 21.5826 9.5853 21.6723 9.83067C21.7587 10.0669 21.9793 10.2308 22.2308 10.2308V10.2308C23.7602 10.2308 25 11.4706 25 13C25 14.5294 23.7602 15.7692 22.2308 15.7692V15.7692C21.9793 15.7692 21.7587 15.9331 21.6723 16.1693C21.5826 16.4147 21.4828 16.6552 21.3734 16.8904C21.2671 17.1187 21.3072 17.391 21.4853 17.569V17.569C22.5667 18.6505 22.5668 20.4038 21.4853 21.4853C20.4038 22.5667 18.6505 22.5667 17.569 21.4853V21.4853C17.391 21.3072 17.1186 21.2671 16.8903 21.3734C16.6552 21.4828 16.4147 21.5826 16.1694 21.6723C15.9332 21.7586 15.7692 21.9793 15.7692 22.2308V22.2308C15.7692 23.7602 14.5294 25 13 25C11.4706 25 10.2308 23.7602 10.2308 22.2308V22.2308C10.2308 21.9793 10.0668 21.7586 9.83064 21.6723C9.58528 21.5826 9.34479 21.4828 9.10968 21.3734C8.88138 21.2671 8.60908 21.3072 8.43101 21.4853V21.4853C7.34956 22.5667 5.59617 22.5667 4.51472 21.4853C3.43327 20.4038 3.43327 18.6505 4.51472 17.569V17.569C4.69279 17.391 4.7329 17.1187 4.62665 16.8904C4.51721 16.6552 4.41739 16.4147 4.32769 16.1693C4.24135 15.9331 4.02071 15.7692 3.76923 15.7692V15.7692C2.23982 15.7692 1 14.5294 1 13C1 11.4706 2.23982 10.2308 3.76923 10.2308V10.2308C4.02071 10.2308 4.24135 10.0669 4.32769 9.83067C4.41739 9.58529 4.5172 9.34479 4.62663 9.10967C4.73287 8.88138 4.69277 8.60909 4.51472 8.43104V8.43104C3.43327 7.34953 3.43327 5.59617 4.51472 4.51472C5.59617 3.43327 7.34956 3.43327 8.43101 4.51472V4.51472C8.60906 4.69277 8.88135 4.73287 9.10964 4.62663C9.34476 4.51721 9.58526 4.41741 9.83064 4.32772C10.0668 4.24138 10.2308 4.02072 10.2308 3.76923V3.76923C10.2308 2.23982 11.4706 1 13 1Z'
        stroke='#BEBFC3'
        strokeWidth='2'
      />
      <circle
        cx='12.9999'
        cy='12.9999'
        r='4.61538'
        stroke='#BEBFC3'
        strokeWidth='2'
      />
    </svg>
  )
}
