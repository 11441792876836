import { breakpoints } from '../breakpoints'
import styled from 'styled-components'
import {
  UserPanelWidth,
  UserPanelWidthMd,
} from '../components/organisms/UserPanel'

export const ContentWrap = styled.div`
  margin-left: ${UserPanelWidth}px;
  padding-top: 25px;
  padding-bottom: 25px;

  @media (max-width: ${breakpoints.lg}px) {
    margin-left: ${UserPanelWidthMd}px;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin-left: 0;
  }

  @media (max-width: ${breakpoints.sm}px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

export const AppRoot = styled.div``
