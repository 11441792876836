import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { gql } from '@apollo/client'
import { AppRoot, ContentWrap } from './App.styled'
import { UserPanel } from '../components/organisms/UserPanel'
import { DetailsPanel } from '../components/organisms/DetailsPanel'
import { MainPage } from '../components/pages/MainPage'
import { breakpoints } from '../breakpoints'
import { RefUserPage } from '../components/pages/RefUserPage'
import { InactiveCasesPage } from '../components/pages/InactiveCasesPage/InactiveCasesPage'
import { HeaderMobileCases } from '../components/molecules/HeaderMobileCases'
import { LoginPage } from '../components/pages/LoginPage'
import {
  useAuth,
  useLoginStore,
} from '../components/pages/LoginPage/login.store'
import { FbSpinner } from '../components/atoms/FbSpinner'
import { GetUserComponent } from '../api/generated'
import { LoadingPlaceholder } from '../components/atoms/LoadingPlaceholder'
import { OtpPage } from '../components/pages/OtpPage'
import { PasswordModal } from '../components/organisms/PasswordModal'
import { GCardInactiveCasesPage } from '../components/pages/InactiveCasesPage/GCardInactiveCasesPage'

export const GET_USER_QUERY = gql`
  query GetUser($id: String!) {
    user(id: $id) {
      ... on Employee {
        user_id
        matterId
        firstName
        lastName
        middleName
        role
        profile {
          image_url
        }
        job {
          title
          company {
            name
            location {
              city
              state
            }
          }
        }

        currentStatus
        advancedParole {
          expirationDate
        }
        i94 {
          number
          dateOfEntry
          classOfAdmission
          expiration
        }
        ead {
          expiration
          comments
          filed
        }
        visa {
          expiration
          type
          comments
        }
        passport {
          number
          issue
          expiration
        }

        files {
          id
          name
          size
          type
          url
          uploadDate
        }

        dependents {
          id
          firstName
          lastName
          middleName
          role
          type

          profile {
            image_url
          }

          importantDocs
          currentStatus
          advancedParole {
            expirationDate
          }
          i94 {
            number
            dateOfEntry
            classOfAdmission
            expiration
          }
          ead {
            expiration
            comments
            filed
          }
          visa {
            expiration
            type
            comments
          }
          passport {
            number
            issue
            expiration
          }
        }
      }

      ... on Dependent {
        user_id
        matterId
        firstName
        lastName
        middleName
        role
        profile {
          image_url
        }

        currentStatus
        advancedParole {
          expirationDate
        }
        i94 {
          number
          dateOfEntry
          classOfAdmission
          expiration
        }
        ead {
          expiration
          comments
          filed
        }
        visa {
          expiration
          type
          comments
        }
        passport {
          number
          issue
          expiration
        }

        files {
          id
          name
          size
          type
          url
          uploadDate
        }

        dependents {
          id
          firstName
          lastName
          middleName
          role
          type

          profile {
            image_url
          }

          importantDocs
          currentStatus
          i94 {
            number
            dateOfEntry
            classOfAdmission
            expiration
          }
          ead {
            expiration
            comments
            filed
          }
          visa {
            expiration
            type
            comments
          }
          passport {
            number
            issue
            expiration
          }
          advancedParole {
            expirationDate
          }
        }
      }
    }
  }
`

export const AppContent = () => {
  const authState = useAuth()
  const { setPasswordChanged } = useLoginStore()
  const [open, setOpen] = useState(false)

  const openChangePassword = () => {
    setOpen(true)
  }

  if (authState.isInitialization) {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FbSpinner />
      </div>
    )
  }

  return (
    <>
      {authState.isAuth && (
        <PasswordModal
          open={open}
          onClose={() => setOpen(false)}
          onSuccess={() => {
            setOpen(false)
            setPasswordChanged()
          }}
          requiredChange={authState.shouldChangePassword}
          userId={authState.employeeId}
        />
      )}

      <Switch>
        <Route path='/login'>
          <LoginPage />

          {authState.isAuth && (
            <Redirect
              to={
                authState.shouldRegisterOTP
                  ? '/otp/reg-phone'
                  : authState.isTemporarily
                  ? '/otp/choose'
                  : '/'
              }
            />
          )}
        </Route>
        {authState.isAuth && (
          <Route path='/otp'>
            <OtpPage userId={authState.employeeId} />
            {!authState.isTemporarily && <Redirect to='/' />}
          </Route>
        )}
        <Route>
          {authState.isAuth && authState.shouldRegisterOTP && (
            <Redirect to='/otp/reg-phone' />
          )}
          {!authState.isAuth ? (
            <Redirect to='/login' />
          ) : (
            <GetUserComponent
              skip={false}
              variables={{ id: authState.employeeId }}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return <LoadingPlaceholder />
                }

                if (error) {
                  return (
                    <div>
                      <h3>{error.name}:</h3>
                      <pre>{error.message}</pre>
                    </div>
                  )
                }

                return data?.user ? (
                  <AppRoot>
                    <Route path='/' exact>
                      <MainPage onClickChangePassword={openChangePassword} />
                    </Route>

                    <Route path='/user/:userId' exact>
                      <RefUserPage onClickChangePassword={openChangePassword} />
                    </Route>

                    <Route path='/user/:userId/inactive-cases' exact>
                      {({ match }) =>
                        match && (
                          <>
                            <MediaQuery minWidth={breakpoints.md + 1}>
                              <UserPanel
                                userId={match.params.userId}
                                isDependent
                                onClickChangePassword={openChangePassword}
                              />
                            </MediaQuery>
                            <MediaQuery maxWidth={breakpoints.md}>
                              <HeaderMobileCases userId={match.params.userId} />
                            </MediaQuery>
                            <ContentWrap>
                              <InactiveCasesPage userId={match.params.userId} />
                            </ContentWrap>
                          </>
                        )
                      }
                    </Route>

                    <Route path='/user/:userId/g-inactive-cases' exact>
                      {({ match }) =>
                        match && (
                          <>
                            <MediaQuery minWidth={breakpoints.md + 1}>
                              <UserPanel
                                isDependent
                                userId={match.params.userId}
                                onClickChangePassword={openChangePassword}
                              />
                            </MediaQuery>
                            <MediaQuery maxWidth={breakpoints.md}>
                              <HeaderMobileCases userId={match.params.userId} />
                            </MediaQuery>
                            <ContentWrap>
                              <GCardInactiveCasesPage
                                userId={match.params.userId}
                              />
                            </ContentWrap>
                          </>
                        )
                      }
                    </Route>

                    <Route path='/inactive-cases' exact>
                      <MediaQuery minWidth={breakpoints.md + 1}>
                        <UserPanel
                          userId={data.user.user_id}
                          onClickChangePassword={openChangePassword}
                        />
                      </MediaQuery>
                      <MediaQuery maxWidth={breakpoints.md}>
                        <HeaderMobileCases userId={data.user.user_id} />
                      </MediaQuery>
                      <ContentWrap>
                        <InactiveCasesPage userId={data.user.user_id} />
                      </ContentWrap>
                    </Route>

                    <Route path='/g-inactive-cases' exact>
                      <MediaQuery minWidth={breakpoints.md + 1}>
                        <UserPanel
                          userId={data.user.user_id}
                          onClickChangePassword={openChangePassword}
                        />
                      </MediaQuery>
                      <MediaQuery maxWidth={breakpoints.md}>
                        <HeaderMobileCases userId={data.user.user_id} />
                      </MediaQuery>
                      <ContentWrap>
                        <GCardInactiveCasesPage userId={data.user.user_id} />
                      </ContentWrap>
                    </Route>

                    <DetailsPanel />
                  </AppRoot>
                ) : null
              }}
            </GetUserComponent>
          )}
        </Route>
      </Switch>
    </>
  )
}
