import React from 'react'
import { Dot } from '../../atoms/Dot'
import { DotBig } from '../../atoms/DotBig'
import { DotMiddle } from '../../atoms/DotMiddle'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { Color } from '../../../types/color'
import {
  HProgressPointTooltipDesc,
  HProgressPointTooltipMainDesc,
  HProgressPointTooltipRoot,
  HProgressPointTooltipTitle,
  HProgressPointWrap,
} from './HProgress.styled'

export type HProgressPointProps = {
  important?: boolean
  color: Color
  filled: boolean
  active: boolean
  percents: number
  visible?: boolean
  title: string
  desc: string
  mainDesc?: string | null
  renderPoint?: (p: { color: Color }) => React.ReactNode
}

export const HProgressPoint = ({
  important,
  color,
  filled,
  active,
  percents,
  visible,
  desc,
  title,
  mainDesc,
  renderPoint,
}: HProgressPointProps) => {
  // const [isOver, hoverProps] = useHover()

  // const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
  //   isOpen: isOver,
  // })

  const renderContent = () => {
    const trueColor = filled || active ? color : 'grey'

    if (renderPoint) {
      return renderPoint({ color: trueColor })
    }

    if (important) {
      return <DotBig color={trueColor} />
    } else if (active) {
      return <DotMiddle color={trueColor} />
    }
    return <Dot color={trueColor} />
  }

  return (
    <>
      <Tooltip
        title={
          <HProgressPointTooltipRoot>
            <HProgressPointTooltipTitle>{title}</HProgressPointTooltipTitle>
            {mainDesc && (
              <HProgressPointTooltipMainDesc>
                {mainDesc}
              </HProgressPointTooltipMainDesc>
            )}
            <HProgressPointTooltipDesc>{desc}</HProgressPointTooltipDesc>
          </HProgressPointTooltipRoot>
        }
      >
        <HProgressPointWrap percents={percents} visible={visible}>
          {renderContent()}
        </HProgressPointWrap>
      </Tooltip>
      {/* {isOver &&
        renderLayer(
          <div className='tooltip' {...layerProps}>
            <div>{title}</div>
            {desc}
            <Arrow {...arrowProps} />
          </div>,
        )} */}
    </>
  )
}
