import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const ContentContainer = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.lg}px) {
    /* width: 720px; */
  }
`
