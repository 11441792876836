import React from 'react'
import { DetailsSecondaryTmpl } from '../../templates/DetailsSecondaryTmpl'
import { DetailsTitle } from '../../atoms/DetailsTitle'
import { DotMiddle } from '../../atoms/DotMiddle'
import { FieldLabel } from '../../atoms/FieldLabel'
import { FieldRow } from '../../atoms/FieldRow'
import { FieldValue } from '../../atoms/FieldValue'
import { FieldsTable } from '../../molecules/FieldsTable'
import { TimeLine, TimelineItem } from '../../molecules/TimeLine'
import { DetailFilesTmpl } from '../../templates/DetailFilesTmpl'
// import { FileList } from '../../organisms/FileList'
import { DetailsPointTextTmpl } from '../../templates/DetailsPointTextTmpl'
import { useCaseDetailsParams } from './useCaseDetailsParams'
import { Case, CaseStatus } from '../../../api/generated'
import { formatDate } from '../../../utils/formatDate'
import { getFullMilestones } from '../../../utils/sortCasesList'
import { DotCancelled } from '../../atoms/DotCancelled/DotCancelled'
import { FileList } from '../../organisms/FileList'

export type CaseDetailsProps = {
  cases: Case[]
  forGCard?: boolean
}

export const CaseDetails = ({ cases, forGCard }: CaseDetailsProps) => {
  const { caseId } = useCaseDetailsParams()

  // if (loading) {
  //   return <FbSpinner />
  // }

  // if (!data?.cases) {
  //   return <>Could not load data</>
  // }

  const caseData = cases.find(c => c.id === caseId)
  if (!caseData) {
    return <>Case not found</>
  }

  const {
    matterCaseId,
    name,
    initiationDate,
    note,
    approvalDate,
    status,
    files,
  } = caseData

  const milestonesAdditional = getFullMilestones(caseData)

  let currentIdx = 0
  for (let i = milestonesAdditional.length - 1; i > 0; i--) {
    const item = milestonesAdditional[i]
    if (item.completedDate) {
      currentIdx = i
      break
    }
  }

  const timeline: TimelineItem[] = milestonesAdditional.map((m, idx) => ({
    description: m.name,
    time: m.completedDate ? formatDate(m.completedDate) : '',
    isCurrent: idx === currentIdx,
    name: m.name,
  }))

  const currentItem = timeline[currentIdx]

  return (
    <>
      <DetailsTitle>{name}</DetailsTitle>

      <FieldsTable
        data={[
          { label: 'Case ID', type: 'string', value: matterCaseId },
          {
            label: 'Initiated',
            type: 'date',
            value: formatDate(initiationDate),
          },
          {
            label: 'Approval',
            type: 'date',
            value: approvalDate ? formatDate(approvalDate) : '',
          },
          // TODO
          // { label: 'LCA Number', type: 'string', value: 'I-200-123-4567' },
          // { label: 'Receipt Number', type: 'string', value: 'WAC987654987' },
        ]}
      />

      {note && (
        <FieldRow>
          <FieldLabel>Case Notes</FieldLabel>
          <FieldValue>{note}</FieldValue>
        </FieldRow>
      )}

      <FieldRow>
        <FieldLabel>Status</FieldLabel>
        <FieldValue bold>
          <DetailsPointTextTmpl
            point={
              status === CaseStatus.Deactivated ? (
                <DotCancelled color='grey' />
              ) : (
                <DotMiddle color={forGCard ? 'green' : 'blue'} />
              )
            }
            text={
              status === CaseStatus.Deactivated
                ? 'Deactivated'
                : currentItem.description
            }
          />
        </FieldValue>
      </FieldRow>

      <DetailsSecondaryTmpl
        footer={
          files &&
          files.length > 0 && (
            <DetailFilesTmpl>
              <FileList
                items={files.map(f => ({
                  title: f.name,
                  downloadUrl: f.url,
                  fileType: f.type,
                  addedDate: f.uploadDate,
                }))}
              />
            </DetailFilesTmpl>
          )
        }
      >
        <TimeLine color={forGCard ? 'green' : 'blue'} items={timeline} />
      </DetailsSecondaryTmpl>
    </>
  )
}
