/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { formatDate } from '../../../utils/formatDate'
import { DocumentIcon, DocumentIconType } from '../../icons/DocumentIcon'
import {
  DocumentDepIconWrap,
  DocumentDepRoot,
  DocumentDepSubtitle,
  DocumentDepTextWrap,
  DocumentDepTitle,
} from './DocumentDep.styled'
import { InfoBig } from '../../icons/InfoBig'

export type DocumentDepProps =
  | {
      typeName: string
      expires: Date
      type: DocumentIconType
    }
  | {
      typeName: string
      type: 'currentStatus'
    }

export const DocumentDep = (p: DocumentDepProps) => {
  return (
    <DocumentDepRoot>
      <DocumentDepIconWrap>
        {p.type === 'currentStatus' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '5px',
              paddingRight: '5px',
              minHeight: '38px',
            }}
          >
            <InfoBig />
          </div>
        ) : (
          <DocumentIcon type={p.type} isGray={false} />
        )}
      </DocumentDepIconWrap>
      <DocumentDepTextWrap>
        <DocumentDepTitle>{p.typeName}</DocumentDepTitle>
        <DocumentDepSubtitle>
          {p.type === 'currentStatus' ? (
            'Current Status'
          ) : (
            <>Exp.: {formatDate(p.expires)}</>
          )}
        </DocumentDepSubtitle>
      </DocumentDepTextWrap>
    </DocumentDepRoot>
  )
}
