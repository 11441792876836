const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

type PixelCrop = {
  width: number
  height: number
  x: number
  y: number
}

/**
 * Although there is a parameter that defines resulting image max size, please take a note,
 * that canvas is not infinite, on some modern browsers it will throw an error if canvasHeight * canvasWidth > 16777216.
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop.
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} imageMaxSize - optional maximum size of the image in pixels, default is 2048
 */
export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: PixelCrop,
  imageMaxSize = 2048,
): Promise<string> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!

  const canvasMaxSize = Math.min(
    imageMaxSize,
    Math.max(pixelCrop.width, pixelCrop.height),
  )

  canvas.width = canvasMaxSize
  canvas.height = canvasMaxSize

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvasMaxSize,
    canvasMaxSize,
  )

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(
      file => {
        resolve(URL.createObjectURL(file))
      },
      'image/jpeg',
      0.92,
    )
  })
}
