import styled from 'styled-components'
import { OtpButton } from '../../pages/OtpPage/OtpPage.styled'
import { Modal } from '../../molecules/Modal'
import { breakpoints } from '../../../breakpoints'

export const PasswordModalRoot = styled(Modal)`
  width: 520px;
  padding-top: 50px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-top: 130px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`

export const DialogTitle = styled.div`
  margin-left: 50px;
  font-weight: bold;
  font-size: 28px;
  line-height: 1;
  color: #282b32;

  @media (max-width: ${breakpoints.sm}px) {
    margin-left: 30px;
  }
`

export const DialogContent = styled.div`
  margin-left: 50px;
  padding-top: 25px;
  padding-bottom: 50px;
  padding-right: 50px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-left: 30px;
    padding-right: 30px;
  }
`

export const DialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
  padding-bottom: 20px;
  margin-left: 50px;
  padding-right: 25px;
  border-top: 1px solid #dadada;

  @media (max-width: ${breakpoints.sm}px) {
    margin-left: 0;
    padding-left: 20px;
    justify-content: flex-start;
  }
`

export const DialogButton = styled(OtpButton)`
  &:not(:last-child) {
    margin-right: 15px;
  }
`

export const DialogInputWrap = styled.div`
  margin-bottom: 20px;
`
