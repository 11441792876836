import React, { useRef } from 'react'
import { useRouteMatch } from 'react-router'
import MediaQuery from 'react-responsive'
import { Drawer } from '../../molecules/Drawer/Drawer'
import { CloseButton } from '../../atoms/CloseButton'
import {
  DetailsPanelHead,
  DetailsPanelInnerRoot,
  DetailsPanelMobileHead,
  DetailsPanelMobileHeadCloseWrap,
  DetailsPanelMobileHeadLogoWrap,
  DetailsPanelRoot,
} from './DetailsPanel.styled'
import { GCardDetails } from '../../pages/GCardDetails/GCardDetails'
import { breakpoints } from '../../../breakpoints'
import BrandLogo from '../../atoms/BrandLogo/BrandLogo'
import { DocumentDetails } from '../../pages/DocumentDetails'
import { useDetailsScreenType } from './useDetailsScreenType'
import { useDetailsRouter } from './useDetailsRouter'
import { CaseDetailsWrap } from '../../pages/CaseDetails/CaseDetailsWrap'
import { useAuth } from '../../pages/LoginPage/login.store'

export const DetailsPanel = () => {
  const auth = useAuth()
  const match = useRouteMatch<{ userId: string }>({ path: '/user/:userId' })
  const rootRef = useRef<HTMLDivElement>(null)

  const userId = match?.params.userId
    ? match.params.userId
    : auth.isAuth
    ? auth.employeeId
    : ''

  const screenType = useDetailsScreenType()
  const detailsRouter = useDetailsRouter()

  const onClose = React.useCallback(() => {
    detailsRouter.closeDetails()
  }, [detailsRouter])

  const onScrollTop = () => {
    if (rootRef.current) {
      rootRef.current.scrollTo({ top: 0 })
    }
  }

  const screenJSX = React.useMemo(() => {
    switch (screenType) {
      case 'case':
        return <CaseDetailsWrap userId={userId} />
      case 'green_card_case':
        return <CaseDetailsWrap forGCard userId={userId} />
      case 'green_card':
        return <GCardDetails userId={userId} onScrollTop={onScrollTop} />
      case 'docs':
        return <DocumentDetails userId={userId} />
    }
  }, [screenType, userId])

  return (
    <Drawer open={!!screenJSX} onClose={onClose}>
      <DetailsPanelRoot ref={rootRef}>
        <DetailsPanelInnerRoot>
          <MediaQuery maxWidth={breakpoints.sm}>
            <DetailsPanelMobileHead>
              <DetailsPanelMobileHeadCloseWrap>
                <CloseButton onClick={onClose} />
              </DetailsPanelMobileHeadCloseWrap>

              <DetailsPanelMobileHeadLogoWrap>
                <BrandLogo />
              </DetailsPanelMobileHeadLogoWrap>
            </DetailsPanelMobileHead>
          </MediaQuery>

          <MediaQuery minWidth={breakpoints.sm + 1}>
            <DetailsPanelHead>
              <CloseButton onClick={onClose} />
            </DetailsPanelHead>
          </MediaQuery>

          {screenJSX}
        </DetailsPanelInnerRoot>
      </DetailsPanelRoot>
    </Drawer>
  )
}
