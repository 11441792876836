import React from 'react'
import { useDetailsRouter } from '../../organisms/DetailsPanel/useDetailsRouter'
import { CaseActiveFilter, useCasesQuery } from '../../../api/generated'
import { InactiveCasesScreen } from './InactiveCasesScreen'

export type InactiveCasesPageProps = {
  userId: string
}

export const InactiveCasesPage = ({ userId }: InactiveCasesPageProps) => {
  const detailsRouter = useDetailsRouter()
  const { data, loading } = useCasesQuery({
    variables: { userId, activeFilter: CaseActiveFilter.Notactive },
  })

  return (
    <InactiveCasesScreen
      cases={(data?.cases as any) || []}
      onClickCaseDetails={({ caseId }) =>
        detailsRouter.goToCaseDetails({ caseId })
      }
      loading={loading}
    />
  )
}
