import React from 'react'
import { FileCard } from '../../molecules/FileCard'
import { FileListItemWrap, FileListRoot } from './FileList.styled'

export type FileItemData = {
  title: string
  addedDate: Date
  fileType: string
  downloadUrl: string
}

export interface FileListProps {
  items: FileItemData[]
}

export function FileList({ items }: FileListProps) {
  return (
    <FileListRoot>
      {items.map(({ downloadUrl, addedDate, fileType, title }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <FileListItemWrap key={idx}>
          <FileCard
            title={title}
            addedDate={addedDate}
            fileType={fileType}
            downloadUrl={downloadUrl}
          />
        </FileListItemWrap>
      ))}
    </FileListRoot>
  )
}
