import styled from 'styled-components'

export const AuthyDescription = styled.div`
  padding-top: 5px;
  margin-left: 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 175%;
  color: rgba(0, 0, 0, 0.5);
`

export const AuthyIconWrap = styled.div`
  flex-shrink: 0;
  display: flex;
`

export const AuthyDescriptionWrap = styled.div`
  margin-top: 80px;
  margin-bottom: 20px;
  display: flex;
`

export const AuthyLink = styled.a`
  color: #6494ff;
  font-weight: 600;
`
