import React from 'react'
import MediaQuery from 'react-responsive'
import { ProfileHeader } from '../../molecules/ProfileHeader'
import { User } from '../../molecules/User'
import {
  UserPanelBodyWrap,
  UserPanelDocsWrap,
  UserPanelRoot,
} from './UserPanel.styled'
import { DocumentsList } from '../DocumentsList'
import { useGetUserQuery } from '../../../api/generated'
import { breakpoints } from '../../../breakpoints'
import { isNotNullOrUndefined } from '../../../utils/isNotNullOrUndefined'
import { ProfileHeaderDep } from '../../molecules/ProfileHeaderDep'
import { useAuth } from '../../pages/LoginPage/login.store'
import { CurrentStatus } from '../../atoms/CurrentStatus'
import { CurrentStatusWrap } from '../../atoms/CurrentStatus/CurrentStatus.styled'

type UserPanelProps = {
  userId: string
  isDependent?: boolean
  onClickChangePassword: () => void
}

export const UserPanel = ({
  userId,
  isDependent,
  onClickChangePassword,
}: UserPanelProps) => {
  const { data, loading } = useGetUserQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: userId },
  })

  const auth = useAuth()

  if (loading && !data) {
    return null
  }

  if (!data?.user || !auth.isAuth) {
    return <>Could not load data</>
  }

  const { user } = data

  const {
    user_id,
    firstName,
    lastName,
    middleName,
    profile,
    visa,
    passport,
    i94,
    ead,
    advancedParole,
    currentStatus,
  } = data.user
  const { image_url } = profile

  const name = [firstName, lastName, middleName].join(' ')

  return (
    <MediaQuery minWidth={breakpoints.md + 1}>
      <UserPanelRoot>
        {isDependent ? (
          <ProfileHeaderDep userId={auth.employeeId} />
        ) : (
          <ProfileHeader onClickChangePassword={onClickChangePassword} />
        )}
        <UserPanelBodyWrap
          // eslint-disable-next-line no-undefined, react/forbid-component-props
          style={{ paddingBottom: advancedParole ? '60px' : undefined }}
        >
          <User
            isDependent={isDependent}
            user_id={user_id}
            avatarUrl={image_url}
            name={name}
            jobText={
              user.__typename === 'Employee'
                ? `${user.job.title} ${user.job.company.name}`
                : ''
            }
            locationText={
              user.__typename === 'Employee'
                ? `${user.job.company.location.city} ${user.job.company.location.state}`
                : ''
            }
          />
        </UserPanelBodyWrap>
        <UserPanelDocsWrap
        // style={{ paddingTop: advancedParole ? '10' : undefined }}
        >
          {currentStatus && (
            <CurrentStatusWrap>
              <CurrentStatus text={currentStatus} />
            </CurrentStatusWrap>
          )}

          <DocumentsList
            list={
              [
                {
                  name: '',
                  expires: advancedParole?.expirationDate,
                  type: 'parole',
                  typeName: 'Advance Parole',
                },
                {
                  name: '',
                  expires: i94?.expiration,
                  type: 'i94',
                  typeName: 'I-94',
                  isExtension: true,
                },
                {
                  name: '',
                  expires: ead?.expiration,
                  type: 'ead',
                  typeName: 'EAD',
                },
                {
                  name: visa?.type,
                  expires: visa?.expiration,
                  type: 'visa',
                  typeName: 'Visa',
                },
                {
                  name: passport?.number,
                  expires: passport?.expiration,
                  type: 'passport',
                  typeName: 'Passport',
                },
              ].filter(({ expires }) => isNotNullOrUndefined(expires)) as any[]
            }
          />
        </UserPanelDocsWrap>
      </UserPanelRoot>
    </MediaQuery>
  )
}
