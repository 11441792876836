import { useLayoutEffect, useReducer } from 'react'

export type Store<T> = {
  getState: () => T
  setState: (d: T) => void
  watch: (s: Subscriber<T>) => () => void
}

export type Subscriber<T> = (v: T) => void

export function createStore<T>(initialValue: T): Store<T> {
  const x = {
    state: initialValue,
  }

  const subscribers: Subscriber<T>[] = []

  const setState = (nextVal: T) => {
    x.state = nextVal

    subscribers.forEach(s => {
      s(x.state)
    })
  }

  const getState = () => {
    return x.state
  }

  const watch = (s: Subscriber<T>) => {
    subscribers.push(s)

    return () => {
      const idx = subscribers.findIndex(oldS => oldS === s)
      if (idx > -1) {
        subscribers.splice(idx, 1)
      }
    }
  }

  return {
    getState,
    setState,
    watch,
  }
}

export function useStore<T>(store: Store<T>): T {
  const [, forceRender] = useReducer(s => s + 1, 0)

  useLayoutEffect(() => {
    const off = store.watch(() => {
      forceRender()
    })

    return off
  }, [store])

  return store.getState()
}
