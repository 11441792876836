/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
};

export type AdvancedParole = {
  __typename: 'AdvancedParole';
  expirationDate?: Maybe<Scalars['DateTime']>;
};

export type AuthCheckResponse = AuthCheckSuccessResponse | AuthCheckUnauthorizedResponse;

export type AuthCheckSuccessResponse = {
  __typename: 'AuthCheckSuccessResponse';
  employeeId: Scalars['String'];
};

export type AuthCheckUnauthorizedResponse = {
  __typename: 'AuthCheckUnauthorizedResponse';
  success: Scalars['Boolean'];
};

export type Case = {
  __typename: 'Case';
  id: Scalars['ID'];
  matterCaseId: Scalars['String'];
  name: Scalars['String'];
  initiationDate: Scalars['DateTime'];
  approvalDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  note: Scalars['String'];
  status: CaseStatus;
  milestones: Array<CaseMilestone>;
  files?: Maybe<Array<File>>;
};

export enum CaseActiveFilter {
  Active = 'active',
  Notactive = 'notactive'
}

export type CaseMilestone = {
  __typename: 'CaseMilestone';
  name: Scalars['String'];
  completedDate?: Maybe<Scalars['DateTime']>;
};

export enum CaseStatus {
  InProgress = 'InProgress',
  Deactivated = 'Deactivated',
  Completed = 'Completed'
}


export type Dependent = User & {
  __typename: 'Dependent';
  user_id: Scalars['ID'];
  matterId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  role: Scalars['String'];
  profile: UserProfile;
  dependents: Array<UserShortInfo>;
  currentStatus?: Maybe<Scalars['String']>;
  i94?: Maybe<I94>;
  ead?: Maybe<Ead>;
  visa?: Maybe<Visa>;
  passport?: Maybe<Passport>;
  advancedParole?: Maybe<AdvancedParole>;
  files: Array<File>;
};

export type Ead = {
  __typename: 'EAD';
  expiration?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  filed?: Maybe<Scalars['Boolean']>;
};

export type Employee = User & {
  __typename: 'Employee';
  user_id: Scalars['ID'];
  matterId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  role: Scalars['String'];
  profile: UserProfile;
  job: EmployeeJob;
  dependents: Array<UserShortInfo>;
  currentStatus?: Maybe<Scalars['String']>;
  i94?: Maybe<I94>;
  ead?: Maybe<Ead>;
  visa?: Maybe<Visa>;
  passport?: Maybe<Passport>;
  advancedParole?: Maybe<AdvancedParole>;
  files: Array<File>;
};

export type EmployeeJob = {
  __typename: 'EmployeeJob';
  title: Scalars['String'];
  company: JobCompany;
};

export type EmployeeOrDependent = Employee | Dependent;

export type File = {
  __typename: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
  uploadDate: Scalars['DateTime'];
};

export type GreenCard = {
  __typename: 'GreenCard';
  status: Scalars['String'];
  priorityDate?: Maybe<Scalars['DateTime']>;
  note: Scalars['String'];
  category: Scalars['String'];
  country: Scalars['String'];
  milestones: Array<GreenCardMilestone>;
  cases: Array<Case>;
};

export type GreenCardMilestone = {
  __typename: 'GreenCardMilestone';
  name: Scalars['String'];
  completedDate?: Maybe<Scalars['DateTime']>;
  caseId: Scalars['String'];
  isImportant: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};

export type I94 = {
  __typename: 'I94';
  number?: Maybe<Scalars['String']>;
  dateOfEntry?: Maybe<Scalars['DateTime']>;
  classOfAdmission?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['DateTime']>;
};

export type JobCompany = {
  __typename: 'JobCompany';
  name: Scalars['String'];
  location: Location;
};

export type Location = {
  __typename: 'Location';
  city: Scalars['String'];
  state: Scalars['String'];
};

export type LoginResponse = LoginSuccessResponse | LoginWrongCredentials;

export type LoginSuccessResponse = {
  __typename: 'LoginSuccessResponse';
  id: Scalars['String'];
  employeeId: Scalars['String'];
  shouldRegisterOTP: Scalars['Boolean'];
  shouldChangePassword: Scalars['Boolean'];
  countryCode: Scalars['String'];
  mobilenum: Scalars['String'];
  accessToken: Scalars['String'];
};

export type LoginWrongCredentials = {
  __typename: 'LoginWrongCredentials';
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  login: LoginResponse;
  registerOtp?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  callMe?: Maybe<Scalars['Boolean']>;
  verifyOtp?: Maybe<VerifyOtpResponse>;
  changePassword?: Maybe<Scalars['Boolean']>;
  updatePassword?: Maybe<Scalars['Boolean']>;
};


export type MutationLoginArgs = {
  login: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterOtpArgs = {
  countryCode: Scalars['String'];
  mobilenum: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationSendSmsArgs = {
  userId: Scalars['String'];
};


export type MutationCallMeArgs = {
  userId: Scalars['String'];
};


export type MutationVerifyOtpArgs = {
  userId: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  userId: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  userId: Scalars['String'];
  password: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Passport = {
  __typename: 'Passport';
  number?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename: 'Query';
  authCheck: AuthCheckResponse;
  user?: Maybe<EmployeeOrDependent>;
  cases: Array<Case>;
  greencards: Array<GreenCard>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryCasesArgs = {
  userId: Scalars['String'];
  activeFilter?: Maybe<CaseActiveFilter>;
};


export type QueryGreencardsArgs = {
  userId: Scalars['String'];
};

export type User = {
  user_id: Scalars['ID'];
  matterId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  role: Scalars['String'];
  profile: UserProfile;
  dependents: Array<UserShortInfo>;
  currentStatus?: Maybe<Scalars['String']>;
  i94?: Maybe<I94>;
  ead?: Maybe<Ead>;
  visa?: Maybe<Visa>;
  passport?: Maybe<Passport>;
  advancedParole?: Maybe<AdvancedParole>;
  files: Array<File>;
};

export type UserProfile = {
  __typename: 'UserProfile';
  image_height?: Maybe<Scalars['String']>;
  image_width?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
};

export type UserShortInfo = {
  __typename: 'UserShortInfo';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  role: Scalars['String'];
  profile: UserProfile;
  type?: Maybe<Scalars['String']>;
  importantDocs: Array<Scalars['String']>;
  currentStatus?: Maybe<Scalars['String']>;
  i94?: Maybe<I94>;
  ead?: Maybe<Ead>;
  visa?: Maybe<Visa>;
  passport?: Maybe<Passport>;
  advancedParole?: Maybe<AdvancedParole>;
};

export type VerifyOtpResponse = VerifyOtpSuccessResponse | VerifyOtpWrongCodeResponse;

export type VerifyOtpSuccessResponse = {
  __typename: 'VerifyOtpSuccessResponse';
  success: Scalars['Boolean'];
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type VerifyOtpWrongCodeResponse = {
  __typename: 'VerifyOtpWrongCodeResponse';
  success: Scalars['Boolean'];
};

export type Visa = {
  __typename: 'Visa';
  expiration?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdvancedParole: ResolverTypeWrapper<AdvancedParole>;
  AuthCheckResponse: ResolversTypes['AuthCheckSuccessResponse'] | ResolversTypes['AuthCheckUnauthorizedResponse'];
  AuthCheckSuccessResponse: ResolverTypeWrapper<AuthCheckSuccessResponse>;
  String: ResolverTypeWrapper<Scalars['String']>;
  AuthCheckUnauthorizedResponse: ResolverTypeWrapper<AuthCheckUnauthorizedResponse>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Case: ResolverTypeWrapper<Case>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  CaseActiveFilter: CaseActiveFilter;
  CaseMilestone: ResolverTypeWrapper<CaseMilestone>;
  CaseStatus: CaseStatus;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Dependent: ResolverTypeWrapper<Dependent>;
  EAD: ResolverTypeWrapper<Ead>;
  Employee: ResolverTypeWrapper<Employee>;
  EmployeeJob: ResolverTypeWrapper<EmployeeJob>;
  EmployeeOrDependent: ResolversTypes['Employee'] | ResolversTypes['Dependent'];
  File: ResolverTypeWrapper<File>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  GreenCard: ResolverTypeWrapper<GreenCard>;
  GreenCardMilestone: ResolverTypeWrapper<GreenCardMilestone>;
  I94: ResolverTypeWrapper<I94>;
  JobCompany: ResolverTypeWrapper<JobCompany>;
  Location: ResolverTypeWrapper<Location>;
  LoginResponse: ResolversTypes['LoginSuccessResponse'] | ResolversTypes['LoginWrongCredentials'];
  LoginSuccessResponse: ResolverTypeWrapper<LoginSuccessResponse>;
  LoginWrongCredentials: ResolverTypeWrapper<LoginWrongCredentials>;
  Mutation: ResolverTypeWrapper<{}>;
  Passport: ResolverTypeWrapper<Passport>;
  Query: ResolverTypeWrapper<{}>;
  User: ResolversTypes['Dependent'] | ResolversTypes['Employee'];
  UserProfile: ResolverTypeWrapper<UserProfile>;
  UserShortInfo: ResolverTypeWrapper<UserShortInfo>;
  VerifyOtpResponse: ResolversTypes['VerifyOtpSuccessResponse'] | ResolversTypes['VerifyOtpWrongCodeResponse'];
  VerifyOtpSuccessResponse: ResolverTypeWrapper<VerifyOtpSuccessResponse>;
  VerifyOtpWrongCodeResponse: ResolverTypeWrapper<VerifyOtpWrongCodeResponse>;
  Visa: ResolverTypeWrapper<Visa>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AdvancedParole: AdvancedParole;
  AuthCheckResponse: ResolversParentTypes['AuthCheckSuccessResponse'] | ResolversParentTypes['AuthCheckUnauthorizedResponse'];
  AuthCheckSuccessResponse: AuthCheckSuccessResponse;
  String: Scalars['String'];
  AuthCheckUnauthorizedResponse: AuthCheckUnauthorizedResponse;
  Boolean: Scalars['Boolean'];
  Case: Case;
  ID: Scalars['ID'];
  CaseMilestone: CaseMilestone;
  DateTime: Scalars['DateTime'];
  Dependent: Dependent;
  EAD: Ead;
  Employee: Employee;
  EmployeeJob: EmployeeJob;
  EmployeeOrDependent: ResolversParentTypes['Employee'] | ResolversParentTypes['Dependent'];
  File: File;
  Int: Scalars['Int'];
  GreenCard: GreenCard;
  GreenCardMilestone: GreenCardMilestone;
  I94: I94;
  JobCompany: JobCompany;
  Location: Location;
  LoginResponse: ResolversParentTypes['LoginSuccessResponse'] | ResolversParentTypes['LoginWrongCredentials'];
  LoginSuccessResponse: LoginSuccessResponse;
  LoginWrongCredentials: LoginWrongCredentials;
  Mutation: {};
  Passport: Passport;
  Query: {};
  User: ResolversParentTypes['Dependent'] | ResolversParentTypes['Employee'];
  UserProfile: UserProfile;
  UserShortInfo: UserShortInfo;
  VerifyOtpResponse: ResolversParentTypes['VerifyOtpSuccessResponse'] | ResolversParentTypes['VerifyOtpWrongCodeResponse'];
  VerifyOtpSuccessResponse: VerifyOtpSuccessResponse;
  VerifyOtpWrongCodeResponse: VerifyOtpWrongCodeResponse;
  Visa: Visa;
};

export type AdvancedParoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvancedParole'] = ResolversParentTypes['AdvancedParole']> = {
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthCheckResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthCheckResponse'] = ResolversParentTypes['AuthCheckResponse']> = {
  __resolveType: TypeResolveFn<'AuthCheckSuccessResponse' | 'AuthCheckUnauthorizedResponse', ParentType, ContextType>;
};

export type AuthCheckSuccessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthCheckSuccessResponse'] = ResolversParentTypes['AuthCheckSuccessResponse']> = {
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthCheckUnauthorizedResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthCheckUnauthorizedResponse'] = ResolversParentTypes['AuthCheckUnauthorizedResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Case'] = ResolversParentTypes['Case']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  matterCaseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  initiationDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  approvalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  completionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  note?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['CaseStatus'], ParentType, ContextType>;
  milestones?: Resolver<Array<ResolversTypes['CaseMilestone']>, ParentType, ContextType>;
  files?: Resolver<Maybe<Array<ResolversTypes['File']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseMilestoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseMilestone'] = ResolversParentTypes['CaseMilestone']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DependentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Dependent'] = ResolversParentTypes['Dependent']> = {
  user_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  matterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
  dependents?: Resolver<Array<ResolversTypes['UserShortInfo']>, ParentType, ContextType>;
  currentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  i94?: Resolver<Maybe<ResolversTypes['I94']>, ParentType, ContextType>;
  ead?: Resolver<Maybe<ResolversTypes['EAD']>, ParentType, ContextType>;
  visa?: Resolver<Maybe<ResolversTypes['Visa']>, ParentType, ContextType>;
  passport?: Resolver<Maybe<ResolversTypes['Passport']>, ParentType, ContextType>;
  advancedParole?: Resolver<Maybe<ResolversTypes['AdvancedParole']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EadResolvers<ContextType = any, ParentType extends ResolversParentTypes['EAD'] = ResolversParentTypes['EAD']> = {
  expiration?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']> = {
  user_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  matterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
  job?: Resolver<ResolversTypes['EmployeeJob'], ParentType, ContextType>;
  dependents?: Resolver<Array<ResolversTypes['UserShortInfo']>, ParentType, ContextType>;
  currentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  i94?: Resolver<Maybe<ResolversTypes['I94']>, ParentType, ContextType>;
  ead?: Resolver<Maybe<ResolversTypes['EAD']>, ParentType, ContextType>;
  visa?: Resolver<Maybe<ResolversTypes['Visa']>, ParentType, ContextType>;
  passport?: Resolver<Maybe<ResolversTypes['Passport']>, ParentType, ContextType>;
  advancedParole?: Resolver<Maybe<ResolversTypes['AdvancedParole']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeJob'] = ResolversParentTypes['EmployeeJob']> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['JobCompany'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeOrDependentResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeOrDependent'] = ResolversParentTypes['EmployeeOrDependent']> = {
  __resolveType: TypeResolveFn<'Employee' | 'Dependent', ParentType, ContextType>;
};

export type FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['File'] = ResolversParentTypes['File']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GreenCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['GreenCard'] = ResolversParentTypes['GreenCard']> = {
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priorityDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  note?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  milestones?: Resolver<Array<ResolversTypes['GreenCardMilestone']>, ParentType, ContextType>;
  cases?: Resolver<Array<ResolversTypes['Case']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GreenCardMilestoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['GreenCardMilestone'] = ResolversParentTypes['GreenCardMilestone']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  completedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  caseId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isImportant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type I94Resolvers<ContextType = any, ParentType extends ResolversParentTypes['I94'] = ResolversParentTypes['I94']> = {
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfEntry?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  classOfAdmission?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobCompany'] = ResolversParentTypes['JobCompany']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']> = {
  __resolveType: TypeResolveFn<'LoginSuccessResponse' | 'LoginWrongCredentials', ParentType, ContextType>;
};

export type LoginSuccessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginSuccessResponse'] = ResolversParentTypes['LoginSuccessResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shouldRegisterOTP?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shouldChangePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mobilenum?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginWrongCredentialsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginWrongCredentials'] = ResolversParentTypes['LoginWrongCredentials']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  login?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<MutationLoginArgs, 'login' | 'password'>>;
  registerOtp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRegisterOtpArgs, 'countryCode' | 'mobilenum' | 'userId'>>;
  sendSms?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSendSmsArgs, 'userId'>>;
  callMe?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCallMeArgs, 'userId'>>;
  verifyOtp?: Resolver<Maybe<ResolversTypes['VerifyOtpResponse']>, ParentType, ContextType, RequireFields<MutationVerifyOtpArgs, 'userId' | 'otp'>>;
  changePassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'userId' | 'newPassword'>>;
  updatePassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'userId' | 'password' | 'newPassword'>>;
};

export type PassportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Passport'] = ResolversParentTypes['Passport']> = {
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  issue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  authCheck?: Resolver<ResolversTypes['AuthCheckResponse'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['EmployeeOrDependent']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  cases?: Resolver<Array<ResolversTypes['Case']>, ParentType, ContextType, RequireFields<QueryCasesArgs, 'userId'>>;
  greencards?: Resolver<Array<ResolversTypes['GreenCard']>, ParentType, ContextType, RequireFields<QueryGreencardsArgs, 'userId'>>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  __resolveType: TypeResolveFn<'Dependent' | 'Employee', ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  matterId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
  dependents?: Resolver<Array<ResolversTypes['UserShortInfo']>, ParentType, ContextType>;
  currentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  i94?: Resolver<Maybe<ResolversTypes['I94']>, ParentType, ContextType>;
  ead?: Resolver<Maybe<ResolversTypes['EAD']>, ParentType, ContextType>;
  visa?: Resolver<Maybe<ResolversTypes['Visa']>, ParentType, ContextType>;
  passport?: Resolver<Maybe<ResolversTypes['Passport']>, ParentType, ContextType>;
  advancedParole?: Resolver<Maybe<ResolversTypes['AdvancedParole']>, ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['File']>, ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  image_height?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image_width?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShortInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserShortInfo'] = ResolversParentTypes['UserShortInfo']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  middleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  importantDocs?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  currentStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  i94?: Resolver<Maybe<ResolversTypes['I94']>, ParentType, ContextType>;
  ead?: Resolver<Maybe<ResolversTypes['EAD']>, ParentType, ContextType>;
  visa?: Resolver<Maybe<ResolversTypes['Visa']>, ParentType, ContextType>;
  passport?: Resolver<Maybe<ResolversTypes['Passport']>, ParentType, ContextType>;
  advancedParole?: Resolver<Maybe<ResolversTypes['AdvancedParole']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyOtpResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyOtpResponse'] = ResolversParentTypes['VerifyOtpResponse']> = {
  __resolveType: TypeResolveFn<'VerifyOtpSuccessResponse' | 'VerifyOtpWrongCodeResponse', ParentType, ContextType>;
};

export type VerifyOtpSuccessResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyOtpSuccessResponse'] = ResolversParentTypes['VerifyOtpSuccessResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyOtpWrongCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyOtpWrongCodeResponse'] = ResolversParentTypes['VerifyOtpWrongCodeResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisaResolvers<ContextType = any, ParentType extends ResolversParentTypes['Visa'] = ResolversParentTypes['Visa']> = {
  expiration?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AdvancedParole?: AdvancedParoleResolvers<ContextType>;
  AuthCheckResponse?: AuthCheckResponseResolvers<ContextType>;
  AuthCheckSuccessResponse?: AuthCheckSuccessResponseResolvers<ContextType>;
  AuthCheckUnauthorizedResponse?: AuthCheckUnauthorizedResponseResolvers<ContextType>;
  Case?: CaseResolvers<ContextType>;
  CaseMilestone?: CaseMilestoneResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Dependent?: DependentResolvers<ContextType>;
  EAD?: EadResolvers<ContextType>;
  Employee?: EmployeeResolvers<ContextType>;
  EmployeeJob?: EmployeeJobResolvers<ContextType>;
  EmployeeOrDependent?: EmployeeOrDependentResolvers<ContextType>;
  File?: FileResolvers<ContextType>;
  GreenCard?: GreenCardResolvers<ContextType>;
  GreenCardMilestone?: GreenCardMilestoneResolvers<ContextType>;
  I94?: I94Resolvers<ContextType>;
  JobCompany?: JobCompanyResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  LoginSuccessResponse?: LoginSuccessResponseResolvers<ContextType>;
  LoginWrongCredentials?: LoginWrongCredentialsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Passport?: PassportResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  UserShortInfo?: UserShortInfoResolvers<ContextType>;
  VerifyOtpResponse?: VerifyOtpResponseResolvers<ContextType>;
  VerifyOtpSuccessResponse?: VerifyOtpSuccessResponseResolvers<ContextType>;
  VerifyOtpWrongCodeResponse?: VerifyOtpWrongCodeResponseResolvers<ContextType>;
  Visa?: VisaResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'Employee' }
    & Pick<Employee, 'user_id' | 'matterId' | 'firstName' | 'lastName' | 'middleName' | 'role' | 'currentStatus'>
    & { profile: (
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'image_url'>
    ), job: (
      { __typename: 'EmployeeJob' }
      & Pick<EmployeeJob, 'title'>
      & { company: (
        { __typename: 'JobCompany' }
        & Pick<JobCompany, 'name'>
        & { location: (
          { __typename: 'Location' }
          & Pick<Location, 'city' | 'state'>
        ) }
      ) }
    ), advancedParole?: Maybe<(
      { __typename: 'AdvancedParole' }
      & Pick<AdvancedParole, 'expirationDate'>
    )>, i94?: Maybe<(
      { __typename: 'I94' }
      & Pick<I94, 'number' | 'dateOfEntry' | 'classOfAdmission' | 'expiration'>
    )>, ead?: Maybe<(
      { __typename: 'EAD' }
      & Pick<Ead, 'expiration' | 'comments' | 'filed'>
    )>, visa?: Maybe<(
      { __typename: 'Visa' }
      & Pick<Visa, 'expiration' | 'type' | 'comments'>
    )>, passport?: Maybe<(
      { __typename: 'Passport' }
      & Pick<Passport, 'number' | 'issue' | 'expiration'>
    )>, files: Array<(
      { __typename: 'File' }
      & Pick<File, 'id' | 'name' | 'size' | 'type' | 'url' | 'uploadDate'>
    )>, dependents: Array<(
      { __typename: 'UserShortInfo' }
      & Pick<UserShortInfo, 'id' | 'firstName' | 'lastName' | 'middleName' | 'role' | 'type' | 'importantDocs' | 'currentStatus'>
      & { profile: (
        { __typename: 'UserProfile' }
        & Pick<UserProfile, 'image_url'>
      ), advancedParole?: Maybe<(
        { __typename: 'AdvancedParole' }
        & Pick<AdvancedParole, 'expirationDate'>
      )>, i94?: Maybe<(
        { __typename: 'I94' }
        & Pick<I94, 'number' | 'dateOfEntry' | 'classOfAdmission' | 'expiration'>
      )>, ead?: Maybe<(
        { __typename: 'EAD' }
        & Pick<Ead, 'expiration' | 'comments' | 'filed'>
      )>, visa?: Maybe<(
        { __typename: 'Visa' }
        & Pick<Visa, 'expiration' | 'type' | 'comments'>
      )>, passport?: Maybe<(
        { __typename: 'Passport' }
        & Pick<Passport, 'number' | 'issue' | 'expiration'>
      )> }
    )> }
  ) | (
    { __typename: 'Dependent' }
    & Pick<Dependent, 'user_id' | 'matterId' | 'firstName' | 'lastName' | 'middleName' | 'role' | 'currentStatus'>
    & { profile: (
      { __typename: 'UserProfile' }
      & Pick<UserProfile, 'image_url'>
    ), advancedParole?: Maybe<(
      { __typename: 'AdvancedParole' }
      & Pick<AdvancedParole, 'expirationDate'>
    )>, i94?: Maybe<(
      { __typename: 'I94' }
      & Pick<I94, 'number' | 'dateOfEntry' | 'classOfAdmission' | 'expiration'>
    )>, ead?: Maybe<(
      { __typename: 'EAD' }
      & Pick<Ead, 'expiration' | 'comments' | 'filed'>
    )>, visa?: Maybe<(
      { __typename: 'Visa' }
      & Pick<Visa, 'expiration' | 'type' | 'comments'>
    )>, passport?: Maybe<(
      { __typename: 'Passport' }
      & Pick<Passport, 'number' | 'issue' | 'expiration'>
    )>, files: Array<(
      { __typename: 'File' }
      & Pick<File, 'id' | 'name' | 'size' | 'type' | 'url' | 'uploadDate'>
    )>, dependents: Array<(
      { __typename: 'UserShortInfo' }
      & Pick<UserShortInfo, 'id' | 'firstName' | 'lastName' | 'middleName' | 'role' | 'type' | 'importantDocs' | 'currentStatus'>
      & { profile: (
        { __typename: 'UserProfile' }
        & Pick<UserProfile, 'image_url'>
      ), i94?: Maybe<(
        { __typename: 'I94' }
        & Pick<I94, 'number' | 'dateOfEntry' | 'classOfAdmission' | 'expiration'>
      )>, ead?: Maybe<(
        { __typename: 'EAD' }
        & Pick<Ead, 'expiration' | 'comments' | 'filed'>
      )>, visa?: Maybe<(
        { __typename: 'Visa' }
        & Pick<Visa, 'expiration' | 'type' | 'comments'>
      )>, passport?: Maybe<(
        { __typename: 'Passport' }
        & Pick<Passport, 'number' | 'issue' | 'expiration'>
      )>, advancedParole?: Maybe<(
        { __typename: 'AdvancedParole' }
        & Pick<AdvancedParole, 'expirationDate'>
      )> }
    )> }
  )> }
);

export type CasesQueryVariables = Exact<{
  userId: Scalars['String'];
  activeFilter?: Maybe<CaseActiveFilter>;
}>;


export type CasesQuery = (
  { __typename: 'Query' }
  & { cases: Array<(
    { __typename: 'Case' }
    & Pick<Case, 'id' | 'matterCaseId' | 'name' | 'initiationDate' | 'approvalDate' | 'completionDate' | 'note' | 'status'>
    & { milestones: Array<(
      { __typename: 'CaseMilestone' }
      & Pick<CaseMilestone, 'name' | 'completedDate'>
    )>, files?: Maybe<Array<(
      { __typename: 'File' }
      & Pick<File, 'id' | 'name' | 'size' | 'type' | 'uploadDate' | 'url'>
    )>> }
  )> }
);

export type GreenCardsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GreenCardsQuery = (
  { __typename: 'Query' }
  & { greencards: Array<(
    { __typename: 'GreenCard' }
    & Pick<GreenCard, 'status' | 'priorityDate' | 'note' | 'category' | 'country'>
    & { milestones: Array<(
      { __typename: 'GreenCardMilestone' }
      & Pick<GreenCardMilestone, 'name' | 'completedDate' | 'caseId' | 'isImportant' | 'description'>
    )>, cases: Array<(
      { __typename: 'Case' }
      & Pick<Case, 'id' | 'matterCaseId' | 'name' | 'initiationDate' | 'approvalDate' | 'completionDate' | 'note' | 'status'>
      & { milestones: Array<(
        { __typename: 'CaseMilestone' }
        & Pick<CaseMilestone, 'name' | 'completedDate'>
      )>, files?: Maybe<Array<(
        { __typename: 'File' }
        & Pick<File, 'id' | 'name' | 'size' | 'type' | 'uploadDate' | 'url'>
      )>> }
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  userId: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type UpdatePasswordMutationVariables = Exact<{
  userId: Scalars['String'];
  password: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type UpdatePasswordMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'updatePassword'>
);

export type LoginMutationVariables = Exact<{
  login: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename: 'Mutation' }
  & { login: (
    { __typename: 'LoginSuccessResponse' }
    & Pick<LoginSuccessResponse, 'id' | 'accessToken' | 'employeeId' | 'shouldRegisterOTP' | 'shouldChangePassword' | 'mobilenum'>
  ) | (
    { __typename: 'LoginWrongCredentials' }
    & Pick<LoginWrongCredentials, 'success'>
  ) }
);

export type AuthCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthCheckQuery = (
  { __typename: 'Query' }
  & { authCheck: (
    { __typename: 'AuthCheckSuccessResponse' }
    & Pick<AuthCheckSuccessResponse, 'employeeId'>
  ) | { __typename: 'AuthCheckUnauthorizedResponse' } }
);

export type SendSmsMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type SendSmsMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'sendSms'>
);

export type CallMeMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type CallMeMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'callMe'>
);

export type VerifyOtpMutationVariables = Exact<{
  userId: Scalars['String'];
  otp: Scalars['String'];
}>;


export type VerifyOtpMutation = (
  { __typename: 'Mutation' }
  & { verifyOtp?: Maybe<(
    { __typename: 'VerifyOtpSuccessResponse' }
    & Pick<VerifyOtpSuccessResponse, 'accessToken' | 'refreshToken' | 'success'>
  ) | (
    { __typename: 'VerifyOtpWrongCodeResponse' }
    & Pick<VerifyOtpWrongCodeResponse, 'success'>
  )> }
);

export type RegisterOtpMutationVariables = Exact<{
  countryCode: Scalars['String'];
  mobilenum: Scalars['String'];
  userId: Scalars['String'];
}>;


export type RegisterOtpMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'registerOtp'>
);


export const GetUserDocument = gql`
    query GetUser($id: String!) {
  user(id: $id) {
    ... on Employee {
      user_id
      matterId
      firstName
      lastName
      middleName
      role
      profile {
        image_url
      }
      job {
        title
        company {
          name
          location {
            city
            state
          }
        }
      }
      currentStatus
      advancedParole {
        expirationDate
      }
      i94 {
        number
        dateOfEntry
        classOfAdmission
        expiration
      }
      ead {
        expiration
        comments
        filed
      }
      visa {
        expiration
        type
        comments
      }
      passport {
        number
        issue
        expiration
      }
      files {
        id
        name
        size
        type
        url
        uploadDate
      }
      dependents {
        id
        firstName
        lastName
        middleName
        role
        type
        profile {
          image_url
        }
        importantDocs
        currentStatus
        advancedParole {
          expirationDate
        }
        i94 {
          number
          dateOfEntry
          classOfAdmission
          expiration
        }
        ead {
          expiration
          comments
          filed
        }
        visa {
          expiration
          type
          comments
        }
        passport {
          number
          issue
          expiration
        }
      }
    }
    ... on Dependent {
      user_id
      matterId
      firstName
      lastName
      middleName
      role
      profile {
        image_url
      }
      currentStatus
      advancedParole {
        expirationDate
      }
      i94 {
        number
        dateOfEntry
        classOfAdmission
        expiration
      }
      ead {
        expiration
        comments
        filed
      }
      visa {
        expiration
        type
        comments
      }
      passport {
        number
        issue
        expiration
      }
      files {
        id
        name
        size
        type
        url
        uploadDate
      }
      dependents {
        id
        firstName
        lastName
        middleName
        role
        type
        profile {
          image_url
        }
        importantDocs
        currentStatus
        i94 {
          number
          dateOfEntry
          classOfAdmission
          expiration
        }
        ead {
          expiration
          comments
          filed
        }
        visa {
          expiration
          type
          comments
        }
        passport {
          number
          issue
          expiration
        }
        advancedParole {
          expirationDate
        }
      }
    }
  }
}
    `;
export type GetUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserQuery, GetUserQueryVariables>, 'query'> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserComponent = (props: GetUserComponentProps) => (
      <ApolloReactComponents.Query<GetUserQuery, GetUserQueryVariables> query={GetUserDocument} {...props} />
    );
    

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CasesDocument = gql`
    query Cases($userId: String!, $activeFilter: CaseActiveFilter) {
  cases(userId: $userId, activeFilter: $activeFilter) {
    id
    matterCaseId
    name
    initiationDate
    approvalDate
    completionDate
    note
    status
    milestones {
      name
      completedDate
    }
    files {
      id
      name
      size
      type
      uploadDate
      url
    }
  }
}
    `;
export type CasesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CasesQuery, CasesQueryVariables>, 'query'> & ({ variables: CasesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CasesComponent = (props: CasesComponentProps) => (
      <ApolloReactComponents.Query<CasesQuery, CasesQueryVariables> query={CasesDocument} {...props} />
    );
    

/**
 * __useCasesQuery__
 *
 * To run a query within a React component, call `useCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      activeFilter: // value for 'activeFilter'
 *   },
 * });
 */
export function useCasesQuery(baseOptions: Apollo.QueryHookOptions<CasesQuery, CasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CasesQuery, CasesQueryVariables>(CasesDocument, options);
      }
export function useCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CasesQuery, CasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CasesQuery, CasesQueryVariables>(CasesDocument, options);
        }
export type CasesQueryHookResult = ReturnType<typeof useCasesQuery>;
export type CasesLazyQueryHookResult = ReturnType<typeof useCasesLazyQuery>;
export type CasesQueryResult = Apollo.QueryResult<CasesQuery, CasesQueryVariables>;
export const GreenCardsDocument = gql`
    query GreenCards($userId: String!) {
  greencards(userId: $userId) {
    status
    priorityDate
    note
    category
    country
    milestones {
      name
      completedDate
      caseId
      isImportant
      description
    }
    cases {
      id
      matterCaseId
      name
      initiationDate
      approvalDate
      completionDate
      note
      status
      milestones {
        name
        completedDate
      }
      files {
        id
        name
        size
        type
        uploadDate
        url
      }
    }
  }
}
    `;
export type GreenCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GreenCardsQuery, GreenCardsQueryVariables>, 'query'> & ({ variables: GreenCardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GreenCardsComponent = (props: GreenCardsComponentProps) => (
      <ApolloReactComponents.Query<GreenCardsQuery, GreenCardsQueryVariables> query={GreenCardsDocument} {...props} />
    );
    

/**
 * __useGreenCardsQuery__
 *
 * To run a query within a React component, call `useGreenCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGreenCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGreenCardsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGreenCardsQuery(baseOptions: Apollo.QueryHookOptions<GreenCardsQuery, GreenCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GreenCardsQuery, GreenCardsQueryVariables>(GreenCardsDocument, options);
      }
export function useGreenCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GreenCardsQuery, GreenCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GreenCardsQuery, GreenCardsQueryVariables>(GreenCardsDocument, options);
        }
export type GreenCardsQueryHookResult = ReturnType<typeof useGreenCardsQuery>;
export type GreenCardsLazyQueryHookResult = ReturnType<typeof useGreenCardsLazyQuery>;
export type GreenCardsQueryResult = Apollo.QueryResult<GreenCardsQuery, GreenCardsQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($userId: String!, $newPassword: String!) {
  changePassword(userId: $userId, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($userId: String!, $password: String!, $newPassword: String!) {
  updatePassword(userId: $userId, password: $password, newPassword: $newPassword)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export type UpdatePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>, 'mutation'>;

    export const UpdatePasswordComponent = (props: UpdatePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> mutation={UpdatePasswordDocument} {...props} />
    );
    

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($login: String!, $password: String!) {
  login(login: $login, password: $password) {
    __typename
    ... on LoginSuccessResponse {
      id
      accessToken
      employeeId
      shouldRegisterOTP
      shouldChangePassword
      mobilenum
    }
    ... on LoginWrongCredentials {
      success
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const AuthCheckDocument = gql`
    query AuthCheck {
  authCheck {
    ... on AuthCheckSuccessResponse {
      employeeId
    }
  }
}
    `;
export type AuthCheckComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthCheckQuery, AuthCheckQueryVariables>, 'query'>;

    export const AuthCheckComponent = (props: AuthCheckComponentProps) => (
      <ApolloReactComponents.Query<AuthCheckQuery, AuthCheckQueryVariables> query={AuthCheckDocument} {...props} />
    );
    

/**
 * __useAuthCheckQuery__
 *
 * To run a query within a React component, call `useAuthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthCheckQuery(baseOptions?: Apollo.QueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, options);
      }
export function useAuthCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthCheckQuery, AuthCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthCheckQuery, AuthCheckQueryVariables>(AuthCheckDocument, options);
        }
export type AuthCheckQueryHookResult = ReturnType<typeof useAuthCheckQuery>;
export type AuthCheckLazyQueryHookResult = ReturnType<typeof useAuthCheckLazyQuery>;
export type AuthCheckQueryResult = Apollo.QueryResult<AuthCheckQuery, AuthCheckQueryVariables>;
export const SendSmsDocument = gql`
    mutation SendSms($userId: String!) {
  sendSms(userId: $userId)
}
    `;
export type SendSmsMutationFn = Apollo.MutationFunction<SendSmsMutation, SendSmsMutationVariables>;
export type SendSmsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendSmsMutation, SendSmsMutationVariables>, 'mutation'>;

    export const SendSmsComponent = (props: SendSmsComponentProps) => (
      <ApolloReactComponents.Mutation<SendSmsMutation, SendSmsMutationVariables> mutation={SendSmsDocument} {...props} />
    );
    

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsMutation, SendSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, options);
      }
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>;
export type SendSmsMutationResult = Apollo.MutationResult<SendSmsMutation>;
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<SendSmsMutation, SendSmsMutationVariables>;
export const CallMeDocument = gql`
    mutation CallMe($userId: String!) {
  callMe(userId: $userId)
}
    `;
export type CallMeMutationFn = Apollo.MutationFunction<CallMeMutation, CallMeMutationVariables>;
export type CallMeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CallMeMutation, CallMeMutationVariables>, 'mutation'>;

    export const CallMeComponent = (props: CallMeComponentProps) => (
      <ApolloReactComponents.Mutation<CallMeMutation, CallMeMutationVariables> mutation={CallMeDocument} {...props} />
    );
    

/**
 * __useCallMeMutation__
 *
 * To run a mutation, you first call `useCallMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callMeMutation, { data, loading, error }] = useCallMeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCallMeMutation(baseOptions?: Apollo.MutationHookOptions<CallMeMutation, CallMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallMeMutation, CallMeMutationVariables>(CallMeDocument, options);
      }
export type CallMeMutationHookResult = ReturnType<typeof useCallMeMutation>;
export type CallMeMutationResult = Apollo.MutationResult<CallMeMutation>;
export type CallMeMutationOptions = Apollo.BaseMutationOptions<CallMeMutation, CallMeMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOtp($userId: String!, $otp: String!) {
  verifyOtp(userId: $userId, otp: $otp) {
    ... on VerifyOtpSuccessResponse {
      accessToken
      refreshToken
      success
    }
    ... on VerifyOtpWrongCodeResponse {
      success
    }
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;
export type VerifyOtpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyOtpMutation, VerifyOtpMutationVariables>, 'mutation'>;

    export const VerifyOtpComponent = (props: VerifyOtpComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyOtpMutation, VerifyOtpMutationVariables> mutation={VerifyOtpDocument} {...props} />
    );
    

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const RegisterOtpDocument = gql`
    mutation RegisterOtp($countryCode: String!, $mobilenum: String!, $userId: String!) {
  registerOtp(countryCode: $countryCode, mobilenum: $mobilenum, userId: $userId)
}
    `;
export type RegisterOtpMutationFn = Apollo.MutationFunction<RegisterOtpMutation, RegisterOtpMutationVariables>;
export type RegisterOtpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterOtpMutation, RegisterOtpMutationVariables>, 'mutation'>;

    export const RegisterOtpComponent = (props: RegisterOtpComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterOtpMutation, RegisterOtpMutationVariables> mutation={RegisterOtpDocument} {...props} />
    );
    

/**
 * __useRegisterOtpMutation__
 *
 * To run a mutation, you first call `useRegisterOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerOtpMutation, { data, loading, error }] = useRegisterOtpMutation({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      mobilenum: // value for 'mobilenum'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRegisterOtpMutation(baseOptions?: Apollo.MutationHookOptions<RegisterOtpMutation, RegisterOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterOtpMutation, RegisterOtpMutationVariables>(RegisterOtpDocument, options);
      }
export type RegisterOtpMutationHookResult = ReturnType<typeof useRegisterOtpMutation>;
export type RegisterOtpMutationResult = Apollo.MutationResult<RegisterOtpMutation>;
export type RegisterOtpMutationOptions = Apollo.BaseMutationOptions<RegisterOtpMutation, RegisterOtpMutationVariables>;