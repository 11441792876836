import styled from 'styled-components'

export type DocumentRootProps = {
  isExpired: boolean
  isParole: boolean
}

export const DocumentRoot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 13px 13px 13px;
  background: ${(p: DocumentRootProps) =>
    p.isExpired || p.isParole ? 'transparent' : '#edf1fa'};
  border-radius: 15px;
  border: 1px solid
    ${(p: DocumentRootProps) =>
      p.isParole ? '#585B78' : p.isExpired ? '#8F95A4' : 'transparent'};
  cursor: ${(p: DocumentRootProps) => (p.isParole ? 'initial' : 'pointer')};
`

export const DocumentName = styled.div`
  font-size: 18px;
  line-height: 1.3;
  vertical-align: bottom;
  word-break: break-all;
  color: ${(p: DocumentRootProps) => (p.isExpired ? '#8F95A4' : '#2F3778')};
`

export const DocumentBoldName = styled.span`
  font-weight: bold;
`

export const DocumentExpires = styled.div`
  font-size: 12px;
  line-height: 1;
  color: ${(p: DocumentRootProps) => (p.isExpired ? '#8F95A4' : '#585b78')};
`

export const DocumentIconWrap = styled.div`
  width: 32px;
  margin-right: 10px;
  flex-shrink: 0;
  /* ${(p: DocumentRootProps) =>
    !p.isExpired ? 'fill: #8F95A4 !important;' : ''} */
`

export const DocumentRightSectionWrap = styled.div`
  flex-grow: 1;
`

export const DocumentNameWrap = styled.div`
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const DocumentChipWrap = styled.div`
  position: absolute;
  right: -1px;
  top: -1px;
`
