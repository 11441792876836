import * as React from 'react'
import { useLayer, useHover, Arrow } from 'react-laag'
import { TooltipRoot } from './Tooltip.styled'

export type TooltipProps = {
  children: React.ReactElement
  title: React.ReactNode
}

export function Tooltip({ children, title }: TooltipProps) {
  // we use this hook to determine if the user is hovering
  // the button in this case
  const [isOpen, hoverProps] = useHover({ delayEnter: 150, delayLeave: 150 })

  // we use this hook for positioning our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    auto: true,
    triggerOffset: 5,
    containerOffset: 5,
  })

  // we make sure the props provided by the hooks, get passed to the button
  // by cloning the button-element passed in as a child
  return (
    <>
      {React.cloneElement(children, { ...triggerProps, ...hoverProps })}
      {isOpen &&
        renderLayer(
          <TooltipRoot {...layerProps} {...hoverProps}>
            {title} <Arrow {...arrowProps} />
          </TooltipRoot>,
        )}
    </>
  )
}
