import React from 'react'
import { Color } from '../../../types/color'
import { DotMiddleInner, DotMiddleRoot } from './DotMiddle.styled'

export type DotMiddleProps = {
  color: Color
}

export const DotMiddle = ({ color }: DotMiddleProps) => {
  return (
    <DotMiddleRoot color={color}>
      <DotMiddleInner />
    </DotMiddleRoot>
  )
}
