import React from 'react'
import { UserPanel } from '../../organisms/UserPanel'
import { ContentWrap } from '../../../App/App.styled'
import { MainScreen } from '../../organisms/MainScreen'
import { ProfileHeaderMobile } from '../../molecules/ProfileHeaderMobile'
import { useAuth } from '../LoginPage/login.store'

export type MainPageProps = {
  onClickChangePassword: () => void
}

export const MainPage = ({ onClickChangePassword }: MainPageProps) => {
  const auth = useAuth()

  if (!auth.isAuth) {
    return <>Could not load data</>
  }

  return (
    <>
      <ProfileHeaderMobile
        userId={auth.employeeId}
        onClickChangePassword={onClickChangePassword}
      />
      <UserPanel
        userId={auth.employeeId}
        onClickChangePassword={onClickChangePassword}
      />
      <ContentWrap>
        <MainScreen userId={auth.employeeId} />
      </ContentWrap>
    </>
  )
}
