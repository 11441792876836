import React from 'react'
import { User } from '../../molecules/User'
import { Drawer } from '../../molecules/Drawer/Drawer'
import { CloseButton } from '../../atoms/CloseButton'
import {
  UserDetailsBtnRow,
  UserDetailsDocsWrap,
  UserDetailsRoot,
  UserDetailsSettingsBtnWrap,
} from './UserDetails.styled'
import { IconButton } from '../../atoms/IconButton'
import { ExitIcon } from '../../icons/ExitIcon'
import { DocumentsList } from '../DocumentsList'
import { EmployeeOrDependent } from '../../../api/generated'
import { useLoginStore } from '../../pages/LoginPage/login.store'
import { isNotNullOrUndefined } from '../../../utils/isNotNullOrUndefined'
import { CurrentStatus } from '../../atoms/CurrentStatus'
import { SettingsButton } from '../../atoms/SettingsBtn'

export interface Props {
  user: EmployeeOrDependent
  open: boolean
  onClose: () => void
  onClickChangePassword: () => void
  isDependent?: boolean
}

export function UserDetails({
  open,
  onClose,
  user,
  onClickChangePassword,
  isDependent,
}: Props) {
  const { logout } = useLoginStore()

  const {
    firstName,
    lastName,
    middleName,
    profile,
    visa,
    passport,
    i94,
    ead,
    user_id,
    advancedParole,
    currentStatus,
  } = user
  const { image_url } = profile

  const name = [firstName, lastName, middleName].join(' ')

  const onLogoutClick = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Do you really want to leave?')) {
      logout()
    }
  }

  return (
    <Drawer open={open} onClose={onClose}>
      <UserDetailsRoot>
        <UserDetailsBtnRow>
          <CloseButton onClick={onClose} />

          {!isDependent && (
            <>
              <UserDetailsSettingsBtnWrap>
                <SettingsButton onClickChangePassword={onClickChangePassword} />
              </UserDetailsSettingsBtnWrap>

              <IconButton onClick={onLogoutClick}>
                <ExitIcon />
              </IconButton>
            </>
          )}
        </UserDetailsBtnRow>
        <User
          isDependent={isDependent}
          user_id={user_id}
          avatarUrl={image_url}
          name={name}
          jobText={
            user.__typename === 'Employee'
              ? `${user.job.title} ${user.job.company.name}`
              : ''
          }
          locationText={
            user.__typename === 'Employee'
              ? `${user.job.company.location.city} ${user.job.company.location.state}`
              : ''
          }
        />

        <UserDetailsDocsWrap>
          {currentStatus && (
            <div style={{ marginBottom: '20px' }}>
              <CurrentStatus text={currentStatus} />
            </div>
          )}

          <DocumentsList
            list={
              [
                {
                  name: '',
                  expires: advancedParole?.expirationDate,
                  type: 'parole',
                  typeName: 'Advance Parole',
                },
                {
                  name: '',
                  expires: i94?.expiration,
                  type: 'i94',
                  typeName: 'I-94',
                  isExtension: true,
                },
                {
                  name: '',
                  expires: ead?.expiration,
                  type: 'ead',
                  typeName: 'EAD',
                },
                {
                  name: visa?.type,
                  expires: visa?.expiration,
                  type: 'visa',
                  typeName: 'Visa',
                },
                {
                  name: passport?.number,
                  expires: passport?.expiration,
                  type: 'passport',
                  typeName: 'Passport',
                },
              ].filter(({ expires }) => isNotNullOrUndefined(expires)) as any[]
            }
          />
        </UserDetailsDocsWrap>
      </UserDetailsRoot>
    </Drawer>
  )
}
