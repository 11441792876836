import React from 'react'
import { getIcColor } from './colors'
import { DocSvgProps } from './types'

export const I94 = ({ isGray }: DocSvgProps) => {
  return (
    <svg
      width='53'
      height='53'
      viewBox='0 0 53 53'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M46.0645 47.1044C46.4933 47.1044 46.8408 46.7568 46.8408 46.328V9.5752C46.8408 9.14643 46.4933 8.79883 46.0645 8.79883H43.7354V5.17578C43.7354 4.74702 43.3878 4.39941 42.959 4.39941H40.6297V0.776367C40.6297 0.347605 40.2822 0 39.8533 0H6.93555C6.50668 0 6.15918 0.347605 6.15918 0.776367V3.29935C6.15918 3.72812 6.50668 4.07572 6.93555 4.07572C7.36441 4.07572 7.71191 3.72812 7.71191 3.29935V1.55273H39.0769V42.6484H7.71191V6.67676C7.71191 6.248 7.36441 5.90039 6.93555 5.90039C6.50668 5.90039 6.15918 6.248 6.15918 6.67676V43.4248C6.15918 43.8536 6.50668 44.2012 6.93555 44.2012H9.26486V47.8242C9.26486 48.253 9.61236 48.6006 10.0412 48.6006H12.3703V52.2236C12.3703 52.6524 12.7178 53 13.1467 53H46.0645C46.4933 53 46.8408 52.6524 46.8408 52.2236V49.6574C46.8408 49.2286 46.4933 48.881 46.0645 48.881C45.6356 48.881 45.2881 49.2286 45.2881 49.6574V51.4473H13.9231V48.6006H17.4629C17.8917 48.6006 18.2392 48.253 18.2392 47.8242C18.2392 47.3955 17.8917 47.0479 17.4629 47.0479H10.8176V44.2012H39.8533C40.2822 44.2012 40.6297 43.8536 40.6297 43.4248V5.95215H42.1826V47.0479H20.8043C20.3754 47.0479 20.0279 47.3955 20.0279 47.8242C20.0279 48.253 20.3754 48.6006 20.8043 48.6006H42.959C43.3878 48.6006 43.7354 48.253 43.7354 47.8242V10.3516H45.2881V46.328C45.2881 46.7568 45.6356 47.1044 46.0645 47.1044Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M34.403 12.8877V6.67676C34.403 6.248 34.0555 5.90039 33.6266 5.90039H13.1274C12.6986 5.90039 12.3511 6.248 12.3511 6.67676V12.8877C12.3511 13.3165 12.6986 13.6641 13.1274 13.6641H33.6265C34.0554 13.6641 34.403 13.3165 34.403 12.8877ZM32.8503 12.1113H13.9038V7.45312H32.8502V12.1113H32.8503Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M11.9386 32.6074C11.9386 33.0362 12.2861 33.3838 12.715 33.3838H33.625C34.0539 33.3838 34.4014 33.0362 34.4014 32.6074C34.4014 32.1787 34.0539 31.8311 33.625 31.8311H12.715C12.2861 31.8311 11.9386 32.1787 11.9386 32.6074Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M12.715 36.6963H33.625C34.0539 36.6963 34.4014 36.3487 34.4014 35.9199C34.4014 35.4912 34.0539 35.1436 33.625 35.1436H12.715C12.2861 35.1436 11.9386 35.4912 11.9386 35.9199C11.9386 36.3487 12.2861 36.6963 12.715 36.6963Z'
        fill={getIcColor(isGray)}
      />
      <path
        d='M17.3213 27.7422C17.3213 28.1709 17.6688 28.5186 18.0977 28.5186C18.5265 28.5186 18.874 28.1709 18.874 27.7422C18.874 25.3734 20.8012 23.4463 23.1699 23.4463C25.5387 23.4463 27.4658 25.3734 27.4658 27.7422C27.4658 28.1709 27.8133 28.5186 28.2422 28.5186C28.6711 28.5186 29.0186 28.1709 29.0186 27.7422C29.0186 25.4332 27.6733 23.4328 25.7256 22.4826C26.5376 21.7706 27.0518 20.7267 27.0518 19.5645C27.0518 17.424 25.3104 15.6826 23.1699 15.6826C21.0294 15.6826 19.2881 17.424 19.2881 19.5645C19.2881 20.7267 19.8022 21.7706 20.6142 22.4826C18.6666 23.4328 17.3213 25.4332 17.3213 27.7422ZM20.8408 19.5645C20.8408 18.2801 21.8857 17.2354 23.1699 17.2354C24.4541 17.2354 25.499 18.2801 25.499 19.5645C25.499 20.8488 24.4541 21.8936 23.1699 21.8936C21.8857 21.8936 20.8408 20.8488 20.8408 19.5645Z'
        fill={getIcColor(isGray)}
      />
    </svg>
  )
}
