import React from 'react'
import { Color } from '../../../types/color'
import { DotRoot } from './Dot.styled'

export type DotProps = {
  color: Color
}

export const Dot = ({ color }: DotProps) => {
  return <DotRoot color={color} />
}
