import styled from 'styled-components'

export const UserDetailsRoot = styled.div`
  padding-top: 17px;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

export const UserDetailsBtnRow = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 53px;
  padding: 14px 14px;
  position: absolute;
  display: flex;
  justify-content: space-between;
`

export const UserDetailsDocsWrap = styled.div`
  padding: 50px 15px;
`

export const UserDetailsSettingsBtnWrap = styled.div`
  margin-left: auto;
  margin-right: 20px;
`
