import React from 'react'
import { Image } from '../Image'
import { AvatarCameraCircle, AvatarRoot, AvatarWrap } from './Avatar.styled'
import defaultAvatar from './default-avatar.svg'
import { Camera } from '../../icons/Camera'

export type AvatarProps = {
  url: string | null | undefined
  onClick?: () => void
}

export const Avatar = ({ url, onClick }: AvatarProps) => {
  return (
    <AvatarWrap onClick={onClick} clickable={!!onClick}>
      <AvatarRoot>
        <Image src={url || defaultAvatar} placeHolderSrc={defaultAvatar} />
      </AvatarRoot>
      {onClick ? (
        <AvatarCameraCircle>
          <Camera />
        </AvatarCameraCircle>
      ) : null}
    </AvatarWrap>
  )
}
