import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const FieldsTableRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #f2f3f5;
`

export const FieldsTableCell = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  min-width: 30%;
  padding: 14px 35px;
  border-top: 1px solid #f2f3f5;
  border-right: 1px solid #f2f3f5;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
