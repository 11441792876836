import React from 'react'

export const Clock = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7' cy='7' r='6' stroke='white' strokeWidth='2' />
      <path
        d='M7.00006 4L7.00007 7.46156L9.00005 8.99999'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
