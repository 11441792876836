import React from 'react'
import logo from './brand-logo.png'
import { BrandLogoImg } from './BrandLogo.styled'

export interface Props {}

function BrandLogo(props: Props) {
  return <BrandLogoImg src={logo} />
}

export default BrandLogo
