import React from 'react'
import { Image } from '../../atoms/Image'
import {
  UserSmallAvatarWrap,
  UserSmallName,
  UserSmallRoot,
} from './UserSmall.styled'
import defaultAvatar from '../../atoms/Avatar/default-avatar.svg'

export type UserSmallProps = {
  avatarUrl: string | null | undefined
  name: string
}

export const UserSmall = ({ avatarUrl, name }: UserSmallProps) => {
  return (
    <UserSmallRoot>
      <UserSmallAvatarWrap>
        <Image
          src={avatarUrl || defaultAvatar}
          placeHolderSrc={defaultAvatar}
        />
      </UserSmallAvatarWrap>
      <UserSmallName>{name}</UserSmallName>
    </UserSmallRoot>
  )
}
