import { CaseMilestone } from '../api/generated'

export function sortMilestones<T extends Pick<CaseMilestone, 'completedDate'>>(
  list: T[],
): T[] {
  const start: T[] = []
  const end: T[] = []

  list.forEach(item => {
    if (!item.completedDate) {
      end.push(item)
    } else {
      start.push(item)
    }
  })

  start.sort((a, b) => {
    return a.completedDate!.getTime() - b.completedDate!.getTime()
  })

  return [...start, ...end]
}
