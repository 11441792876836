import React from 'react'
import { useHistory } from 'react-router-dom'
import { gql } from '@apollo/client'
import { CardListTmpl } from '../../templates/CardListTmpl'
import { GreenCard } from '../../organisms/GreenCard'
import { Case } from '../../molecules/Case'
import {
  SectionTmpl,
  SectionTmplPlaceholderText,
} from '../../templates/SectionTmpl'
import { FileList } from '../../organisms/FileList'
import { Dependent } from '../../organisms/Dependent'
import { StyledLink } from '../../atoms/StyledLink/StyledLink'
import { useDetailsRouter } from '../DetailsPanel/useDetailsRouter'
import {
  Case as CaseType,
  CaseActiveFilter,
  CaseStatus,
  useCasesQuery,
  useGetUserQuery,
  useGreenCardsQuery,
} from '../../../api/generated'
import { getGqlErrorMessage } from '../../../api/client'
import { sortCasesList } from '../../../utils/sortCasesList'

export const GET_CASES_QUERY = gql`
  query Cases($userId: String!, $activeFilter: CaseActiveFilter) {
    cases(userId: $userId, activeFilter: $activeFilter) {
      id
      matterCaseId
      name
      initiationDate
      approvalDate
      completionDate
      note
      status
      milestones {
        name
        completedDate
      }
      files {
        id
        name
        size
        type
        uploadDate
        url
      }
    }
  }
`

export const GET_GREENCARDS_QUERY = gql`
  query GreenCards($userId: String!) {
    greencards(userId: $userId) {
      status
      priorityDate
      note
      category
      country
      milestones {
        name
        completedDate
        caseId
        isImportant
        description
      }
      cases {
        id
        matterCaseId
        name
        initiationDate
        approvalDate
        completionDate
        note
        status
        milestones {
          name
          completedDate
        }
        files {
          id
          name
          size
          type
          uploadDate
          url
        }
      }
    }
  }
`

export type MainScreenProps = {
  userId: string
  isDependentPage?: boolean
}

export const MainScreen = ({ userId, isDependentPage }: MainScreenProps) => {
  // TODO move router logic to top level
  const history = useHistory()
  const detailsRouter = useDetailsRouter()

  const { data: casesData, error: casesError } = useCasesQuery({
    variables: { userId, activeFilter: CaseActiveFilter.Active },
    fetchPolicy: 'network-only',
    pollInterval: 1000 * 30,
  })

  const { data: greencardsData } = useGreenCardsQuery({
    variables: { userId },
  })

  const { data: userData } = useGetUserQuery({
    variables: { id: userId },
  })

  const files = userData?.user?.files
  const dependents = userData?.user?.dependents

  let casesSection: React.ReactNode = null
  if (casesError) {
    casesSection = (
      <div>
        <p>Could not load cases data, api error:</p>
        <pre>
          {getGqlErrorMessage(casesError.graphQLErrors) || casesError.message}
        </pre>
        {/* <p>path: {casesError.}</p> */}
      </div>
    )
  } else if (casesData?.cases) {
    const { cases } = casesData

    casesSection = (
      <SectionTmpl
        title='Current Cases'
        divider={cases.length === 0}
        titleSecondary={
          cases.length > 0 ? (
            <StyledLink
              to={
                isDependentPage
                  ? `/user/${userId}/inactive-cases`
                  : '/inactive-cases'
              }
              // eslint-disable-next-line react/forbid-component-props
              style={{ fontWeight: 600 }}
            >
              Show Inactive Cases
            </StyledLink>
          ) : null
        }
      >
        {cases.length > 0 ? (
          <CardListTmpl
            itemsJSX={sortCasesList(cases).map(c => (
              <Case
                caseData={c}
                key={c.id}
                color='blue'
                onClickDetails={() => {
                  detailsRouter.goToCaseDetails({ caseId: c.id })
                }}
              />
            ))}
          />
        ) : (
          <div>
            <SectionTmplPlaceholderText>
              <span>No current cases</span>{' '}
              <StyledLink
                to={
                  isDependentPage
                    ? `/user/${userId}/inactive-cases`
                    : '/inactive-cases'
                }
                // eslint-disable-next-line react/forbid-component-props
                style={{ fontWeight: 600 }}
              >
                Show Inactive Cases
              </StyledLink>
            </SectionTmplPlaceholderText>
          </div>
        )}
      </SectionTmpl>
    )
  }

  return (
    <>
      {casesSection}

      <SectionTmpl
        title='Green Card Status'
        divider={greencardsData?.greencards.length === 0}
        titleSecondary={
          greencardsData?.greencards &&
          greencardsData.greencards.length > 0 && (
            <StyledLink
              to={
                isDependentPage
                  ? `/user/${userId}/g-inactive-cases`
                  : '/g-inactive-cases'
              }
              // eslint-disable-next-line react/forbid-component-props
              style={{ fontWeight: 600 }}
            >
              Show Inactive Cases
            </StyledLink>
          )
        }
      >
        {greencardsData?.greencards && greencardsData.greencards.length > 0 ? (
          greencardsData?.greencards.map(data => (
            <div style={{ marginBottom: '20px' }} key={data.category}>
              <GreenCard
                key={data.category}
                greencard={data as any}
                onClickDetails={() => {
                  detailsRouter.goToGreenCardDetails({
                    category: data.category,
                  })
                }}
              />
              <CardListTmpl
                itemsJSX={activeCases(data.cases as any).map(c => (
                  <Case
                    {...c}
                    key={c.id}
                    color='green'
                    caseData={c}
                    onClickDetails={() => {
                      detailsRouter.goToGreenCardCaseDetails({ caseId: c.id })
                    }}
                  />
                ))}
              />
            </div>
          ))
        ) : (
          <div>
            <SectionTmplPlaceholderText>
              No active Green Card cases
            </SectionTmplPlaceholderText>
          </div>
        )}
      </SectionTmpl>

      {dependents && dependents.length > 0 && !isDependentPage && (
        <SectionTmpl
          title={
            userData?.user?.role === 'principal' ? 'Dependents' : 'Relatives'
          }
        >
          <CardListTmpl
            itemsJSX={dependents.map(d => (
              <Dependent
                key={d.id}
                data={d}
                onClickDetails={() => {
                  history.push(`/user/${d.id}`) // TODO user id and check current session userId
                }}
              />
            ))}
          />
        </SectionTmpl>
      )}

      {files && files.length > 0 && (
        <SectionTmpl title='Documents'>
          <FileList
            items={files.map(f => ({
              addedDate: f.uploadDate,
              downloadUrl: f.url,
              title: f.name,
              fileType: f.type,
            }))}
          />
        </SectionTmpl>
      )}
    </>
  )
}

function activeCases(list: CaseType[]): CaseType[] {
  return list.filter(c => c.status === CaseStatus.InProgress)
}
