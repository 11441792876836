import React from 'react'
import {
  VariantDescription,
  VariantIconWrap,
  VariantRoot,
  VariantTextBlock,
  VariantTitle,
} from './Variant.styled'

export type VariantProps = {
  title: string
  description: string
  icon: React.ReactNode
  onClick: () => void
}

export const Variant = ({
  description,
  icon,
  title,
  onClick,
}: VariantProps) => {
  return (
    <VariantRoot onClick={onClick}>
      <VariantIconWrap>{icon}</VariantIconWrap>
      <VariantTextBlock>
        <VariantTitle>{title}</VariantTitle>
        <VariantDescription>{description}</VariantDescription>
      </VariantTextBlock>
    </VariantRoot>
  )
}
