import React from 'react'
import {
  GCardBottomWrap,
  GCardButtonWrap,
  GCardFieldWrap,
  GCardProgressWrap,
  GCardRoot,
  GCardTopWrap,
} from './GreenCard.styled'
import { HProgress } from '../../molecules/HProgress'
import { ActiveCaseName } from '../../molecules/Case/Case.styled'
import { DotMiddle } from '../../atoms/DotMiddle'
import { CardField } from '../../atoms/CardField'
import { CaseDetailsBtn } from '../../atoms/CaseDetailsBtn'
import { PointTextTmpl } from '../../templates/PointTextTmpl'
import { GreenCard as GreenCardData } from '../../../api/generated'
import { formatDate } from '../../../utils/formatDate'

export type GreenCardProps = {
  greencard: GreenCardData
  onClickDetails?: () => void
}

export const GreenCard = ({ onClickDetails, greencard }: GreenCardProps) => {
  const { priorityDate, category, milestones, status } = greencard

  let activeIdx = 0
  for (let i = 0; i < milestones.length; i++) {
    const m = milestones[i]
    if (!m.completedDate) {
      activeIdx = i - 1
      break
    }
  }

  return (
    <GCardRoot>
      <GCardTopWrap>
        <GCardProgressWrap>
          <HProgress
            color='green'
            points={milestones.map(m => ({
              title: m.name,
              desc: m.completedDate ? formatDate(m.completedDate) : '',
              important: m.isImportant,
              mainDesc: m.description,
            }))}
            activePointIdx={activeIdx}
            hover
          />
        </GCardProgressWrap>
        <PointTextTmpl
          point={<DotMiddle color='green' />}
          text={<ActiveCaseName>{status}</ActiveCaseName>}
        />
      </GCardTopWrap>
      <GCardBottomWrap>
        <GCardFieldWrap>
          <CardField>
            Priority Date: {priorityDate ? formatDate(priorityDate) : null}
          </CardField>
        </GCardFieldWrap>

        <GCardFieldWrap>
          <CardField>Class: {category}</CardField>
        </GCardFieldWrap>

        <GCardButtonWrap>
          <CaseDetailsBtn onClick={onClickDetails} />
        </GCardButtonWrap>
      </GCardBottomWrap>
    </GCardRoot>
  )
}
