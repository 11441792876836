import { GraphQLScalarType, Kind } from 'graphql'

export const dateScalar = new GraphQLScalarType({
  name: 'DateTime',
  description: 'Date custom scalar type',
  serialize(value) {
    return value
  },
  parseValue(value) {
    return value
  },
  parseLiteral({ kind, value }: any) {
    if (kind === Kind.INT) {
      return new Date(parseInt(value.value, 10)) // Convert hard-coded AST string to integer and then to Date
    }
    return null // Invalid hard-coded value (not an integer)
  },
})

export const dateScalarClient = new GraphQLScalarType({
  name: 'DateTime',
  description: 'Date custom scalar type',
  serialize(value) {
    return value.getTime()
  },
  parseValue(value) {
    if (value === '') {
      return null
    }

    const timestamp = Date.parse(value)
    if (isNaN(timestamp)) {
      throw new Error('Date format is not valid')
    }
    return new Date(timestamp)
  },
  parseLiteral({ kind, value }: any) {
    if (kind === Kind.INT) {
      return new Date(parseInt(value.value, 10)) // Convert hard-coded AST string to integer and then to Date
    }
    return null // Invalid hard-coded value (not an integer)
  },
})
