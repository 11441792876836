import React, { useEffect, useState } from 'react'

export type ImageProps = React.HTMLAttributes<HTMLImageElement> & {
  src: string
  placeHolderSrc: string
}

export const Image = ({ src, placeHolderSrc, ...props }: ImageProps) => {
  const [nextSrc, setNextSrc] = useState(src)

  useEffect(() => {
    setNextSrc(src)
  }, [src])

  return (
    <img
      src={nextSrc}
      onError={() => {
        setNextSrc(placeHolderSrc)
      }}
    />
  )
}
