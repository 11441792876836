import React from 'react'
import { formatDate } from '../../../utils/formatDate'
import {
  FileCardDesc,
  FileCardIconImg,
  FileCardIconWrap,
  FileCardRoot,
  FileCardText,
  FileCardTitle,
} from './FileCard.styled'
import { getIconByType } from './iconsMap'

export type FileCardProps = {
  title: string
  addedDate: Date
  fileType: string
  downloadUrl: string
}

export const FileCard = ({
  addedDate,
  title,
  fileType,
  downloadUrl,
}: FileCardProps) => {
  return (
    <FileCardRoot href={downloadUrl} target='_blank'>
      <FileCardIconWrap>
        <FileCardIconImg src={getIconByType(fileType)} />
      </FileCardIconWrap>

      <FileCardText>
        <FileCardTitle>{title}</FileCardTitle>
        <FileCardDesc>Added: {formatDate(addedDate)}</FileCardDesc>
      </FileCardText>
    </FileCardRoot>
  )
}
