import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

const BorderRadius = '15px'

export const GCardRoot = styled.div`
  margin-bottom: 20px;
  box-shadow: 10px 10px 20px rgba(47, 55, 120, 0.1);
  border-radius: ${BorderRadius};

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 15px;
  }
`

export const GCardTopWrap = styled.div`
  padding: 24px;
  padding-bottom: 15px;
  background-color: #f5f8fd;
  border-top-left-radius: ${BorderRadius};
  border-top-right-radius: ${BorderRadius};

  @media (max-width: ${breakpoints.sm}px) {
    padding: 15px;
  }
`

export const GCardBottomWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fff;
  border-bottom-left-radius: ${BorderRadius};
  border-bottom-right-radius: ${BorderRadius};

  @media (max-width: ${breakpoints.sm}px) {
    padding: 14px;
    padding-top: 10px;
    padding-bottom: 12px;
  }
`

export const GCardProgressWrap = styled.div`
  padding: 13px 0;
  margin-bottom: 15px;
`

export const GCardFieldWrap = styled.div`
  margin-right: 25px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-right: 15px;
  }
`

export const GCardButtonWrap = styled.div`
  margin-left: auto;
`
