import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const DetailsSecondaryTmplRoot = styled.div`
  background: #f5f8fd;
  flex-grow: 1;
`

export const DetailsSecondaryTmplContent = styled.div`
  padding: 32px 35px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const DetailsSecondaryTmplFooter = styled.div`
  border-top: 1px solid #ced4e1;
  padding: 23px 35px;

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
