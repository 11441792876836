import styled from 'styled-components'

export const DocumentIconRoot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  & > svg {
    width: 100%;
    height: auto;
  }
`
