import styled from 'styled-components'

export const PhoneInputRoot = styled.input`
  height: 60px;
  min-width: 198px;
  padding: 18px 12px 12px;
  background: #f5f8fd;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  color: #000000;
`
