import styled from 'styled-components'
import { Image } from '../../atoms/Image'

export const HeaderRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px 16px 17px;

  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(47, 55, 120, 0.05);
`

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
`

export const HeaderArrowButton = styled.div`
  padding: 6px;
  display: flex;
  align-items: center;
`

export const HeaderAvatarWrap = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`

export const HeaderAvatarImg = styled(Image)`
  width: 100%;
`
