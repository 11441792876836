import styled from 'styled-components'
import { BackDrop } from '../../atoms/BackDrop'

export const ModalContentWrap = styled.div`
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalPaper = styled.div`
  min-width: 300px;
  background-color: #fff;

  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }
`

export const ModalBackDrop = styled(BackDrop)`
  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }
`
