import styled from 'styled-components'

export const DetailsPointTextTmplRoot = styled.div`
  display: flex;
  align-items: center;
`

export const DetailsPointTextTmplPointWrap = styled.div`
  margin-right: 4px;
`

export const DetailsPointTextTmplTextWrap = styled.div`
  padding-top: 0.2em;
`
