import React from 'react'
import { mobilePhoneStore$ } from '../../../../pages/LoginPage/login.store'
import { useStore } from '../../../../../utils/store'
import { AuthyIcon } from '../../../../icons/AuthyIcon'
import { Call } from '../../../../icons/Call'
import { Sms } from '../../../../icons/Sms'
import {
  OtpContentInner,
  OtpContentWrap,
  OtpDescription,
  OtpHeader,
  OtpLabel,
  OtpPaper,
  OtpTitle,
  OtpWrap,
} from '../../OtpPage.styled'
import { Variant } from './Variant/Variant'
import { VariantsList, VariantWrap } from './VariantsScreen.styled'

export type VariantsScreenProps = {
  onClickSms: () => void
  onClickCall: () => void
  onClickAuthy: () => void
}

export const VariantsScreen = ({
  onClickAuthy,
  onClickCall,
  onClickSms,
}: VariantsScreenProps) => {
  const phoneNum = useStore(mobilePhoneStore$)

  return (
    <OtpWrap>
      <OtpPaper>
        <OtpHeader></OtpHeader>
        <OtpContentWrap>
          <OtpContentInner>
            <OtpTitle>Verify your Identity</OtpTitle>

            <OtpDescription>
              In order to access Immidee, please confirm your identity using
              your registered mobile phone number below.
            </OtpDescription>

            <OtpLabel>Your phone number is {phoneNum}</OtpLabel>
          </OtpContentInner>

          <VariantsList>
            <VariantWrap>
              <Variant
                icon={<Sms />}
                title='Send SMS'
                description='You will receive a 7-digit Code via SMS'
                onClick={onClickSms}
              />
            </VariantWrap>
            <VariantWrap>
              <Variant
                icon={<Call />}
                title='Call Me'
                description='We will provide you 7-digit code during phone call'
                onClick={onClickCall}
              />
            </VariantWrap>
            <VariantWrap>
              <Variant
                icon={<AuthyIcon />}
                title='Use Authy App'
                description='You can generate a code using your Authy app'
                onClick={onClickAuthy}
              />
            </VariantWrap>
          </VariantsList>
        </OtpContentWrap>
      </OtpPaper>
    </OtpWrap>
  )
}
