import React from 'react'
import { FbSpinnerItem, FbSpinnerRoot } from './FbSpinner.styled'

export const FbSpinner = () => {
  return (
    <FbSpinnerRoot>
      <FbSpinnerItem num={1} />
      <FbSpinnerItem num={2} />
      <FbSpinnerItem num={3} />
    </FbSpinnerRoot>
  )
}
