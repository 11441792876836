import React from 'react'

export const Check = () => {
  return (
    <svg
      width='12'
      height='9'
      viewBox='0 0 12 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 3.88235L4.7037 8L11 1'
        stroke='#4D7ADE'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
