import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const AvatarEditUploadLabel = styled.label`
  margin-top: 30px;
  display: block;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(151, 151, 151);
  cursor: pointer;
  font-size: 1.25em;
  font-weight: 700;
  color: black;
`

export const AvatarEditButtonsWrap = styled.div`
  padding-top: 26px;
  padding-right: 25px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`

export const AvatarEditDescription = styled.div`
  margin-top: 25px;
  margin-bottom: 14px;
  margin-left: 50px;
  font-weight: normal;
  font-size: 15px;
  line-height: 175%;

  color: #000000;

  opacity: 0.5;

  @media (max-width: ${breakpoints.sm}px) {
    margin-left: 30px;
  }
`

export const AvatarEditSpinnerWrap = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`

export const AvatarEditErrMessage = styled.div`
  margin-top: 20px;
  margin-left: 50px;

  font-weight: normal;
  font-size: 15px;
  line-height: 175%;
  color: #f45b39;

  @media (max-width: ${breakpoints.sm}px) {
    margin-left: 30px;
  }
`
