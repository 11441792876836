import React, { useCallback, useEffect, useState } from 'react'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { ModalPortal } from '../../atoms/ModalPortal'
import { RemoveDocScroll } from '../../atoms/RemoveDocScroll'
import { ModalBackDrop, ModalContentWrap, ModalPaper } from './Modal.styled'

export type ModalProps = {
  children?: React.ReactNode
  open: boolean
  onClose: () => void
  style?: React.CSSProperties
  backDropStyle?: React.CSSProperties
  className?: string
}

// TODO add escape button handler
export const Modal = ({
  children,
  open,
  onClose,
  className,
  style,
  backDropStyle,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [fadeType, setFadeType] = useState<'in' | 'out'>('out')

  const handleOutside = useCallback(() => {
    onClose()
  }, [onClose])

  const paperRef = useClickOutside<HTMLDivElement>(handleOutside)

  const transitionEnd = useCallback(
    (e: React.TransitionEvent) => {
      if (e.propertyName !== 'opacity' || fadeType === 'in') {
        return
      }

      if (fadeType === 'out') {
        setIsOpen(false)
      }
    },
    [fadeType],
  )

  useEffect(() => {
    if (open) {
      setIsOpen(true)
      setTimeout(() => setFadeType('in'), 0)
    } else {
      setFadeType('out')
    }
  }, [open])

  if (!isOpen) {
    return null
  }

  const paperClasses = [`fade-${fadeType}`, className].filter(Boolean).join(' ')

  return (
    <ModalPortal>
      <ModalBackDrop
        // eslint-disable-next-line react/forbid-component-props
        className={`fade-${fadeType}`}
        // eslint-disable-next-line react/forbid-component-props
        style={backDropStyle}
      />
      <RemoveDocScroll />
      <ModalContentWrap>
        <ModalPaper
          // eslint-disable-next-line react/forbid-component-props
          className={paperClasses}
          onTransitionEnd={transitionEnd}
          ref={paperRef}
          // eslint-disable-next-line react/forbid-component-props
          style={style}
        >
          {children}
        </ModalPaper>
      </ModalContentWrap>
    </ModalPortal>
  )
}
