import styled from 'styled-components'

export const AvatarRoot = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
`

export type AvatarWrapProps = {
  clickable: boolean
}

export const AvatarWrap = styled.div`
  position: relative;
  cursor: ${(p: AvatarWrapProps) => (p.clickable ? 'pointer' : 'default')};
`

export const AvatarCameraCircle = styled.div`
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f5f8fd;
  z-index: 100000;
`
