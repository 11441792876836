import React from 'react'
import { Info } from '../../icons/Info'
import {
  CurrentStatusIconWrap,
  CurrentStatusNameWrap,
  CurrentStatusRoot,
  CurrentStatusTextBlock,
  CurrentStatusTextWrap,
} from './CurrentStatus.styled'

export type CurrentStatusProps = {
  text: string
}

export const CurrentStatus = ({ text }: CurrentStatusProps) => {
  return (
    <CurrentStatusRoot>
      <CurrentStatusIconWrap>
        <Info />
      </CurrentStatusIconWrap>
      <CurrentStatusTextBlock>
        <CurrentStatusNameWrap>Current Status</CurrentStatusNameWrap>
        <CurrentStatusTextWrap>{text}</CurrentStatusTextWrap>
      </CurrentStatusTextBlock>
    </CurrentStatusRoot>
  )
}
