import React from 'react'
import { Tooltip } from '../../atoms/Tooltip'
import {
  HProgressPointTooltipDesc,
  HProgressPointTooltipMainDesc,
  HProgressPointTooltipRoot,
  HProgressPointTooltipTitle,
} from '../HProgress/HProgress.styled'

export type TimelineTooltipProps = {
  name: string
  subName?: string | null
  time?: string
  children: React.ReactElement
}

export const TimelineTooltip = ({
  name,
  subName,
  time,
  children,
}: TimelineTooltipProps) => {
  return (
    <Tooltip
      title={
        <HProgressPointTooltipRoot>
          <HProgressPointTooltipTitle>{name}</HProgressPointTooltipTitle>
          {subName && (
            <HProgressPointTooltipMainDesc>
              {subName}
            </HProgressPointTooltipMainDesc>
          )}
          <HProgressPointTooltipDesc>{time}</HProgressPointTooltipDesc>
        </HProgressPointTooltipRoot>
      }
    >
      {children}
    </Tooltip>
  )
}
