export function formatDate(date: Date): string {
  const day = withLeadZero(date.getDate())
  const month = withLeadZero(date.getMonth() + 1)
  const year = withLeadZero(date.getFullYear())

  return `${month}/${day}/${year}`
}

function withLeadZero(num: number): string {
  let result = num.toString()
  if (Math.abs(num) < 10) {
    result = `0${num}`
  }

  return result
}

export function getThisMonthDate(date: Date): Date {
  return new Date(
    `${date.getFullYear()}-${withLeadZero(date.getMonth() + 1)}-01`,
  )
}

export function addMonth(date: Date, monthDelta: number): Date {
  const d = getThisMonthDate(date)
  const month = d.getMonth()
  return new Date(d.setMonth(month + monthDelta))
}

export function getThisYearDate(date: Date): Date {
  return new Date(`${date.getFullYear()}-01-01`)
}
