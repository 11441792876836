import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const DetailFilesTitleWrap = styled.div`
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    margin-bottom: 15px;
  }
`

export const DetailFilesList = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`

export const DetailFilesItemWrap = styled.div`
  margin-right: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`
