import PDF from './icons/pdf.svg'
import DOC from './icons/doc.svg'
import TIFF from './icons/tiff.svg'
import XLS from './icons/xls.svg'
import DEFAULT_ICON from './icons/default.svg'

export const iconsMap: Record<string, string | undefined> = {
  PDF,
  DOC,
  DOCX: DOC,
  TIFF,
  JPG: TIFF,
  JPEG: TIFF,
  PNG: TIFF,
  XLS,
  XLSX: XLS,
  NUMBERS: XLS,
  PAGES: DOC,
  RTF: DOC,
  RTFD: DOC,
}

export const getIconByType = (type: string): string => {
  const icon = iconsMap[type] || DEFAULT_ICON
  return icon
}
