import React from 'react'
import { DocumentIconType } from '../../icons/DocumentIcon'
import { Document } from '../../molecules/Document'
import { DocumentsListItem } from './DocumentsList.styled'

export type DocumentData = {
  typeName: string
  name: string
  expires: Date
  type: DocumentIconType
  isExtension?: boolean
}

export type DocumentsListProps = {
  list: DocumentData[]
}

export const DocumentsList = ({ list }: DocumentsListProps) => {
  return (
    <>
      {list.map(({ expires, name, typeName, type, isExtension }, idx) => (
        <DocumentsListItem
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
        >
          <Document
            name={name}
            typeName={typeName}
            expires={expires}
            isExtension={isExtension}
            type={type}
          />
        </DocumentsListItem>
      ))}
    </>
  )
}
