import React from 'react'
import {
  TextFieldErrorText,
  TextFieldInput,
  TextFieldLabel,
  TextFieldRoot,
  TextFieldSuggestion,
} from './TextField.styled'

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: React.ReactNode
  suggestion?: React.ReactNode
  error?: boolean
}

export const TextField = ({
  label,
  suggestion,
  error = false,
  type = 'text',
  ...otherProps
}: TextFieldProps) => {
  const Suggestion = error ? TextFieldErrorText : TextFieldSuggestion

  return (
    <TextFieldRoot>
      <TextFieldLabel>{label}</TextFieldLabel>
      <TextFieldInput {...otherProps} type={type} error={error} />
      {suggestion && <Suggestion>{suggestion}</Suggestion>}
    </TextFieldRoot>
  )
}
