import React from 'react'
import MediaQuery from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import './style.css'

import { breakpoints } from '../../../breakpoints'
import { PointData } from '../../molecules/HProgress'
import { CardWrap, CardListRoot, CardSlideWrap } from './CardListTmpl.styled'

export type CaseData = {
  name: string
  caseId: string
  initiatedDate: string
  completionDate?: string
  points: PointData[]
  activePointIdx: number
}

export type CasesListProps = {
  itemsJSX: React.ReactNode[]
  mobileSliderOff?: boolean
}

SwiperCore.use([Pagination])

export const CardListTmpl = ({ itemsJSX, mobileSliderOff }: CasesListProps) => {
  const mobileBreakPoint = mobileSliderOff ? 0 : breakpoints.sm

  return (
    <>
      <MediaQuery maxWidth={mobileBreakPoint}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          pagination
          breakpoints={{
            650: {
              slidesPerView: 1.7,
            },
            550: {
              slidesPerView: 1.5,
            },
            450: {
              slidesPerView: 1.3,
            },
            360: {
              slidesPerView: 1.1,
            },
          }}
          freeMode
        >
          {itemsJSX.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={idx}>
              <CardSlideWrap>{item}</CardSlideWrap>
            </SwiperSlide>
          ))}
        </Swiper>
      </MediaQuery>
      <MediaQuery minWidth={mobileBreakPoint + 1}>
        <CardListRoot>
          {itemsJSX.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <CardWrap key={idx}>{item}</CardWrap>
          ))}
        </CardListRoot>
      </MediaQuery>
    </>
  )
}
