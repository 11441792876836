import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const FileCardRoot = styled.a`
  width: 200px;
  padding: 18px;
  padding-top: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;
  box-shadow: 10px 10px 20px rgba(47, 55, 120, 0.1);
  border-radius: 15px;

  text-decoration: none;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: row;
    width: auto;
    padding: 18px 16px;
  }
`

export const FileCardIconWrap = styled.div`
  width: 53px;
  margin-bottom: 15px;
  font-size: 5px;

  @media (max-width: ${breakpoints.sm}px) {
    width: 28px;
    margin-bottom: 0;
    margin-right: 15px;
  }
`

export const FileCardIconImg = styled.img`
  width: 100%;
`

export const FileCardText = styled.div`
  text-align: center;
  @media (max-width: ${breakpoints.sm}px) {
    text-align: left;
  }
`

export const FileCardTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: #2f3778;
  @media (min-width: ${breakpoints.sm + 1}px) {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
`

export const FileCardDesc = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #585b78;
  opacity: 0.75;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 10px;
  }
`
