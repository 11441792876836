import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import { FbSpinner } from '../../atoms/FbSpinner'
import getCroppedImg from '../../../utils/cropImage'
import {
  AvatarEditButtonsWrap,
  AvatarEditDescription,
  AvatarEditErrMessage,
  AvatarEditSpinnerWrap,
  AvatarEditUploadLabel,
} from './AvatarEdit.styled'
import {
  DialogButton,
  DialogTitle,
  PasswordModalRoot,
} from '../../organisms/PasswordModal/PasswordModal.styled'

export type UploadImageInfo = {
  image: string
  width: number
  height: number
}

const MAXIMUM_FILE_SIZE = 10485760 // 10mb

type AvatarEditPops = {
  onSubmitUpload: (params: UploadImageInfo) => void | Promise<void>
  open: boolean
  onClose: () => void
}

export const AvatarEdit = ({
  onSubmitUpload,
  open,
  onClose,
}: AvatarEditPops) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [image, setImage] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [pending, setPending] = useState(false)
  const [sizeErr, setSizeErr] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0]
    if (f) {
      if (f.size > MAXIMUM_FILE_SIZE) {
        setSizeErr(true)
        return
      }

      setSizeErr(false)

      const text = URL.createObjectURL(f)
      setImage(text)
    } else {
      setImage('')
    }
  }

  const onUpload = useCallback(async () => {
    setPending(true)
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels!, 512)
      onSubmitUpload({ image: croppedImage, width: 200, height: 200 })
    } catch (e) {
      console.error(e)
    } finally {
      setPending(false)
      onClose()
    }
  }, [image, croppedAreaPixels, onSubmitUpload, onClose])

  useEffect(() => {
    return () => {
      if (!open) {
        setImage('')
      }
    }
  }, [open])

  const content = useMemo(() => {
    if (image) {
      return (
        <>
          <AvatarEditDescription>
            Drag to move and scale your photo
          </AvatarEditDescription>
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: 300,
              background: '#333',
            }}
          >
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              // onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape='round'
            />
          </div>
        </>
      )
    }

    return (
      <>
        <AvatarEditUploadLabel>
          Choose a File
          <input
            ref={inputRef}
            onChange={onChange}
            type='file'
            accept='image/*'
            style={{
              width: 0.1,
              height: 0.1,
              opacity: 0,
              overflow: 'hidden',
              position: 'absolute',
              zIndex: -1,
            }}
          />
        </AvatarEditUploadLabel>
      </>
    )
  }, [crop, image, onCropComplete, zoom])

  const stubHandler = () => {
    //
  }

  return (
    <PasswordModalRoot open={open} onClose={pending ? stubHandler : onClose}>
      <DialogTitle>Change Avatar</DialogTitle>

      {sizeErr && (
        <AvatarEditErrMessage>Maximum file size is 10mb</AvatarEditErrMessage>
      )}

      {content}

      <AvatarEditButtonsWrap>
        {pending && (
          <AvatarEditSpinnerWrap>
            <FbSpinner />
          </AvatarEditSpinnerWrap>
        )}
        <DialogButton
          variant='secondary'
          onClick={() => {
            setImage('')
            onClose()
          }}
          disabled={pending}
        >
          Cancel
        </DialogButton>
        <DialogButton
          variant='primary'
          onClick={onUpload}
          disabled={pending || !image}
        >
          Confirm
        </DialogButton>
      </AvatarEditButtonsWrap>
    </PasswordModalRoot>
  )
}
