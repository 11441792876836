import styled from 'styled-components'
import { breakpoints } from '../../../breakpoints'

export const CardField = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #585b78;

  @media (max-width: ${breakpoints.sm}px) {
    font-size: 10px;
    line-height: 12px;
  }
`
