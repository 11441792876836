import React from 'react'
import {
  HProgressActive,
  HProgressPointsWrap,
  HProgressRoot,
} from './HProgress.styled'
import { Color } from '../../../types/color'
import { HProgressPoint } from './HProgressPoint'

export type PointData = {
  title: string
  mainDesc?: string | null
  desc: string
  important?: boolean
}

export type HProgressProps = {
  points: PointData[]
  activePointIdx: number
  hover: boolean
  color: Color
  renderActivePoint?: (p: { color: Color }) => React.ReactNode
}

export const HProgress = ({
  points,
  activePointIdx,
  hover,
  color,
  renderActivePoint,
}: HProgressProps) => {
  const pointsCount = points.length - 1
  let step: number
  if (pointsCount <= 0) {
    step = 0
  } else {
    step = 100 / pointsCount
  }
  const percents = activePointIdx * step

  return (
    <HProgressRoot>
      <HProgressActive percents={percents} color={color} />
      <HProgressPointsWrap>
        {points.map(({ important, title, desc, mainDesc }, idx) => {
          const filled = idx < activePointIdx
          const isActive = idx === activePointIdx
          const percents = step * 1 * idx
          const visible = hover || isActive || important

          return (
            <HProgressPoint
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              active={isActive}
              color={color}
              percents={percents}
              filled={filled}
              important={important}
              visible={visible}
              title={title}
              desc={desc}
              mainDesc={mainDesc}
              // eslint-disable-next-line no-undefined
              renderPoint={isActive ? renderActivePoint : undefined}
            />
          )
        })}
      </HProgressPointsWrap>
    </HProgressRoot>
  )
}
