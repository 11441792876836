import React from 'react'

export const PinIcon = () => {
  return (
    <svg
      width='11'
      height='14'
      viewBox='0 0 11 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='5.5'
        cy='5.5'
        r='0.5'
        fill='#585B78'
        stroke='#585B78'
        strokeWidth='2'
      />
      <path
        d='M10 5.47337C10 9.36407 5.5 13 5.5 13C5.5 13 1 9.36407 1 5.47337C1 3.0028 3.01472 1 5.5 1C7.98528 1 10 3.0028 10 5.47337Z'
        stroke='#585B78'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  )
}
