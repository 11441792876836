import React, { useState } from 'react'
import { gql } from '@apollo/client'
import { TextField } from '../../atoms/TextField'
import {
  DialogButton,
  DialogContent,
  DialogFooter,
  DialogInputWrap,
  DialogTitle,
  PasswordModalRoot,
} from './PasswordModal.styled'
import {
  useChangePasswordMutation,
  useUpdatePasswordMutation,
} from '../../../api/generated'
import { FbSpinner } from '../../atoms/FbSpinner'
import { LoginPageError } from '../../pages/LoginPage/LoginPage.styled'

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($userId: String!, $newPassword: String!) {
    changePassword(userId: $userId, newPassword: $newPassword)
  }
`

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword(
    $userId: String!
    $password: String!
    $newPassword: String!
  ) {
    updatePassword(
      userId: $userId
      password: $password
      newPassword: $newPassword
    )
  }
`

export type PasswordModalProps = {
  requiredChange: boolean
  userId: string
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const PasswordModal = ({
  requiredChange,
  userId,
  onClose,
  open,
  onSuccess,
}: PasswordModalProps) => {
  const [changePassword, { loading }] = useChangePasswordMutation()
  const [
    updatePassword,
    { loading: updateLoading },
  ] = useUpdatePasswordMutation()

  const pending = loading || updateLoading

  const [password, setPassword] = useState('')
  const [currPassword, setCurrPassword] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [confirmErr, setConfirmErr] = useState<string | null>(null)

  const resetFields = () => {
    setCurrPassword('')
    setConfirmPass('')
    setPassword('')
  }

  const handleClose = () => {
    if (requiredChange || pending) {
      return
    }
    resetFields()
    onClose()
  }

  const validateChange = (): string | null => {
    if (!password) {
      return 'Password is empty'
    }

    if (password !== confirmPass) {
      return 'Password mismatch'
    }

    return null
  }

  const validateUpdate = (): string | null => {
    if (!currPassword) {
      return 'Current Password is empty'
    }

    return validateChange()
  }

  const submitChange = async () => {
    const err = validateChange()
    if (err) {
      setConfirmErr(err)
      return
    }

    const res = await changePassword({
      variables: { userId, newPassword: password },
    })
    if (res.data?.changePassword) {
      resetFields()
      onSuccess()
    }
  }

  const submitUpdate = async () => {
    const err = validateUpdate()
    if (err) {
      setConfirmErr(err)
      return
    }

    const res = await updatePassword({
      variables: { userId, newPassword: password, password: currPassword },
    })

    if (!res.data?.updatePassword) {
      setConfirmErr('Incorrect Current Password')
      return
    }

    resetFields()
    onSuccess()
  }

  return (
    <PasswordModalRoot
      open={open || requiredChange}
      onClose={handleClose}
      backDropStyle={
        requiredChange
          ? {
              background: `linear-gradient(135deg,rgba(94, 137, 238, 1) 0%,rgba(46, 54, 119, 1) 100%)`,
            }
          : // eslint-disable-next-line no-undefined
            undefined
      }
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <LoginPageError
          // eslint-disable-next-line react/forbid-component-props
          style={{ visibility: !confirmErr ? 'hidden' : 'unset' }}
        >
          Error: {confirmErr}
        </LoginPageError>

        {!requiredChange && (
          <DialogInputWrap>
            <TextField
              label='Current Password'
              placeholder='Type Current Password'
              type='password'
              value={currPassword}
              onChange={e => {
                setCurrPassword(e.target.value)
                setConfirmErr(null)
              }}
            />
          </DialogInputWrap>
        )}

        <DialogInputWrap>
          <TextField
            label='New Password'
            placeholder='Type New Password'
            type='password'
            value={password}
            onChange={e => {
              setPassword(e.target.value)
              setConfirmErr(null)
            }}
          />
        </DialogInputWrap>
        <TextField
          label='Confirm New Password'
          placeholder='Retype New Password'
          type='password'
          value={confirmPass}
          onChange={e => {
            setConfirmPass(e.target.value)
            setConfirmErr(null)
          }}
        />
      </DialogContent>
      <DialogFooter>
        {(loading || updateLoading) && <FbSpinner />}

        {!requiredChange && (
          <DialogButton
            variant='secondary'
            onClick={handleClose}
            disabled={pending}
          >
            Cancel
          </DialogButton>
        )}
        <DialogButton
          variant='primary'
          onClick={requiredChange ? submitChange : submitUpdate}
          disabled={pending}
        >
          Confirm
        </DialogButton>
      </DialogFooter>
    </PasswordModalRoot>
  )
}
