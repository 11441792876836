import styled from 'styled-components'
import { Image } from '../../atoms/Image'

export const UserSmallRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const UserSmallAvatarWrap = styled.div`
  margin-bottom: 6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`

export const UserSmallName = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #282b32;
`

export const UserSmallAvatarImg = styled(Image)`
  width: 100%;
`
