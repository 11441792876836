import React from 'react'

export const AuthyIcon = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48 23.9996C48 37.2555 37.2551 48 23.9994 48C10.7445 48 0 37.2555 0 23.9996C0 10.7447 10.7445 0 23.9994 0C37.2551 0 48 10.7447 48 23.9996Z'
        fill='#B3BDD1'
      />
      <path
        d='M21.9419 18.9166L27.6964 24.6691C28.5271 25.5009 29.8758 25.5009 30.7084 24.6691C31.5393 23.8376 31.5411 22.49 30.7084 21.6582L24.9535 15.9035C20.8358 11.7848 14.1807 11.7185 9.97563 15.6942C9.93637 15.7268 9.89852 15.7611 9.86219 15.797C9.84382 15.8153 9.82882 15.8348 9.81063 15.8513C9.79188 15.8693 9.77275 15.8855 9.75494 15.9035C9.71857 15.9397 9.68538 15.9777 9.65369 16.0154C5.67588 20.2224 5.74432 26.8777 9.86238 30.9961L15.6171 36.7498C16.4496 37.581 17.7964 37.581 18.6288 36.7498C19.4616 35.9179 19.4616 34.5686 18.6308 33.7385L12.8759 27.9839C10.3638 25.4728 10.3469 21.3972 12.8238 18.8645C15.3561 16.3876 19.4294 16.4056 21.9419 18.9168V18.9166ZM29.3691 11.2529C28.5376 12.0848 28.5376 13.4322 29.3714 14.264L35.1241 20.0189C37.6347 22.5299 37.651 26.6049 35.1743 29.1382C32.6412 31.6132 28.5693 31.5967 26.0575 29.0853L20.3028 23.3311C19.4706 22.4992 18.1216 22.4992 17.2904 23.3311C16.4573 24.1623 16.4573 25.514 17.2904 26.3433L23.0433 32.0985C27.1623 36.2169 33.8166 36.2839 38.0224 32.3077C38.0614 32.2732 38.0982 32.2411 38.1368 32.2048C38.1544 32.187 38.1709 32.1688 38.188 32.1504C38.2068 32.1326 38.2249 32.1161 38.2424 32.0962C38.2793 32.0619 38.3108 32.0242 38.3436 31.9848C42.3214 27.7796 42.2549 21.1265 38.137 17.0056L32.3823 11.2527C31.5505 10.4196 30.2001 10.4196 29.3691 11.2529Z'
        fill='white'
      />
    </svg>
  )
}
