import React from 'react'
import { ContentContainer } from '../../atoms/ContentContainer'
import { ContentTitle } from '../../atoms/ContentTitle'
import {
  SectionTmplDivider,
  SectionTmplRoot,
  SectionTmplTitleSecondaryWrap,
  SectionTmplTitleWrap,
} from './SectionTmpl.styled'

export type SectionTmplProps = {
  title: React.ReactNode
  titleSecondary?: React.ReactNode
  children: React.ReactNode
  divider?: boolean
}

export const SectionTmpl = ({
  children,
  title,
  titleSecondary,
  divider,
}: SectionTmplProps) => {
  return (
    <SectionTmplRoot>
      <ContentContainer>
        <SectionTmplTitleWrap>
          <ContentTitle>{title}</ContentTitle>
          <SectionTmplTitleSecondaryWrap>
            {titleSecondary}
          </SectionTmplTitleSecondaryWrap>
        </SectionTmplTitleWrap>
        {children}
        {divider && <SectionTmplDivider />}
      </ContentContainer>
    </SectionTmplRoot>
  )
}
