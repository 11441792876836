import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { SchemaLink } from '@apollo/client/link/schema'
import { makeExecutableSchema } from '@graphql-tools/schema'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'

import schemaFile from './schema.gql'
import { resolvers } from './resolvers'
import { GraphQLError } from 'graphql'
import { withScalars } from 'apollo-link-scalars'
import { dateScalarClient } from './dateScalar'

const cache = new InMemoryCache()

const schema = makeExecutableSchema({
  typeDefs: schemaFile,
  resolvers: resolvers as any,
})

const schemaLink = new SchemaLink({ schema })

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(err => {
      const { message, locations, path } = err
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )

      Sentry.captureException(err)
    })
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const link = from([
  errorLink,
  withScalars({ schema, typesMap: { DateTime: dateScalarClient } }) as any,
  schemaLink,
])

export const client = new ApolloClient({
  cache,
  link,
})

export function getGqlErrorMessage(errors: readonly GraphQLError[]): string {
  let str = ''
  errors.forEach(({ message, locations, path }) => {
    str += `,\n[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  })

  return str.replace(/^,\n/, '')
}
