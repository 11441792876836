import React from 'react'
import { Color } from '../../../types/color'
import { CancelBig } from '../../icons/CancelBig'
import { Cancel } from '../../icons/Cancel'
import { DotCompletedRoot } from '../DotCompleted/DotCompleted.styled'

export type DotCancelledProps = {
  color: Color
  big?: boolean
}

export const DotCancelled = ({ color, big }: DotCancelledProps) => {
  return (
    <DotCompletedRoot color={color} big={big}>
      {big ? <CancelBig /> : <Cancel />}
    </DotCompletedRoot>
  )
}
